import { useEffect, useState } from 'react';

import { api, API } from 'modules/api';
import { FooterContentType } from '.';
import { FooterGetResponse } from 'modules/api/endpoints/footer';
import logger from 'modules/logger';

export const useFooter = (
  setLoaded: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  const [footer, setFooter] = useState<FooterContentType>();
  //TODO done useEffect
  useEffect(() => {
    let active = true;
    (async () => {
      try {
        const response: FooterGetResponse = await api(API.GET_FOOTER());
        active && setFooter(response.data.entries[0]);
      } catch (error) {
        active &&
          logger.error('Error getting content from ContentStack', error);
      } finally {
        active && setLoaded(true);
      }
    })();
    return () => {
      active = false;
    };
  }, [setLoaded]);
  return { footer };
};
