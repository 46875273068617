import { useHistory } from 'react-router-dom';

import { CONGRATULATIONS_PAGE_URL } from 'modules/utils/constants';
import { SubscriptionStateEnum } from 'modules/utils/types';
import { useCallback } from 'react';

export const useSuccessRedirect = () => {
  const history = useHistory();
  const successRedirect = useCallback(
    (
      subscriptionPlanName: string,
      subscriptionState: SubscriptionStateEnum,
    ) => {
      history.push({
        pathname: CONGRATULATIONS_PAGE_URL,
        state: {
          plan: subscriptionPlanName,
          subscriptionState: subscriptionState,
        },
      });
    },
    [history],
  );

  return { successRedirect } as const;
};
