import React from 'react';
import SignIn from './components/SignIn';

type TemplateType = {
  loading: boolean;
};

const Template: React.FunctionComponent<TemplateType> = ({ loading }) => {
  return <SignIn />;
};

export default React.memo(Template);
