import React, { useEffect } from 'react';
import loading from 'modules/theme/ims/button-loading.svg';
import styles from './styles.module.scss';

const Modal: React.FunctionComponent = () => {
  useEffect(() => {
    document.documentElement.classList.add('lock-screen');
    return () => {
      document.documentElement.classList.remove('lock-screen');
    };
  }, []);

  return (
    <div className={styles.loaderModal}>
      <div
        className={styles.spinner}
        style={{
          backgroundImage: `url(${loading}`,
        }}
      ></div>
    </div>
  );
};

export default Modal;
