import { useCallback, useEffect, useRef, useState } from 'react';

import { API, api } from 'modules/api';
import { ChargebeeSubscriptionPlanId } from 'modules/chargebee/constants';
import { EventsActions, EventsCategories } from 'modules/gtm/constants';
import {
  SIGNUP_REMINDER_CLIKED_COOKIE,
  SIGNUP_REMINDER_CLIKED_VALUE,
} from 'modules/utils/constants';
import { SubscriptionStateEnum } from 'modules/utils/types';
import { TriggerGTMEvent } from 'modules/gtm';
import { useHandleSignUpError } from './useHandleSignUpError';
import { useSuccessRedirect } from './useSuccessRedirect';
import logger from 'modules/logger';

function useSubscribeUser() {
  const { successRedirect } = useSuccessRedirect();
  const { handleSignUpError } = useHandleSignUpError();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const active = useRef(true);

  //TODO done useCallback
  const subscribeNewUser = useCallback(
    async (
      formData: any,
      subscriptionState: SubscriptionStateEnum,
      subscriptionPlanId?: ChargebeeSubscriptionPlanId,
    ) => {
      try {
        setIsSubmitting(true);
        const response = await api(API.PUT_USER_SUBSCRIPTION_USER(formData));
        if (active.current) {
          if (!response.data?.success) {
            logger.debug(response);
            throw new Error(response.data.errorMessage);
          }
          let actionType = '';
          if (subscriptionState === SubscriptionStateEnum.PeopleCert_User) {
            actionType = EventsActions.CLICK_PAY_NOW_PEOPLECERT;
          } else if (
            subscriptionPlanId ===
            ChargebeeSubscriptionPlanId.STANDARD_INDIVIDUAL_MONTHLY
          ) {
            actionType = EventsActions.CLICK_PAY_NOW_MONTHLY;
          } else if (
            subscriptionPlanId ===
            ChargebeeSubscriptionPlanId.STANDARD_INDIVIDUAL_YEARLY
          ) {
            actionType = EventsActions.CLICK_PAY_NOW_ANNUAL;
          }

          TriggerGTMEvent({
            action: actionType,
            category: EventsCategories.SIGN_UP,
            label: '',
          });

          const stateToForward = response.data?.isConsultant
            ? SubscriptionStateEnum.Consultant_User
            : subscriptionState;
          successRedirect(response.data.subscriptionPlanName, stateToForward);
          logger.debug(response);
        }
      } catch (error) {
        if (active.current) {
          logger.error('Put User Subscription User Error', error);
          handleSignUpError(error);
        }
      } finally {
        active.current && setIsSubmitting(false);
      }
    },
    [handleSignUpError, setIsSubmitting, successRedirect],
  );
  //TODO done useCallback
  const subscribeExistingUser = useCallback(
    async (formData: any, subscriptionState: SubscriptionStateEnum) => {
      try {
        logger.debug('SUBSCRIBE USER DATA', formData);
        setIsSubmitting(true);
        const response = await api(
          API.PUT_USER_SUBSCRIPTION_SUBSCRIBE_USER(formData),
        );
        if (active.current) {
          if (!response.data?.success) {
            logger.debug(response);
            throw new Error(response.data.errorMessage);
          }
          if (
            sessionStorage.getItem(SIGNUP_REMINDER_CLIKED_COOKIE) ===
            SIGNUP_REMINDER_CLIKED_VALUE
          ) {
            sessionStorage.removeItem(SIGNUP_REMINDER_CLIKED_COOKIE);
            TriggerGTMEvent({
              action: EventsActions.CLICK_SIGNUP_REMINDER,
              category: EventsCategories.SIGN_UP,
              label: '',
            });
          }
          successRedirect(
            response.data.subscriptionPlanName,
            subscriptionState,
          );
          logger.debug(response);
        }
      } catch (error) {
        if (active.current) {
          logger.error('Put User Subscription Subscribe Error', error);
          handleSignUpError(error);
        }
      } finally {
        active.current && setIsSubmitting(false);
      }
    },
    [handleSignUpError, setIsSubmitting, successRedirect],
  );

  useEffect(() => {
    return () => {
      active.current = false;
    };
  }, []);

  return { subscribeNewUser, subscribeExistingUser, isSubmitting } as const;
}

export default useSubscribeUser;
