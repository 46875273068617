import React, { useEffect, useState } from 'react';

import { BadgesPageType, ProgrammePageType } from './types';
import { BadgesLoadedType } from './types';
import { certificatesItil, itilDesignations } from './certificatesItil';
import {
  certificatesPropPath,
  proPathDesignations,
} from './certificatesProPath';
import { useBadgesPolling } from './hooks/useBadgesPolling';
import { usePaths, useBadges, useCandidateIds, useBadgesLinks } from './hooks';
import { usePathSlider } from './hooks/useSliderPaths';
import { usePathSliderControl } from './hooks/usePathSliderControl';
import Template from './Template';

export const Badges: React.FunctionComponent = () => {
  const [loaded, setLoaded] = useState<BadgesLoadedType>({
    badges: false,
    candidateId: false,
    certificates: false,
    paths: false,
  });

  const { badgesLinks } = useBadgesLinks(setLoaded);
  const { candidateIds, setCandidateIds } = useCandidateIds(setLoaded);
  const { cancelPolling, runPolling } = useBadgesPolling();
  const { badges, setBadges } = useBadges(setLoaded, runPolling, candidateIds);
  const { paths } = usePaths(setLoaded, candidateIds);

  const [
    proPathSlidePosition,
    itilSlidePosition,
    handleNextPropPath,
    handlePrevPropPath,
    handleNextItil,
    handlePrevItil,
  ] = usePathSliderControl(paths);

  const { filterPaths, sliderPaths } = usePathSlider();

  useEffect(() => {
    paths && filterPaths(paths);
  }, [paths, filterPaths]);

  const badgesData: BadgesPageType = {
    links: badgesLinks,
    earned:
      badges?.filter(b => b?.claimed) &&
      badges?.filter(b => b?.claimed).length > 0
        ? badges?.filter(b => b?.claimed).length
        : false,
    records: badges,
  };

  const proPath: ProgrammePageType = {
    certificates: certificatesPropPath,
    designations: proPathDesignations,
    handleNext: handleNextPropPath,
    handlePrev: handlePrevPropPath,
    paths: sliderPaths?.filter(p => p.family === 'ProPath'),
    showPaths:
      sliderPaths && sliderPaths?.filter(p => p.family === 'ProPath').length > 0
        ? sliderPaths?.filter(p => p.family === 'ProPath').length
        : false,
    slidePosition: proPathSlidePosition,
  };

  const itil: ProgrammePageType = {
    certificates: certificatesItil,
    designations: itilDesignations,
    handleNext: handleNextItil,
    handlePrev: handlePrevItil,
    paths: sliderPaths?.filter(p => p.family === 'ITIL 4'),
    showPaths:
      sliderPaths && sliderPaths?.filter(p => p.family === 'ITIL 4').length > 0
        ? sliderPaths?.filter(p => p.family === 'ITIL 4').length
        : false,
    slidePosition: itilSlidePosition,
  };

  return (
    <Template
      badges={badgesData}
      candidateId={candidateIds}
      cancelPolling={cancelPolling}
      itil={itil}
      loaded={loaded}
      proPath={proPath}
      runPolling={runPolling}
      setBadges={setBadges}
      setCandidateIds={setCandidateIds}
    />
  );
};
