import { useCallback, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import { ModalType } from 'modules/modals/constants';
import useModalAction from 'modules/modals/hooks/useModalAction';

export const useLapsedUserModal = () => {
  const { modalShow, modalHide } = useModalAction();
  const modal = useRef<string | null>(null);
  const history = useHistory();

  useEffect(() => {
    history.listen((_, action) => {
      if (action === 'POP' && modal.current) {
        window.location.href = process.env.REACT_APP_NOROLE_URL!;
      }
    });
  }, [history, history.action]);

  const displayLapsedUserModal = useCallback(() => {
    modal.current = modalShow({
      onClose: () => {
        !!modal.current && modalHide(modal.current);
        modal.current = null;
      },
      type: ModalType.LAPSED_USER,
    });
  }, [modalHide, modalShow]);

  return { displayLapsedUserModal } as const;
};
