import React, { useContext } from 'react';

import {
  AchievementsTypes,
  CandidateIdTypes,
  ClaimStateTypes,
  DashboardLoadedType,
  DashboardType,
  useContentTypesType,
  useEventsType,
} from './types';
import { BadgesTypes } from 'pages/badges/types';
import { EMPLOYEE_CONTENT_TYPE_FILTER } from 'modules/utils/constants';
import { LoginTypeContext } from 'modules/context';
import { LoginTypeEnum, NotificationItem } from 'modules/utils/types';
import { MaturityModelCtaType } from 'components/MaturityModels/types';
import { ResourceHubItemType } from '../../components/FeaturedResources/types';
import * as Layout from 'components/Layout';
import Achievements from './components/Achievements';
import Badges from './components/Badges';
import ContentTypesList from 'components/ContentTypeList';
import Events from './components/Events';
import FeaturedResources from 'components/FeaturedResources';
import KeyTemplates from 'components/KeyTemplates';
import MaturityModels from 'components/MaturityModels';
import Notifications from './components/Notifications';

type TemplateProps = {
  achievements?: AchievementsTypes[];
  badges?: BadgesTypes[];
  candidateIds?: CandidateIdTypes[];
  contentTypes?: useContentTypesType;
  claimStates: ClaimStateTypes;
  dashboard?: DashboardType;
  events?: useEventsType;
  featuredResources?: ResourceHubItemType[];
  isEmployee: boolean;
  keyTemplates?: ResourceHubItemType[];
  loaded: DashboardLoadedType;
  maturityModelsUrl: MaturityModelCtaType;
  notifications: NotificationItem[];
  updateBadges: () => Promise<void>;
};

const Template: React.FunctionComponent<TemplateProps> = ({
  achievements,
  badges,
  candidateIds,
  contentTypes,
  claimStates,
  dashboard,
  events,
  featuredResources,
  isEmployee,
  keyTemplates,
  loaded,
  maturityModelsUrl,
  notifications,
  updateBadges,
}) => {
  const { loginTypeContext } = useContext(LoginTypeContext);
  return (
    <Layout.Container>
      <Layout.Content>
        {loginTypeContext === LoginTypeEnum.INDIVIDUAL &&
          notifications
            .filter(
              (notification: NotificationItem) => !notification.isDismissed,
            )
            .map((notification: NotificationItem) => (
              <Notifications
                buttonLabel={notification.buttonLabel}
                date={notification.date}
                id={notification.id}
                key={`notification-${notification.id}`}
                message={notification.message}
                type={notification.type}
              />
            ))}
        <FeaturedResources
          data={featuredResources}
          loaded={loaded.featuredResources}
          title={dashboard?.featured_resources?.label}
        />
        <ContentTypesList
          buttonLabel={`Go to ${dashboard?.content_types?.label}`}
          contentTypeName={
            isEmployee ? EMPLOYEE_CONTENT_TYPE_FILTER : contentTypes?.slug
          }
          data={contentTypes?.entries}
          loaded={loaded.contentTypes}
          title={contentTypes?.title}
        />
        <Events
          events={events?.entries}
          featuredEvent={events?.featured}
          isEmployee={isEmployee}
          loaded={loaded.events}
          title={dashboard?.events?.label}
        />
      </Layout.Content>
      <Layout.Sidebar>
        {loginTypeContext === LoginTypeEnum.CONSULTANT && (
          <MaturityModels
            loaded={loaded.maturityModels}
            maturityModelsUrl={maturityModelsUrl}
            mm_section={dashboard?.mm_section}
          />
        )}
        {loginTypeContext === LoginTypeEnum.INDIVIDUAL && (
          <React.Fragment>
            <Badges
              allCandidateIds={candidateIds}
              badges={badges}
              claimStates={claimStates}
              loaded={loaded.badges && loaded.claim}
              updateBadges={updateBadges}
            />
            <Achievements
              achievements={achievements}
              loaded={loaded.achievements}
            />
          </React.Fragment>
        )}
        <KeyTemplates
          keyTemplates={keyTemplates}
          loaded={loaded.keyTemplates}
          title={dashboard?.curate_key_templates?.label}
        />
      </Layout.Sidebar>
    </Layout.Container>
  );
};

export default Template;
