import { API, api } from 'modules/api';
import {
  mapContentTypeEngagementTime,
  mapEngagementSummaryContentType,
  mapEngagementSummaryPageType,
  mapPageTypeEngagementTime,
} from './utility';

export function EngagementSummaryPageType(data: any) {
  const mappedData = mapEngagementSummaryPageType(data);

  // Post Engagement Summary Page Type
  (async () => {
    try {
      await api(API.POST_DASHBOARD_SUMMARY_PAGE_TYPE(mappedData)).then(res => {
        if (res.status === 200) {
          console.log('EngagementSummaryPageType sent');
        }
      });
    } catch (error) {
      console.log('error', error);
    }
  })();
}

export function EngagementSummaryContentType(data: any) {
  const mappedData = mapEngagementSummaryContentType(data);

  // Post Engagement Summary Content Type
  (async () => {
    try {
      await api(API.POST_DASHBOARD_SUMMARY_CONTENT_TYPE(mappedData)).then(
        res => {
          if (res.status === 200) {
            console.log('EngagementSummaryContentType sent');
          }
        },
      );
    } catch (error) {
      console.log('error', error);
    }
  })();
}

export function ContentTypeEngagementTime(data: any) {
  const mappedData = mapContentTypeEngagementTime(data);

  // Post Engagement Summary Content Type
  (async () => {
    try {
      await api(API.POST_CONTENT_TYPE_ENGAGEMENT_TIME(mappedData)).then(res => {
        if (res.status === 200) {
          console.log('ContentTypeEngagementTime sent');
        }
      });
    } catch (error) {
      console.log('error', error);
    }
  })();
}

export function PageTypeEngagementTime(data: any) {
  const mappedData = mapPageTypeEngagementTime(data);

  // Post Engagement Summary Page Type
  (async () => {
    try {
      await api(API.POST_PAGE_TYPE_ENGAGEMENT_TIME(mappedData)).then(res => {
        if (res.status === 200) {
          console.log('PageTypeEngagementTime sent');
        }
      });
    } catch (error) {
      console.log('error', error);
    }
  })();
}
