import { useCallback, useContext, useEffect, useRef, useState } from 'react';

import PlatformContext from 'modules/platform/context';

export const useAccordion = (closed?: boolean) => {
  const [animating, setAnimating] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(!closed);
  const { isFirefox } = useContext(PlatformContext);
  const ref = useRef<any>(null);

  const handleTransition = useCallback((openItem: boolean, element: any) => {
    const height = element.scrollHeight;
    element.style.height = `${openItem ? height : 0}px`;
    setAnimating(true);
    requestAnimationFrame(() => {
      element.style.height = `${openItem ? 0 : height}px`;
    });
  }, []);

  const handleTransitionEnd = useCallback(
    (event: TransitionEvent) => {
      if (event.target === ref.current) {
        setAnimating(false);
        !isFirefox &&
          event.propertyName === 'height' &&
          ref.current?.style.removeProperty('height');
      }
    },
    [isFirefox],
  );

  const handleClick = useCallback(
    (questionTitle?: string, callback?: (questionTitle: string) => void) => {
      if (!open && callback && questionTitle) {
        callback(questionTitle);
      }
      setOpen(!open);
      !!ref.current && !isFirefox && handleTransition(open, ref.current);
    },
    [handleTransition, isFirefox, open],
  );

  useEffect(() => {
    const element = ref.current;
    element?.addEventListener('transitionend', handleTransitionEnd);
    return () =>
      element?.removeEventListener('transitionend', handleTransitionEnd);
  }, [handleTransitionEnd]);

  return { animating, handleClick, open, ref, setOpen };
};
