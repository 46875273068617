import React from 'react';

import { ContentTypesType } from 'components/ContentTypeList/types';
import { getLink, linkText } from 'modules/utils/utils';
import BookmarkItem from 'components/BookMarkItem';

import styles from './styles.module.scss';

type BookmarksFullWidthProps = {
  bookmarks: ContentTypesType[];
  handleBookmarkClick: (contentTypeUid: string) => void;
};

const BookmarksFullWidth: React.FunctionComponent<BookmarksFullWidthProps> = ({
  handleBookmarkClick,
  bookmarks,
}) => {
  return bookmarks.length > 3 ? (
    <React.Fragment>
      <div className={styles.fullWidth}>
        {bookmarks.slice(3).map((bookmark: ContentTypesType, index: number) => (
          <BookmarkItem
            handleBookmarkClick={handleBookmarkClick}
            bookmarked={bookmark.bookmarked}
            buttonLabel={linkText(
              bookmark.categorisation?.content_type[0].slug,
            )}
            description={bookmark.short_description}
            image={bookmark?.images?.featured_image?.url}
            key={`content-types-${index}`}
            liked={bookmark.liked}
            likes={bookmark.likes}
            publicationDate={bookmark.meta.publication_date}
            slug={getLink(bookmark.categorisation?.content_type, bookmark.slug)}
            title={bookmark.short_title}
            uid={bookmark.uid}
          />
        ))}
      </div>
    </React.Fragment>
  ) : null;
};

export default BookmarksFullWidth;
