import { CertificatesItilTitles, ItilDesignationsTitles } from './constants';

export const certificatesItil = [
  {
    category: 'ITIL FOUNDATION',
    certificates: [
      {
        title: CertificatesItilTitles.FOUNDATION,
        uniqueTitle: 'ITIL 4 Foundation',
      },
    ],
  },
  {
    category: 'ITIL SPECIALIST',
    certificates: [
      {
        title: CertificatesItilTitles.CREATE_DELIVER_SUPPORT,
        uniqueTitle: 'ITIL 4 Specialist: Create, Deliver & Support',
      },
      {
        title: CertificatesItilTitles.DRIVE_STAKEHOLDER_VALUE,
        uniqueTitle: 'ITIL 4 Specialist: Drive Stakeholder Value',
      },
      {
        title: CertificatesItilTitles.HIGH_VELOCITY_IT,
        uniqueTitle: 'ITIL 4 Specialist: High Velocity IT',
      },
    ],
  },
  {
    category: 'ITIL STRATEGIST',
    certificates: [
      {
        title: CertificatesItilTitles.DIRECT_PLAN_IMPROVE,
        uniqueTitle: 'ITIL 4 Strategist: Direct, Plan & Improve',
      },
    ],
  },
  {
    category: 'ITIL LEADER',
    certificates: [
      {
        title: CertificatesItilTitles.DIGITAL_IT_STRATEGY,
        uniqueTitle: 'ITIL 4 Leader: Digital and IT Strategy',
      },
    ],
  },
  {
    category: 'ITIL EXTENSION MODULES',
    certificates: [
      {
        title: CertificatesItilTitles.ACQUIRING_MANAGING_CLOUD_SERVICES,
        uniqueTitle: 'ITIL 4 Specialist: Acquiring & Managing Cloud Services',
      },
      {
        title: CertificatesItilTitles.SUSTAINABILITY_IN_DIGITAL_IT,
        uniqueTitle: 'ITIL 4 Specialist: Sustainability in Digital & IT',
      },
    ],
  },
];

export const itilDesignations = [
  {
    title: ItilDesignationsTitles.MANAGING_PROFESSIONAL,
    uniqueTitle: 'ITIL 4 Managing Professional',
  },
  {
    title: ItilDesignationsTitles.STRATEGIC_LEADER,
    uniqueTitle: 'ITIL 4 Strategic Leader',
  },
];
