import React from 'react';

import { countries } from 'modules/countries';
import { LOCK_FIELDS_SIGNUP_PCERT_USER } from 'modules/utils/constants';
import { SignUpQuestionSetType } from '../types';
import * as Question from 'components/Question';

type PersonalInfoProps = SignUpQuestionSetType & {
  isPeopleCertUser: boolean;
};

const PersonalInfo: React.FC<PersonalInfoProps> = ({
  control,
  isPeopleCertUser,
  isSubmitting,
  model,
}) => {
  return (
    <React.Fragment>
      <Question.Text
        control={control}
        isSubmitting={isSubmitting}
        label="First name"
        question={model.givenName}
        readOnly={isPeopleCertUser && LOCK_FIELDS_SIGNUP_PCERT_USER}
      />

      <Question.Text
        control={control}
        isSubmitting={isSubmitting}
        label="Surname"
        question={model.surname}
        readOnly={isPeopleCertUser && LOCK_FIELDS_SIGNUP_PCERT_USER}
      />

      <Question.EmailUserExists
        control={control}
        isSubmitting={isSubmitting}
        label="Email"
        question={model.email}
        readOnly={isPeopleCertUser && LOCK_FIELDS_SIGNUP_PCERT_USER}
      />

      <Question.Text
        autoComplete="emailOff"
        control={control}
        isSubmitting={isSubmitting}
        label="Confirm Email"
        question={model.confirmEmail}
        readOnly={isPeopleCertUser && LOCK_FIELDS_SIGNUP_PCERT_USER}
      />

      <Question.Text
        control={control}
        help="This is the long number that can usually be found on your exam certificate or in the personal details section of your PeopleCert account."
        isSubmitting={isSubmitting}
        label="Candidate number (Optional)"
        question={model.candidateNumber}
        readOnly={isPeopleCertUser && LOCK_FIELDS_SIGNUP_PCERT_USER}
      />

      <Question.Select
        control={control}
        isSubmitting={isSubmitting}
        label="Country"
        options={countries}
        question={model.countryCode}
      />
    </React.Fragment>
  );
};

export default React.memo(PersonalInfo);
