import { JSEncrypt } from 'jsencrypt';
import { useJSUtilities } from './UtilitiesExternal';
import $ from 'jquery';

export const useJSPeoplecertApplication = () => {
  const { getCookie, getCacheBuster, getURLparams, setCookie, deleteCookie } =
    useJSUtilities();
  const cookieAccessDomains: { id: string; domain: string }[] = [];
  let elementCount = 0;
  let settings = {
    cookieAccessDomains,
    portalBaseAddress: '',
  };
  const lastAjaxElement: any = [];
  const timeout = 300000;
  const baseAddress = '';
  let originWebSite = 'overview';
  let isSQAmode = false;
  const callbacks: any = [];
  const peopleCertSso = 'https://stage-accessportal.peoplecert.org/sso';
  let clientID = getCookie('clientid');

  const loginApplication = function (
    userName: any,
    passWord: any,
    captchaValue: any,
    __result: any,
    __error?: any,
  ) {
    hideErrorDIV('errorDIV');
    clientID = getCookie('clientid');
    if (clientID && clientID !== '') {
      redirectToProject();
    } else {
      const cb = getCacheBuster();

      const publicKey =
        'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDa5kl2GxrW8QuZppNt961nYO0WvXhgXaylXw4LXLKxutphlaCuXvGPu/ONhTvPhMPlGp+Q3XAJxCsIT3HQtSw6pM4gd6bxqI5FLMtIqFeX0eWCPM4gPCJJkvAjma5qFD+AU3XO18I/YQCI+V3q7iJtPvYNsx03dNCpesryZDEefQIDAQAB';
      const encryptor = new JSEncrypt();
      encryptor.setPublicKey(publicKey);
      const bodyObj = {
        username: userName,
        password: passWord,
      };
      const encrypted = encryptor.encrypt(JSON.stringify(bodyObj));

      _executeService(
        'identity?captcha=' + captchaValue + '&cb=' + cb,
        encrypted,
        function (result: any) {
          result = JSON.parse(result);
          if (__result) __result(result);
        },
        function (error: any) {
          if (__error) __error(error);
        },
      );
    }
  };

  const getLoadingElement = function () {
    if (lastAjaxElement.length > 0)
      return lastAjaxElement[lastAjaxElement.length - 1];
    else return $(document.body);
  };

  const setLastAjaxElement = function (aelement: any) {
    lastAjaxElement.push(aelement);
  };

  const unSetLastAjaxElement = function (aelement: any) {
    let indexToDelete = -1;
    lastAjaxElement.some(function (element: any, index: number) {
      if (element[0].id === aelement[0].id) {
        indexToDelete = index;
        return true;
      }
    });
    if (indexToDelete > -1) lastAjaxElement.splice(indexToDelete, 1);
  };

  const executeService = function (url: string, __result: any, __error?: any) {
    _executeService(url, null, __result, __error);
  };

  const _executeService = function (
    url: string,
    body: any,
    __result: any,
    __error?: any,
  ) {
    let socket: any = null;
    if (window.XMLHttpRequest) {
      socket = new XMLHttpRequest();
      if (socket.overrideMimeType) {
        socket.overrideMimeType('text/javascript');
      }
    } else {
      const a = [
        'Msxml2.XMLHTTP.6.0',
        'Msxml2.XMLHTTP.3.0',
        'Msxml2.XMLHTTP',
        'Microsoft.XMLHTTP',
      ];
      for (let i = 0, l = a.length; i < l; i++)
        try {
          socket = new window.ActiveXObject(a[i]);
          break;
        } catch (e) {
          socket = null;
        }
    }

    if (!socket) return;
    socket.onreadystatechange = function () {
      if (socket.readyState === 4) {
        elementCount -= 1;

        if (socket.status === 200) {
          const aresponsetext = socket.responseText.trim();
          __result(aresponsetext);
        } else {
          if (__error)
            __error('Status : 0, Response : Cannot connect to server.');
        }
      }
    };

    if (body) socket.open('POST', settings.portalBaseAddress + url);
    else socket.open('GET', settings.portalBaseAddress + url);
    elementCount += 1;
    socket.send(body);
  };

  const getSettings = function (continueEvent: any, __error?: any) {
    let socket: any = null;
    if (window.XMLHttpRequest) {
      socket = new XMLHttpRequest();
      if (socket.overrideMimeType) {
        socket.overrideMimeType('text/javascript');
      }
    } else {
      const a = [
        'Msxml2.XMLHTTP.6.0',
        'Msxml2.XMLHTTP.3.0',
        'Msxml2.XMLHTTP',
        'Microsoft.XMLHTTP',
      ];
      for (let i = 0, l = a.length; i < l; i++)
        try {
          socket = new ActiveXObject(a[i]);
          break;
        } catch (e) {
          socket = null;
        }
    }

    if (!socket) return;
    socket.onreadystatechange = function () {
      if (socket.readyState === 4) {
        if (socket.status === 200) {
          const aresponsetext = socket.responseText.trim();
          settings = JSON.parse(aresponsetext);

          /* IMPORTANT! INCLUDE PEOPLECERT AS A COOKIE ACCESS DOMAIN */
          settings.cookieAccessDomains.push({
            id: '99',
            domain: peopleCertSso,
          });

          if (continueEvent) continueEvent();
        } else {
          if (__error)
            __error('Status : 0, Response : Cannot connect to server.');
        }
      }
    };
    socket.open('GET', 'appsettings.txt');
    socket.send(null);
  };

  const showErrorNotification = function (aMessage: any, errorDIV: any) {
    if (errorDIV) {
      const input = document.getElementById(errorDIV);
      if (input !== null) input.innerText = aMessage;
      showErrorDIV(errorDIV);
    }
  };

  const checkForLogout = function () {
    const URLparams = getURLparams();
    if (URLparams && URLparams.lg) {
      deleteCookies();
      clientID = undefined;
    }
  };

  const getOriginWebSite = function () {
    const URLparams = getURLparams();

    if (URLparams) {
      if (typeof URLparams.sqa !== 'undefined') isSQAmode = true;
      if (URLparams.ReturnUrl)
        originWebSite = URLparams.ReturnUrl.toLowerCase();
    }
  };

  const GetAdditionalParams = function () {
    let returnString = '';
    const URLparams = getURLparams();
    const keys = Object.getOwnPropertyNames(URLparams).filter(function (name) {
      return name.toLowerCase() !== 'returnurl' && name.toLowerCase() !== 'lg';
    });
    for (let i = 0; i < keys.length; i++)
      returnString +=
        (i === 0 ? '?' : '&') + keys[i] + '=' + URLparams[keys[i]];
    return returnString;
  };

  const redirectToProject = function () {
    setCookie('clientid', clientID, 1);
    const userAcess = localStorage.getItem('user_access');
    const excludedDomains: any = [];
    manageDomainCookies('add', excludedDomains);
    setTimeout(function () {
      getDestination();
    }, 2000);
  };

  const getDestination = function () {
    if (clientID) {
      const destination = '';

      /* IMPLEMENT HERE */
      console.log(
        'Now You can redirect client to your local Target OR You can ask peoplecert accessportal for the Target on below GetDestinationAsync request.',
      );
      const loginBtnInput = document.getElementById('loginBtn');
      if (loginBtnInput !== null) loginBtnInput.innerHTML = 'SIGN IN';
      disableButton($('#loginBtn'), true);
      window.location.href = './sampleExternalApp/ExternalApplication.html';
      return;

      executeService(
        'routing/GetDestinationAsync?origin=' +
          originWebSite +
          '&clientid=' +
          clientID,
        function (result: any) {
          result = JSON.parse(result);
          console.log(
            'Peoplecert says destination is: ' +
              result.reasonPhrase.toLowerCase(),
          );
          if (loginBtnInput !== null) loginBtnInput.innerHTML = 'SIGN IN';
          disableButton($('#loginBtn'), true);
          return;
        },
        function (error: any) {
          showErrorNotification(error, 'errorDIV');
          if (loginBtnInput !== null) loginBtnInput.innerHTML = 'SIGN IN';
          disableButton($('#loginBtn'), true);
        },
      );
    }
  };

  const showErrorDIV = function (errorDIV: any) {
    const errObj = document.getElementById(errorDIV);
    // const parentObj = errObj.parentElement;
    if (errObj !== null) errObj.style.display = 'block';
  };

  const hideErrorDIV = function (errorDIV: any) {
    const errObj = document.getElementById(errorDIV);
    if (errObj !== null) errObj.style.display = 'none';
  };

  const resetPassword = function (email: string, __result: any, __error?: any) {
    executeService(
      'actions/ResetPassword?email=' +
        email +
        '&domain=' +
        window.location.host,
      function (result: any) {
        result = JSON.parse(result);
        if (result.statusCode === 200) {
          __result();
        }
      },
      function (error: any) {
        showErrorNotification(
          'A technical error has occured: ' + error,
          'errorDIV',
        );
      },
    );
  };

  const deleteCookies = function () {
    deleteCookie('clientid');
    manageDomainCookies('remove', []);
    clientID = undefined;
    sessionStorage.clear();
  };

  const manageDomainCookies = function (
    action: any,
    excludedDomains: any,
    clientId?: string,
  ) {
    console.log(
      'PeoplecertExternalApplication > manageDomainCookies > clientId',
      clientId,
    );
    // const allDomains = settings.cookieAccessDomains;
    const domains: any = [
      {
        id: '1',
        domain: 'https://stage-accessportal.peoplecert.org/sso',
      },
    ];
    // if (excludedDomains && excludedDomains.length > 0) {
    //   allDomains.forEach(function (dm) {
    //     if (excludedDomains.indexOf(dm.id) < 0) {
    //       domains.push(dm);
    //     }
    //   });
    // } else {
    //   domains = allDomains;
    // }

    if (domains) {
      $('#iframe').empty();
      domains.forEach(function (dm: any) {
        console.log(
          'sso request: ' +
            dm.domain +
            '?clientid=' +
            (action === 'remove' ? '' : clientId ? clientId : ''),
        );
        callbacks.push({ id: dm.id, counter: domains.length });
        const strFrame =
          '<iframe onload="domainLoaded(\'' +
          dm.id +
          '\')" src="' +
          dm.domain +
          '?clientid=' +
          (action === 'remove' ? '' : clientId ? clientId : '') +
          '" style="width:0;height:0;border:0; border:none;"></iframe>';
        $('#iframe').append(strFrame);
      });
    }
  };

  const processDomainCallback = function (id: any) {
    for (let i = 0; i < callbacks.length; i++) {
      if (id === callbacks[i].id) {
        callbacks[i].counter -= 1;
        if (callbacks[i].counter === 0) {
          callbacks.splice(i, 1);
          getDestination();
        }
        break;
      }
    }
  };

  const validateEmail = function (email: string) {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  const checkMaintenance = function (__result: any, __error?: any) {
    hideErrorDIV('errorDIV');
    executeService(
      'actions/CheckMaintenance',
      function (result: any) {
        result = JSON.parse(result);
        if (__result) __result(result);
      },
      function (error: any) {
        if (__error) __error(error);
      },
    );
  };

  const disableButton = function (button: any, enabled: any) {
    button.prop('disabled', !enabled);
    let color = '#FF3200';
    if (!enabled) color = '#FFB6A3';
    button.prop(
      'style',
      'background-color:' + color + ';border-color:' + color,
    );
  };

  const showCaptcha = function (__result: any, __error?: any) {
    _executeService(
      'captcha',
      '{}',
      function (result: any) {
        result = JSON.parse(result);
        if (__result) __result(result);
      },
      function (error: any) {
        if (__error) __error(error);
      },
    );
  };

  const domainLoaded = (id: any) => {
    console.log('domainLoaded react', id);
    //   window.application.processDomainCallback(id);
  };

  return {
    getOriginWebSite,
    checkForLogout,
    getSettings,
    deleteCookie,
    executeService,
    checkMaintenance,
    redirectToProject,
    loginApplication,
    manageDomainCookies,
    clientID,
  };
};

function domainLoaded(id: any) {
  console.log('domainLoaded js', id);
  //   window.application.processDomainCallback(id);
}
