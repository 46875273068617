import { useContext, useEffect, useState } from 'react';

import { AccountDataContext, LoginTypeContext } from 'modules/context';
import { api, API } from 'modules/api';
import { LoginTypeEnum, NotificationItem } from 'modules/utils/types';
import logger from 'modules/logger';

export const useRefreshNotifications = () => {
  const { accountDataContext, setAccountDataContext } =
    useContext(AccountDataContext);
  const { loginTypeContext } = useContext(LoginTypeContext);
  const [notifications, setNotifications] = useState<NotificationItem[]>([]);

  useEffect(() => {
    if (accountDataContext && loginTypeContext === LoginTypeEnum.INDIVIDUAL) {
      //TODO done useEffect
      let active = true;
      (async () => {
        try {
          const responseNotifications = await api(API.GET_USER_NOTIFICATIONS());
          if (active) {
            accountDataContext.notifications = responseNotifications.data;
            setAccountDataContext(accountDataContext);
            setNotifications(responseNotifications.data);
          }
        } catch (error) {
          active &&
            logger.error('Get User Notifications Error:', error.message);
        }
      })();

      return () => {
        active = false;
      };
    }
  }, [accountDataContext, loginTypeContext, setAccountDataContext]);

  return { notifications } as const;
};
