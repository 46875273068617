import React from 'react';

import { InputSize, InputType } from 'components/Inputs/Input/constants';
import { QuestionProps } from '../Template/types';
import * as Input from 'components/Inputs/Input';
import * as Question from 'components/Question';

type QuestionTextProps<T> = QuestionProps<T> & {
  autoComplete?: string;
  readOnly?: boolean;
  type?: InputType;
};

function Component<T>({
  autoComplete,
  control,
  disabled,
  help,
  isSubmitting,
  label,
  question,
  readOnly,
  type,
  size = InputSize.REGULAR,
}: QuestionTextProps<T>) {
  return (
    <Question.Template
      control={control}
      help={help}
      label={label}
      question={question}
    >
      {({ invalid, name, onBlur, onChange, ref, value }) => (
        <Input.String
          autoComplete={autoComplete}
          forwardedRef={ref}
          id={name}
          invalid={invalid}
          label={label}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          readOnly={isSubmitting || readOnly}
          tabIndex={disabled ? -1 : 0}
          value={value}
          size={size}
          type={type}
        />
      )}
    </Question.Template>
  );
}

export default React.memo(Component) as typeof Component;
