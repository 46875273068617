import React from 'react';

import { ButtonColour } from 'components/Button/constants';
import { getLink } from 'modules/utils/utils';
import { KeyTemplatesItemIcon } from './KeyTemplatesItemIcon';
import { ResourceHubItemType } from 'components/FeaturedResources/types';
import CtaButton from 'components/Cta/Button';

import styles from './styles.module.scss';

type KeyTemplatesItemProp = {
  keyTemplate: ResourceHubItemType;
};

export const KeyTemplatesItem: React.FunctionComponent<KeyTemplatesItemProp> =
  ({ keyTemplate }) => {
    return (
      <li className={styles.keyTemplatesItem}>
        <KeyTemplatesItemIcon
          contentType={keyTemplate.categorisation.content_type[0].slug}
        />
        <div>
          {(keyTemplate.short_title || keyTemplate.title) && (
            <p>
              {keyTemplate.short_title || keyTemplate.title?.substring(0, 30)}
            </p>
          )}
          <span>{keyTemplate.categorisation.content_type[0].title}</span>
        </div>
        <div>
          <CtaButton
            ariaLabel={
              keyTemplate.short_title || keyTemplate.title?.substring(0, 30)
            }
            colour={ButtonColour.BRAND_MONO_ONE}
            custom_url={getLink(
              keyTemplate.categorisation.content_type,
              keyTemplate.slug,
            )}
            small
          />
        </div>
      </li>
    );
  };

export default KeyTemplatesItem;
