/* eslint-disable */
export const AllowedKeys = [
  'Alt',
  'ArrowDown',
  'ArrowLeft',
  'ArrowRight',
  'ArrowUp',
  'Backspace',
  'CapsLock',
  'Control',
  'Delete',
  'Enter',
  'Escape',
  'Meta',
  'Tab',
  'Unidentified',
];

export enum InputMode {
  DECIMAL = 'decimal',
  EMAIL = 'email',
  NONE = 'none',
  NUMERIC = 'numeric',
  SEARCH = 'search',
  TEL = 'tel',
  TEXT = 'text',
  URL = 'url',
}

export enum InputSize {
  REGULAR = 'regular',
  SMALL = 'small',
  EXTRA_SMALL = 'extra-small',
}

export enum InputStyle {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  DATE = 'date',
}

export enum InputType {
  TEXT = 'text',
  DATE = 'date',
  PASSWORD = 'password',
}

export enum InputFormat {
  SEARCH = 'search',
  TEXT = 'text',
  DATE = 'date',
}

export enum InputAutocomplete {
  OFF = 'off',
  NEW_PASSWORD = 'new-password',
}

export const InputStringFormat = {
  SEARCH: InputFormat.SEARCH,
  TEXT: InputFormat.TEXT,
};

export type InputStringFormat = typeof InputFormat[keyof typeof InputFormat];

export const RegularExpressions: { [key: string]: RegExp } = {};

export const Sanitisers = {
  LEADING_WHITESPACE: /^\s+/g,
};

export const InputDateFormat = {
  DATE: InputFormat.DATE,
};
export type InputDateFormat = typeof InputFormat[keyof typeof InputFormat];
