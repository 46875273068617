import { useErrorModal } from 'modules/modals/hooks/useErrorModal';
import React, { useCallback, useEffect, useState } from 'react';
import * as Loading from 'components/Loading';
import logger from 'modules/logger';
import { useRef } from 'react';

export const Page404 = () => {
  const [displayModal, getErrorMessage404] = useErrorModal();
  const [loading404Message, setLoading404Message] = useState(true);
  const active = useRef(true);
  //TODO done useCallback
  const displayError = useCallback(async () => {
    try {
      const errorMessage404 = await getErrorMessage404();
      if (active.current) {
        setLoading404Message(false);
        displayModal('', errorMessage404, '404');
      }
    } catch (error) {
      active.current && logger.debug('Get Error Message 404 Error: ', error);
    }
  }, [displayModal, getErrorMessage404]);

  useEffect(() => {
    displayError();
  }, [displayError]);

  useEffect(() => {
    return () => {
      active.current = false;
    };
  }, []);

  return (
    <React.Fragment>{loading404Message && <Loading.Modal />}</React.Fragment>
  );
};
