import { useEffect, useState } from 'react';

import { API, api } from 'modules/api';
import { BadgesCandidateIdTypes, BadgesLoadedType } from '../types';
import { CandidateIdsGetResponse } from 'modules/api/endpoints/candidate-ids';
import logger from 'modules/logger';

export const useCandidateIds = (
  setLoaded: React.Dispatch<React.SetStateAction<BadgesLoadedType>>,
) => {
  const [candidateIds, setCandidateIds] = useState<
    BadgesCandidateIdTypes[] | undefined
  >();
  //TODO done useEffect
  useEffect(() => {
    let active = true;
    (async () => {
      try {
        const response: CandidateIdsGetResponse = await api(
          API.GET_CANDIDATE_ID(),
        );
        active && setCandidateIds(response.data);
      } catch (error) {
        active && logger.error('Get Candidate Numbers Error', error);
      } finally {
        active &&
          setLoaded(prevState => ({
            ...prevState,
            candidateId: true,
          }));
      }
    })();
    return () => {
      active = false;
    };
  }, [setLoaded]);

  return { candidateIds, setCandidateIds };
};
