import React, { useContext } from 'react';
import clsx from 'clsx';

import { AccountDataType, LoginTypeEnum } from 'modules/utils/types';
import { ReactComponent as ChevronSVG } from 'modules/theme/icons/chevrons/animate-chevron.svg';
import { ReactComponent as DropdownChevronSVG } from 'modules/theme/icons/chevrons/account-dropdown-chevron.svg';
import { LoginTypeContext } from 'modules/context';
import { useAccountMenu } from './hooks/useAccountMenu';
import { useProfile } from './hooks/useProfile';
import * as Button from 'components/Button';
import AccountMenuItems from './AccountMenuItems';

import styles from './styles.module.scss';

type AccountProps = {
  accountDataContext?: AccountDataType;
  handleCloseMenu: () => void;
};

const Account: React.FunctionComponent<AccountProps> = ({
  accountDataContext,
  handleCloseMenu,
}) => {
  const { loginTypeContext } = useContext(LoginTypeContext);
  const {
    buttonRef,
    navRef,
    showMenu,
    toggleAccountMenu,
    setToggleAccountMenu,
  } = useAccountMenu(styles);

  const { accessibleTitle, handleImageError, photo } =
    useProfile(accountDataContext);

  const classNames = clsx(
    styles.account,
    toggleAccountMenu && styles.open,
    !toggleAccountMenu && styles.closed,
    !showMenu && styles.hide,
  );
  return (
    <React.Fragment>
      <Button.Generic
        ariaControls="account-menu"
        ariaExpanded={toggleAccountMenu}
        ariaLabel="Account Menu"
        className={styles.accountInfo}
        forwardedRef={buttonRef}
        onClick={() => setToggleAccountMenu(!toggleAccountMenu)}
        title={accessibleTitle}
      >
        <img alt="" aria-hidden="true" onError={handleImageError} src={photo} />
        <span aria-hidden="true">{accountDataContext?.name}</span>
        {accountDataContext?.company &&
          loginTypeContext === LoginTypeEnum.CONSULTANT && (
            <span aria-hidden="true">{accountDataContext?.company}</span>
          )}
        <ChevronSVG />
      </Button.Generic>
      <nav
        aria-label="Account"
        className={classNames}
        id="account-menu"
        ref={navRef}
        role="navigation"
      >
        <DropdownChevronSVG />
        <ul>
          <AccountMenuItems
            accountDataContext={accountDataContext}
            handleCloseMenu={handleCloseMenu}
            setToggleAccountMenu={setToggleAccountMenu}
          />
        </ul>
      </nav>
    </React.Fragment>
  );
};

export default Account;
