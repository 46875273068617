import { useCallback, useEffect, useState } from 'react';

import { API, api } from 'modules/api';
import { useLoginToken } from 'modules/hooks';
import logger from 'modules/logger';

export const useChangePass = () => {
  const { logOut } = useLoginToken();
  const [changePassUrl, setChangePassUrl] = useState('');

  useEffect(() => {
    //TODO done useEffect
    let active = true;

    (async () => {
      try {
        const res = await api(API.GET_CHANGE_PASS_URL());
        active && setChangePassUrl(res.data);
      } catch (error) {
        active && logger.debug('Get Change Pass URL Error: ', error);
      }
    })();

    return () => {
      active = false;
    };
  }, []);

  const logoutAndRedirect = useCallback(
    (userName: string) => {
      logOut();
      window.location.href = `${changePassUrl}&userName=${userName}`;
    },
    [changePassUrl, logOut],
  );

  return [logoutAndRedirect] as const;
};
