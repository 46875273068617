import React from 'react';

import { AchievementsTypes } from '../../types';
import { BADGES_URL } from 'modules/utils/constants';
import { ButtonColour } from 'components/Button/constants';
import AchievementsItem from './AchievementsItem';
import CtaButton from 'components/Cta/Button';
import Skeleton from './Skeleton';

import styles from './styles.module.scss';

type AchievementsProps = {
  achievements?: AchievementsTypes[];
  loaded: boolean;
};

const Achievements: React.FunctionComponent<AchievementsProps> = ({
  achievements,
  loaded,
}) => {
  const isDesignations =
    achievements &&
    achievements?.findIndex((el: AchievementsTypes) => !el.isCertificate) > -1;

  return loaded ? (
    <React.Fragment>
      {achievements && achievements.length > 0 && isDesignations && (
        <section className={styles.achievements}>
          <h3>Your achievements</h3>
          <ul>
            {achievements &&
              achievements.map(
                (achievement: AchievementsTypes, index: number) => (
                  <AchievementsItem
                    achievement={achievement}
                    key={`achievement-${index}`}
                  />
                ),
              )}
          </ul>
          <div>
            <CtaButton
              defaultColour={ButtonColour.BRAND_BERRY}
              label="Go to Badges"
              page_link={BADGES_URL}
              small
            />
          </div>
        </section>
      )}
    </React.Fragment>
  ) : (
    <Skeleton />
  );
};

export default Achievements;
