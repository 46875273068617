import { useCallback, useEffect, useRef, useState } from 'react';

import { API, api } from 'modules/api';
import { BadgesTypes } from '../types';
import { BadgeSession } from '../components/Badges/constants';

export const useBadgesPolling = () => {
  const [timerRef, setTimerRef] = useState<any>();
  const [claimingStatus, setClaimingStatus] = useState(false);
  const cancelPolling = useCallback(() => {
    clearInterval(timerRef);
  }, [timerRef]);
  const active = useRef(true);

  const runPolling = useCallback(
    (
      badgeData: BadgesTypes[],
      setBadges: React.Dispatch<
        React.SetStateAction<BadgesTypes[] | undefined>
      >,
    ) => {
      const badgesInitData: BadgesTypes[] = [];
      const badgesUpdate = badgesInitData.concat(badgeData);
      // Filter badgeData for items with status 1 and 2
      const badgesStatusIndex = badgeData.findIndex(
        (el: BadgesTypes) => el.status === 1 || el.status === 2,
      );

      // If badgesStatusIndex contains badges with status 1 or 2
      // poll the `status update` endpoint
      if (badgesStatusIndex > -1) {
        let count = 0; // Counter to stop making calls || 6 === 1 minute
        let hasStatusChanged = false;
        setClaimingStatus(true);
        //TODO done setInterval
        const timer = setInterval(async () => {
          try {
            count++;
            hasStatusChanged = false;
            const newBadgesStatus = await api(API.GET_BADGES_STATUS());
            if (active.current) {
              // store the badge data that is not status 1 or 2
              const newBadgesIndex = newBadgesStatus.data.filter(
                (el: BadgesTypes) => el.status !== 4,
              );
              // loop through the new badges data to see if there is an update
              badgesUpdate.forEach((oldBadge: BadgesTypes) => {
                const newBadgesNameIndex = newBadgesStatus.data.findIndex(
                  (el: BadgesTypes) => el.systemName === oldBadge.systemName,
                );
                if (
                  newBadgesNameIndex > -1 &&
                  newBadgesStatus.data[newBadgesNameIndex].status !==
                    oldBadge.status
                ) {
                  oldBadge.status =
                    newBadgesStatus.data[newBadgesNameIndex].status;
                  hasStatusChanged = true;
                }
              });
              if (hasStatusChanged) {
                setBadges([...badgesUpdate]);
              }
              if (
                newBadgesIndex.length === 0 &&
                sessionStorage.getItem(BadgeSession.DASHBOARD_CLAIMING_TITLE) !=
                  null
              ) {
                sessionStorage.removeItem(
                  BadgeSession.DASHBOARD_CLAIMING_TITLE,
                );
              }
              // If the status endpoint does not have any item with
              // status 1 or 2 clear the timeout
              if (newBadgesIndex.length === 0 || count >= 9) {
                clearInterval(timer);
              }
            }
          } catch (error: any) {
            active.current && clearInterval(timer);
          }
        }, 10000);
        setTimerRef(timer);
      }
    },
    [],
  );

  useEffect(() => {
    return () => {
      active.current = false;
    };
  }, []);

  return {
    runPolling,
    cancelPolling,
    claimingStatus,
  };
};
