import React, { useContext, useState } from 'react';

import { AccountDataContext, LoginTypeContext } from 'modules/context';
import { DashboardLoadedType } from './types';
import {
  useAchievements,
  useBadges,
  useCandidateIds,
  useContentTypes,
  useDashboard,
  useEvents,
  useFeaturedResources,
  useInitClaimBadge,
  useKeyTemplates,
  useMaturityModelsHomePage,
  useRefreshNotifications,
  useUpdateBadges,
} from './hooks';
import { useBadgesPolling } from 'pages/badges/hooks/useBadgesPolling';
import Template from './Template';

export const Home: React.FunctionComponent = () => {
  const { accountDataContext } = useContext(AccountDataContext);
  const { loginTypeContext } = useContext(LoginTypeContext);

  const [loaded, setLoaded] = useState<DashboardLoadedType>({
    achievements: false,
    badges: false,
    contentTypes: false,
    claim: false,
    events: false,
    featuredResources: false,
    keyTemplates: false,
    maturityModels: false,
  });

  const { dashboard, isEmployee } = useDashboard();
  const { candidateIds } = useCandidateIds();
  const { featuredResources } = useFeaturedResources(setLoaded);
  const { contentTypes } = useContentTypes(setLoaded, dashboard);
  const { events } = useEvents(setLoaded, dashboard);
  const { keyTemplates } = useKeyTemplates(setLoaded, dashboard);
  const { cancelPolling, runPolling } = useBadgesPolling();
  const { badges, setBadges } = useBadges(
    runPolling,
    setLoaded,
    candidateIds,
    dashboard,
  );
  const { achievements, setAchievements } = useAchievements(
    setLoaded,
    candidateIds,
    dashboard,
  );
  const { maturityModelsUrl } = useMaturityModelsHomePage(setLoaded, dashboard);
  const { updateBadges } = useUpdateBadges(
    setAchievements,
    setBadges,
    cancelPolling,
    runPolling,
  );
  const { notifications } = useRefreshNotifications();
  const { claimStates } = useInitClaimBadge(setLoaded, dashboard);

  return loginTypeContext && accountDataContext ? (
    <Template
      achievements={achievements}
      badges={badges}
      candidateIds={candidateIds}
      claimStates={claimStates}
      contentTypes={contentTypes}
      dashboard={dashboard}
      featuredResources={featuredResources}
      events={events}
      isEmployee={isEmployee}
      notifications={notifications}
      loaded={loaded}
      keyTemplates={keyTemplates}
      maturityModelsUrl={maturityModelsUrl}
      updateBadges={updateBadges}
    />
  ) : null;
};
