import { useCallback, useEffect, useRef, useState } from 'react';
// import { useErrorModal } from 'modules/modals/hooks/useErrorModal';
import { API, api } from 'modules/api';
import { CpdTypes, cpdOptionsTypes } from '../types';
import logger from 'modules/logger';

export const useCpdTypes = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [cpdTypesOptions, setCpdTypesOptions] = useState<cpdOptionsTypes[]>();
  const active = useRef(true);
  //TODO done useCallback
  const fetchCpdTypes = useCallback(async () => {
    try {
      const res = await api(API.GET_BADGE_CPD_TYPES());
      if (active.current) {
        const selectOptions: cpdOptionsTypes[] = [];
        res.data?.forEach((el: CpdTypes) => {
          selectOptions.push({ label: el.name, value: el.id });
        });
        setCpdTypesOptions(selectOptions);
      }
    } catch (error) {
      active.current && logger.debug('Fetch CPD Types Error: ', error);
    } finally {
      active.current && setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    return () => {
      active.current = false;
    };
  }, []);

  return [fetchCpdTypes, isLoading, cpdTypesOptions] as const;
};
