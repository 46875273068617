import { useEffect, useState } from 'react';
import sleep from 'sleep-promise';

import { ALL_FILTERS_ELEMENT } from 'modules/utils/constants';
import { API, api } from 'modules/api';
import { filterType, ResourceHubLoadedType } from '../types';
import { LoginTypeEnum } from 'modules/utils/types';
import { slugIsFilter } from '../utils';
import logger from 'modules/logger';

export const useResourceHub = (
  setLoaded: React.Dispatch<React.SetStateAction<ResourceHubLoadedType>>,
  pathname: string,
  loaded: boolean,
  loginTypeContext?: LoginTypeEnum,
) => {
  const [bestPractices, setBestPractices] = useState<filterType>({ data: [] });
  const [contentTypes, setContentTypes] = useState<filterType>({ data: [] });
  const [topics, setTopics] = useState<filterType>({ data: [] });

  useEffect(() => {
    //TODO done useEffect
    let active = true;
    (async () => {
      try {
        const startTime = performance.now();
        const topicList = await api(
          API.GET_CONTENT_FROM_CONTENT_STACK(
            `get-topic-by-slug/${
              loginTypeContext !== undefined ? loginTypeContext : ''
            }`,
          ),
        );
        const bestPracticeList = await api(
          API.GET_CONTENT_FROM_CONTENT_STACK(
            `get-best-practice-by-slug/${
              loginTypeContext !== undefined ? loginTypeContext : ''
            }`,
          ),
        );
        const contentTypeList = await api(
          API.GET_CONTENT_FROM_CONTENT_STACK(
            `get-content-type-by-slug/${
              loginTypeContext !== undefined ? loginTypeContext : ''
            }`,
          ),
        );

        if (active) {
          const [topicResponse, bestPracticeResponse, contentTypeResponse] =
            await Promise.all([
              topicList.data,
              bestPracticeList.data,
              contentTypeList.data,
            ]);

          const endTime = performance.now();
          if (endTime - startTime < 400) {
            await sleep(250);
          }

          topicResponse?.data?.unshift(ALL_FILTERS_ELEMENT);
          setTopics(topicResponse);
          bestPracticeResponse?.data?.unshift(ALL_FILTERS_ELEMENT);
          setBestPractices(bestPracticeResponse);
          contentTypeResponse?.data?.unshift(ALL_FILTERS_ELEMENT);
          setContentTypes(contentTypeResponse);
        }
      } catch (error) {
        active && logger.error('Get Filters Error', error);
      } finally {
        active &&
          setLoaded(prevState => ({
            ...prevState,
            filters: true,
          }));
      }
    })();
    return () => {
      active = false;
    };
  }, [loginTypeContext, setLoaded]);

  useEffect(() => {
    setLoaded(prevState => ({
      ...prevState,
      contentType: false,
    }));
    if (pathname.replace(/\/$/, '').split('/').length === 4 && loaded) {
      const result = slugIsFilter(
        bestPractices,
        contentTypes,
        pathname.replace(/\/$/, '').split('/')[3].split(',')[0],
        topics,
      );
      setLoaded(prevState => ({
        ...prevState,
        contentType: !result,
      }));
    }
  }, [pathname, bestPractices, contentTypes, topics, loaded, setLoaded]);

  return {
    resources: {
      bestPractices,
      setBestPractices,
      contentTypes,
      setContentTypes,
      topics,
      setTopics,
    },
  };
};
