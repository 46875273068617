import React, { useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import { LoginTypeContext } from 'modules/context';
import { MOBILE } from 'modules/utils/constants';
import { NavMenuItem } from 'components/Header/Menu';
import { useFooter } from './hooks';
import { useLoginToken } from 'modules/hooks';
import LegalText from './LegalText';
import Logo from 'components/Logo';
import Menu from './Menu';
import MobileSlice from 'modules/theme/ims/footer-mobile-slice.svg';
import Slice from 'modules/theme/ims/footer-desktop-slice.svg';
import SocialIcons from './Social';

import styles from './styles.module.scss';

export type FooterContentType = {
  copyright_text: string;
  footer_text: string;
  social: {
    youtube_url: string;
    twitter_url: string;
    linkedin_url: string;
    facebook_url: string;
  };
};

type FooterProps = {
  navMenu?: NavMenuItem[];
  navLoaded?: boolean;
};

export const Footer: React.FunctionComponent<FooterProps> = ({
  navMenu,
  navLoaded,
}) => {
  const [loaded, setLoaded] = useState(false);
  const { loginTypeContext } = useContext(LoginTypeContext);
  const { pathname } = useLocation();
  const { isLoggedIn } = useLoginToken();

  const { footer } = useFooter(setLoaded);
  const isMobile = useMediaQuery({
    query: `(max-width:${MOBILE})`,
  });

  if (pathname === '/sign-in') return null;

  return (
    <footer className={styles.footer}>
      {!isMobile && loaded && (
        <span
          aria-hidden="true"
          style={{
            backgroundImage: `url(${Slice}`,
          }}
        ></span>
      )}
      {isMobile && loaded && (
        <span
          aria-hidden="true"
          style={{
            backgroundImage: `url(${MobileSlice}`,
          }}
        ></span>
      )}
      <div className="container">
        <div>
          <Logo
            isLoggedIn={isLoggedIn}
            pathname={pathname}
            role={loginTypeContext}
          />
          {isLoggedIn() && <Menu navMenu={navMenu} loaded={navLoaded} />}
        </div>
        <div>
          <LegalText footerContent={footer} loaded={loaded} />
          <SocialIcons footerContent={footer} loaded={loaded} />
        </div>
      </div>
    </footer>
  );
};
