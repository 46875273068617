import { useContext, useEffect, useState } from 'react';

import { API, api } from 'modules/api';
import { APIExceptions } from 'modules/api/exceptions';
import { BadgeSession } from 'pages/badges/components/Badges/constants';
import { ClaimBadgesGetResponse } from 'modules/api/endpoints/claim-badge';
import { DashboardLoadedType, DashboardType } from '../types';
import { LoginTypeContext } from 'modules/context';
import { LoginTypeEnum } from 'modules/utils/types';
import logger from 'modules/logger';

export const useInitClaimBadge = (
  setLoaded: React.Dispatch<React.SetStateAction<DashboardLoadedType>>,
  dashboard?: DashboardType,
) => {
  const { loginTypeContext } = useContext(LoginTypeContext);
  const [examNotPassed, setExamNotPassed] = useState(false);
  const [examNotPassedUrl, setExamNotPassedUrl] = useState('');

  useEffect(() => {
    if (
      dashboard &&
      loginTypeContext &&
      loginTypeContext !== LoginTypeEnum.CONSULTANT
    ) {
      let active = true;
      (async () => {
        try {
          const res: ClaimBadgesGetResponse = await api(API.GET_CLAIM_BADGES());
          if (active) {
            setExamNotPassed(false);
            if (
              sessionStorage.getItem(`Claiming:${res.data.assertionId}`) != null
            ) {
              sessionStorage.setItem(
                BadgeSession.DASHBOARD_CLAIMING_TITLE,
                BadgeSession.DASHBOARD_CLAIMING_VALUE,
              );
            }
          }
        } catch (error: any) {
          if (active) {
            if (
              error.response.data.type === APIExceptions.NoExamPassedException
            ) {
              setExamNotPassed(true);
              setExamNotPassedUrl(error.response.data.message);
            } else {
              logger.error('Failed to load claimable badge', error);
            }
          }
        } finally {
          active &&
            setLoaded(prevState => ({
              ...prevState,
              claim: true,
            }));
        }
      })();
      return () => {
        active = false;
      };
    }
  }, [dashboard, loginTypeContext, setLoaded]);

  return {
    claimStates: {
      examNotPassed,
      examNotPassedUrl,
      setExamNotPassed,
      setExamNotPassedUrl,
    },
  };
};
