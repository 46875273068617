import React, { useEffect, useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';

import { EventsActions, EventsCategories } from 'modules/gtm/constants';
import { LayoutVerticalPadding } from 'components/Layout/constants';
import { SignUpWithPaymentType } from '../types';
import { SubscriptionStateEnum } from 'modules/utils/types';
import { TriggerGTMEvent } from 'modules/gtm';
import { useStepOneSubmit } from './hooks/useStepOneSubmit';
import * as Layout from 'components/Layout';
import Back from '../Back';
import Footer from '../Footer';
import PageHeading from '../PageHeading';
import PaymentQuestions from './PaymentQuestions';
import PersonalInfoQuestions from './PersonalInfoQuestions';
import PurchaseSummary from '../PurchaseSummary';
import useSignUpWithPayment from './hooks/useSignUpWithPayment';
import VoucherCode from '../VoucherCode';

import styles from 'pages/sign-up/components/styles.module.scss';
import Skeleton from '../Skeleton';

const SignUpWithPayment: React.FunctionComponent<SignUpWithPaymentType> = ({
  candidateToken,
  countryCode,
  loading,
  pageInfo,
  peopleCertDefaultValues,
  productInfo,
  subscriptionPlan,
  subscriptionState,
  voucherCode,
}) => {
  const isMobileBreakpoint = useMediaQuery({ query: '(max-width: 767px)' });
  const {
    activeStep,
    defaultValues,
    handleBack,
    handleSubmitStep2,
    isSubmitting,
    setActiveStep,
    setFormData,
  } = useSignUpWithPayment({
    candidateToken,
    peopleCertDefaultValues,
    subscriptionPlan,
    subscriptionState,
  });

  const { handleStepOneSubmit } = useStepOneSubmit(setFormData, setActiveStep);

  useEffect(() => {
    TriggerGTMEvent({
      action: EventsActions.SIGN_UP,
      category: EventsCategories.PAGE_VIEWS,
      label: 'Payment',
    });
  }, []);

  const isPeopleCertUser = useMemo(
    () => subscriptionState === SubscriptionStateEnum.PeopleCert_User,
    [subscriptionState],
  );
  return (
    <Layout.Container className={styles.signUpPage}>
      <Layout.Content
        className={styles.signUpContent}
        verticalPadding={LayoutVerticalPadding.LARGE}
      >
        {!loading ? (
          <React.Fragment>
            {isMobileBreakpoint &&
              subscriptionState !== SubscriptionStateEnum.PeopleCert_User && (
                <Back href={process.env.REACT_APP_MARKETING_PAGE!} />
              )}
            <PageHeading {...pageInfo} />
            {activeStep === 1 && (
              <PersonalInfoQuestions
                countryCode={countryCode}
                defaultValues={defaultValues}
                handleSubmit={handleStepOneSubmit}
                isPeopleCertUser={isPeopleCertUser}
                isSubmitting={isSubmitting}
                productInfo={productInfo}
                voucherCode={voucherCode}
              />
            )}

            {activeStep === 2 && (
              <PaymentQuestions
                defaultValues={defaultValues}
                handleBack={handleBack}
                handleSubmit={handleSubmitStep2}
                isSubmitting={isSubmitting}
                isUserFromPeopleCert={
                  candidateToken !== undefined && candidateToken.length > 0
                }
                productInfo={productInfo}
                voucherCode={voucherCode}
              />
            )}
          </React.Fragment>
        ) : (
          <Skeleton />
        )}
        <Footer />
      </Layout.Content>
      {!isMobileBreakpoint && (
        <Layout.Sidebar
          className={styles.signUpSidebar}
          verticalPadding={LayoutVerticalPadding.LARGE}
        >
          <PurchaseSummary
            showBackLink={!isPeopleCertUser}
            {...productInfo}
            loading={loading}
          >
            {!isPeopleCertUser && (
              <VoucherCode {...voucherCode} isSubmitting={isSubmitting} />
            )}
          </PurchaseSummary>
        </Layout.Sidebar>
      )}
    </Layout.Container>
  );
};

export default React.memo(SignUpWithPayment);
