import { useCallback, useEffect, useRef, useState } from 'react';

import { API, api } from 'modules/api';
import { BadgesCandidateIdTypes } from '../types';
import { EventsActions, EventsCategories } from 'modules/gtm/constants';
import { Model } from '../components/model';
import { TriggerGTMEvent } from 'modules/gtm';
import { UseFormReset } from 'react-hook-form';
import { useModal } from './useModal';
import logger from 'modules/logger';

export const useSubmitCandidate = (
  setCandidateIds: React.Dispatch<
    React.SetStateAction<BadgesCandidateIdTypes[] | undefined>
  >,
  cancelPolling: () => void,
) => {
  const [candidateErrorState, setCandidateErrorState] = useState(false);
  const { displayModal } = useModal();
  const [isApiSubmitting, setIsApiSubmitting] = useState(false);
  const active = useRef(true);

  //TODO done useCallback
  const postCandidateId = useCallback(
    async (newCandidateValue: string) => {
      try {
        setCandidateErrorState(false);
        const res = await api(API.POST_CANDIDATE_POST(newCandidateValue));
        if (active.current) {
          return res.data;
        }
      } catch (error: any) {
        if (active.current) {
          switch (error.response.data.type) {
            case 'CandidateIdNotFoundException':
              setCandidateErrorState(true);
              break;
            case 'CandidateIdAlreadyExistsException':
              displayModal(
                'Error',
                'Oops, it looks like this candidate number has already been added. Please try again or visit our <a href="/help">Help page</a>',
              );
              break;
            case 'CandidateIdClaimCheckNoExamException':
              displayModal(
                'Error',
                `Oops, it looks like there aren't any valid exams associated with this candidate number. Please try again or visit our <a href="/help">Help page</a>`,
              );
              break;
            case 'CandidateIdClaimCheckValidationException':
              displayModal(
                'Error',
                `We are sorry but we couldn't add that candidate number to your account. Please check that the name on your account matches the name on your exam or contact Axelos for help`,
              );
              break;
            default:
              displayModal(
                'Error',
                "We are sorry but we couldn't add that candidate number to your account. Please check that the name on your account matches the name on your exam or contact Axelos for help",
              );
          }
        }
      }
    },
    [displayModal],
  );

  //TODO done useCallback
  const handleSubmit = useCallback(
    async (data: Model, reset: UseFormReset<Model>) => {
      if (!isApiSubmitting) {
        try {
          setIsApiSubmitting(true);
          const candidateId = await postCandidateId(data.candidateId);
          if (active.current && candidateId) {
            cancelPolling();
            displayModal(
              'We are retrieving your badges!',
              'You will be able to claim them shortly. Please allow a few minutes for badges to show after you have claimed them.',
            );
            const actionType =
              candidateId.length === 1
                ? EventsActions.CLICK_CTA_ON_BADGING_PAGE
                : EventsActions.CLICK_CTA_ON_RHS_OF_BADGING_PAGE;
            TriggerGTMEvent({
              action: actionType,
              category: EventsCategories.CANDIDATE_NUMBERS,
              label: '',
            });
            setCandidateIds(candidateId);
            reset({ candidateId: '' });
          }
        } catch (error) {
          active.current && logger.debug('Badges Submit Error: ', error);
        } finally {
          active.current && setIsApiSubmitting(false);
        }
      }
    },
    [
      cancelPolling,
      displayModal,
      isApiSubmitting,
      postCandidateId,
      setCandidateIds,
    ],
  );

  useEffect(() => {
    return () => {
      active.current = false;
    };
  }, []);

  return {
    handleSubmit,
    isApiSubmitting,
    candidateErrorState,
  };
};
