import React from 'react';

import { ButtonColour } from 'components/Button/constants';
import { ContentTypesType } from './types';
import { getLink, linkText, readTimeText } from 'modules/utils/utils';
import { RESOURCE_HUB_URL } from 'modules/utils/constants';
import ResourceHubItem from 'components/ResourceHubItem';
import CtaButton from 'components/Cta/Button';

import styles from './styles.module.scss';
import Skeleton from './Skeleton';

export type ContentTypesListProps = {
  buttonLabel?: string;
  contentTypeName?: string;
  data?: ContentTypesType[];
  loaded: boolean;
  title?: string;
};

const ContentTypesList: React.FunctionComponent<ContentTypesListProps> = ({
  buttonLabel,
  contentTypeName,
  data,
  loaded,
  title,
}) => {
  return loaded ? (
    <React.Fragment>
      {data && data.length > 0 && (
        <div className={styles.contentTypeList}>
          {title && <h2>{title}</h2>}
          <ul>
            <li>
              {data.map((contentType: ContentTypesType, index: number) => (
                <ResourceHubItem
                  bookmarked={contentType.bookmarked}
                  buttonLabel={linkText(
                    contentType.categorisation?.content_type[0].slug,
                  )}
                  description={contentType.short_description}
                  image={contentType?.images?.featured_image?.url}
                  key={`content-types-${index}`}
                  liked={contentType.liked}
                  likes={contentType.likes}
                  publicationDate={contentType.meta.publication_date}
                  readTimeText={readTimeText(
                    contentType.categorisation?.content_type[0].slug,
                  )}
                  slug={getLink(
                    contentType.categorisation?.content_type,
                    contentType.slug,
                  )}
                  timeToRead={contentType.meta.time_to_read}
                  title={contentType.short_title}
                  uid={contentType.uid}
                />
              ))}
            </li>
          </ul>
          {contentTypeName && buttonLabel && (
            <div>
              <CtaButton
                defaultColour={ButtonColour.BRAND_BERRY}
                label={buttonLabel}
                custom_url={`${RESOURCE_HUB_URL}${contentTypeName}`}
                small
              />
            </div>
          )}
        </div>
      )}
    </React.Fragment>
  ) : (
    <Skeleton />
  );
};

export default ContentTypesList;
