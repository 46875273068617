import React, { useCallback, useMemo } from 'react';
import clsx from 'clsx';

import { ArrowSpinnerColour } from 'components/Spinner/constants';
import { ButtonColour, ButtonStyle } from 'components/Button/constants';
import { InputSize } from 'components/Inputs/Input/constants';
import * as Button from 'components/Button';
import * as Input from 'components/Inputs/Input';
import Spinner from 'components/Spinner';

import styles from './styles.module.scss';

type VoucherCodeType = {
  couponCodeApplied: boolean;
  couponCodeMessage: string;
  couponCodePreviouslyApplied: boolean;
  couponCodeSubmitting: boolean;
  isSubmitting: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClick: () => false | void;
  setCouponCodeApplied?: React.Dispatch<React.SetStateAction<boolean>>;
  value: string;
};

const VoucherCode: React.FC<VoucherCodeType> = ({
  couponCodeApplied,
  couponCodeMessage,
  couponCodePreviouslyApplied,
  couponCodeSubmitting,
  isSubmitting,
  onChange,
  onClick,
  value,
}) => {
  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === 'Enter') {
        onClick();
        event.stopPropagation();
      }
    },
    [onClick],
  );

  const isInvalid = useMemo(
    () => !couponCodeApplied && couponCodeMessage.length > 0,
    [couponCodeApplied, couponCodeMessage.length],
  );

  const classNames = clsx(
    styles.voucherCode,
    couponCodeSubmitting && styles.submitting,
  );

  return (
    <div className={classNames}>
      <Input.String
        invalid={isInvalid}
        label="Voucher code"
        name="voucherCode"
        onChange={onChange}
        onKeyDown={handleKeyDown}
        readOnly={isSubmitting || couponCodeSubmitting || couponCodeApplied}
        size={InputSize.SMALL}
        value={value}
      />
      <Button.Generic
        className={styles.button}
        colour={
          couponCodeApplied ? ButtonColour.BRAND_BERRY : ButtonColour.BRAND_AQUA
        }
        disabled={isSubmitting || couponCodePreviouslyApplied}
        label={couponCodeApplied ? 'Remove' : 'Apply'}
        onClick={onClick}
        style={ButtonStyle.SECONDARY}
      >
        {couponCodeSubmitting && (
          <Spinner
            background={false}
            className={styles.spinner}
            colour={ArrowSpinnerColour.WHITE}
          />
        )}
      </Button.Generic>
      {isInvalid && <span aria-live="polite">{couponCodeMessage}</span>}
    </div>
  );
};

export default React.memo(VoucherCode);
