import { SignIn } from 'pages';
import React from 'react';
import { Route } from 'react-router-dom';
import { ProtectedRoutePropsType } from './types';

export const ProtectedRouteSignin: React.FunctionComponent<ProtectedRoutePropsType> =
  ({ path }) => {
    // return null;
    return (
      <Route path={path}>
        <SignIn />
      </Route>
    );
  };
