import React from 'react';
import { useMediaQuery } from 'react-responsive';

import { BadgesCandidateIdTypes } from '../../types';
import {
  ButtonColour,
  ButtonStyle,
  ButtonTarget,
} from 'components/Button/constants';
import { defaultValues, model, schema } from '../model';
import { InputSize } from 'components/Inputs/Input/constants';
import { useSubmitCandidate } from '../../hooks/useSubmitCandidate';
import * as Button from 'components/Button';
import * as Hyperlink from 'components/Hyperlink';
import * as Question from 'components/Question';
import Questions from 'components/Questions';

import styles from './styles.module.scss';
import Skeleton from './Skeleton';

type AddCandidateProps = {
  candidateId?: BadgesCandidateIdTypes[];
  cancelPolling: () => void;
  loaded: boolean;
  setCandidateIds: React.Dispatch<
    React.SetStateAction<BadgesCandidateIdTypes[] | undefined>
  >;
};

const AddCandidate: React.FunctionComponent<AddCandidateProps> = ({
  candidateId,
  cancelPolling,
  loaded,
  setCandidateIds,
}) => {
  const { handleSubmit, isApiSubmitting, candidateErrorState } =
    useSubmitCandidate(setCandidateIds, cancelPolling);

  const placeholderBreakpoint = useMediaQuery({
    query: '(max-width: 1700px) and (min-width: 768px), (max-width: 484px)',
  });

  const placeholder = placeholderBreakpoint
    ? 'Candidate Number'
    : 'Enter Your Candidate Number';

  return loaded ? (
    <React.Fragment>
      {candidateId && candidateId.length > 0 && (
        <div className={styles.addCandidate}>
          <h3>Got a new candidate number?</h3>
          <Questions
            className={styles.addCandidateForm}
            defaultValues={defaultValues}
            enableReinitialize={true}
            onSubmit={handleSubmit}
            schema={schema}
          >
            {({ control, isFormSubmitting }) => (
              <React.Fragment>
                <Question.Text
                  control={control}
                  help="This is the long number that can usually be found on your exam certificate or in the personal details section of your PeopleCert account."
                  isSubmitting={isApiSubmitting || isFormSubmitting}
                  question={model.candidateId}
                  label={placeholder}
                  size={InputSize.EXTRA_SMALL}
                />
                <Button.Submit
                  ariaLabel="Submit"
                  colour={ButtonColour.BRAND_MONO_ONE}
                  disabled={isApiSubmitting || isFormSubmitting}
                  isSubmitting={isApiSubmitting || isFormSubmitting}
                  style={ButtonStyle.PRIMARY}
                  small
                />
              </React.Fragment>
            )}
          </Questions>
          {candidateErrorState && (
            <p className={styles.errorResponse}>
              Sorry, we don't recognise this candidate number. Please try again
              or visit our{' '}
              <Hyperlink.PageLink
                rel="noreferrer"
                slug="/help"
                target={ButtonTarget.BLANK}
              >
                Help page
              </Hyperlink.PageLink>
            </p>
          )}
        </div>
      )}
    </React.Fragment>
  ) : (
    <Skeleton />
  );
};

export default AddCandidate;
