import React, { useMemo } from 'react';

import {
  CountryCodeProps,
  PageInfoProps,
  PeopleCertSplitOptions,
  ProductInfoProps,
  VoucherCodeProps,
} from './components/types';
import { Model } from './components/QuestionSet/model';
import { PaymentInfoType } from 'pages/account-management/components/Subscriptions/PaymentInformation/types';
import { SubscriptionStateEnum } from 'modules/utils/types';
import { useErrorModal } from 'modules/modals/hooks/useErrorModal';
import { UserSubscriptionPlanType } from 'modules/api/endpoints/user-subscription-plan';
import SignUp from './components/SignUp';
import SignUpWithPayment from './components/SignUpWithPayment';
import Subscribe from './components/Subscribe';
import SubscribeWithPaymentMethod from './components/SubscribeWithPaymentMethod';

type TemplateType = {
  candidateToken: string;
  countryCode: CountryCodeProps;
  loading: boolean;
  pageInfo: PageInfoProps;
  paymentMethods: PaymentInfoType[];
  peopleCertDefaultValues: Model;
  peopleCertSplit: PeopleCertSplitOptions | null;
  productInfo: ProductInfoProps;
  subscriptionPlan: UserSubscriptionPlanType | null;
  userState: SubscriptionStateEnum;
  voucherCode: VoucherCodeProps;
};

const Template: React.FunctionComponent<TemplateType> = ({
  candidateToken,
  countryCode,
  loading,
  pageInfo,
  paymentMethods,
  peopleCertDefaultValues,
  peopleCertSplit,
  productInfo,
  subscriptionPlan,
  userState,
  voucherCode,
}) => {
  const [displayModal] = useErrorModal();
  const noPaymentMethods = useMemo(
    () => paymentMethods.length === 0,
    [paymentMethods.length],
  );

  const SignUpProps = useMemo(() => {
    return {
      candidateToken: candidateToken,
      countryCode: countryCode,
      pageInfo: pageInfo,
      peopleCertDefaultValues: peopleCertDefaultValues,
      productInfo: productInfo,
      subscriptionPlan: subscriptionPlan,
      subscriptionState: userState,
      voucherCode: voucherCode,
    };
  }, [
    candidateToken,
    countryCode,
    pageInfo,
    peopleCertDefaultValues,
    productInfo,
    subscriptionPlan,
    userState,
    voucherCode,
  ]);

  switch (userState) {
    case SubscriptionStateEnum.New_User:
    case SubscriptionStateEnum.Consultant_User:
      return <SignUpWithPayment loading={loading} {...SignUpProps} />;
    case SubscriptionStateEnum.PeopleCert_User:
      if (peopleCertSplit === PeopleCertSplitOptions.NoPayment) {
        return <SignUp loading={loading} {...SignUpProps} />;
      }
      return <SignUpWithPayment loading={loading} {...SignUpProps} />;
    case SubscriptionStateEnum.Subscription_Lapsed:
    case SubscriptionStateEnum.Subscription_Non_Renewing:
    case SubscriptionStateEnum.Subscribed:
      if (noPaymentMethods) {
        return (
          <Subscribe
            loading={loading}
            requiresCountry={false}
            {...SignUpProps}
          />
        );
      }
      return (
        <SubscribeWithPaymentMethod
          loading={loading}
          requiresCountry={false}
          paymentMethods={paymentMethods}
          {...SignUpProps}
        />
      );
    case SubscriptionStateEnum.Migrated_User:
      if (noPaymentMethods) {
        return (
          <Subscribe
            loading={loading}
            requiresCountry={true}
            {...SignUpProps}
          />
        );
      }
      return (
        <SubscribeWithPaymentMethod
          loading={loading}
          paymentMethods={paymentMethods}
          requiresCountry={true}
          {...SignUpProps}
        />
      );
    case SubscriptionStateEnum.Organisation_User:
      displayModal('You do not have permission to view this page');
      return null;
    default:
      return null;
  }
};

export default React.memo(Template);
