import React, { memo } from 'react';
import styles from '../../styles.module.scss';
import dayjs from 'dayjs';

type ClaimInfoTypes = {
  candidateNumber?: string;
  cpdCompleteThisYear: number;
  cpdClaimWindowEnd: string;
  cpdPointsRemainingThisYear: number;
  firstName?: string;
  lastName?: string;
  pointsRequiredRemaining: number;
};

const ClaimInfo: React.FunctionComponent<ClaimInfoTypes> = ({
  candidateNumber,
  cpdCompleteThisYear,
  cpdClaimWindowEnd,
  cpdPointsRemainingThisYear,
  firstName,
  lastName,
  pointsRequiredRemaining,
}) => {
  return (
    <div className={styles.claimInfo}>
      <h2>
        {firstName} {lastName}
      </h2>
      <span className={styles.candidateNumber}>
        Candidate number: <strong>{candidateNumber}</strong>
      </span>
      <ul>
        <li>
          Points logged: <strong>{cpdCompleteThisYear}</strong>
        </li>
        <li>
          Overall points remaining:{' '}
          <strong>{cpdPointsRemainingThisYear}</strong>
        </li>
        {pointsRequiredRemaining && pointsRequiredRemaining > 0 ? (
          <li>
            Professional Experience points left:{' '}
            <strong>{pointsRequiredRemaining}</strong>{' '}
            <span>
              (These are obligatory and must be claimed by{' '}
              <strong>{dayjs(cpdClaimWindowEnd).format('DD/MM/YYYY')}</strong>)
            </span>
          </li>
        ) : (
          <li>
            You have claimed all the Professional Experience points for this
            year.
          </li>
        )}
      </ul>
    </div>
  );
};

export default memo(ClaimInfo);
