import React, { useRef } from 'react';
import clsx from 'clsx';

import { ReactComponent as ChevronSVG } from 'modules/theme/icons/chevrons/chevron-right.svg';
import { useOutsideAlerter } from 'modules/utils/utils';
import * as Button from 'components/Button';
import * as Filters from '../../Filters';

import styles from './styles.module.scss';

type FiltersFilterProps = {
  addDeleteItems: any;
  closeFilters: () => void;
  isFilterSelected: boolean;
  itemsSelected: any[];
  listItems: any;
  setIsFilterSelected: any;
  setItemsSelected: any;
  title: string;
};

const FiltersFilter: React.FunctionComponent<FiltersFilterProps> = ({
  addDeleteItems,
  closeFilters,
  listItems,
  isFilterSelected,
  itemsSelected,
  setIsFilterSelected,
  setItemsSelected,
  title,
}) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const className = clsx(
    styles['filter-button'],
    isFilterSelected && styles.expanded,
  );

  useOutsideAlerter({
    buttonRef,
    containerRef,
    handler: closeFilters,
  });

  return (
    <div className={styles.filtersFilter}>
      <Button.Generic
        className={className}
        label={title}
        onClick={() => {
          closeFilters();
          setIsFilterSelected(!isFilterSelected);
        }}
        forwardedRef={buttonRef}
      >
        <ChevronSVG />
      </Button.Generic>
      {isFilterSelected && (
        <Filters.List
          addDeleteItems={addDeleteItems}
          containerRef={containerRef}
          itemsSelected={itemsSelected}
          listItems={listItems}
          setIsItemSelected={setIsFilterSelected}
          setListItems={setItemsSelected}
        />
      )}
    </div>
  );
};

export default FiltersFilter;
