import React from 'react';

import { linkText, readTimeText } from 'modules/utils/utils';
import { ReactComponent as ErrorIcon } from 'modules/theme/ims/error_icon.svg';
import { ResourceSearchAzureType } from 'modules/api/endpoints/resource-search';
import ResourceHubItem from 'components/ResourceHubItem';
import Skeleton from './Skeleton';

import styles from './styles.module.scss';

const itemsToShow = (
  resourceHub: ResourceSearchAzureType[],
  numberOfitemsShown: number,
) =>
  resourceHub.length > 0 ? (
    resourceHub
      ?.slice(0, numberOfitemsShown)
      .map((page: ResourceSearchAzureType, index) => {
        return (
          <li key={`resource-hub-${index}`}>
            <ResourceHubItem
              bookmarked={page.bookmarked}
              buttonLabel={linkText(page.contentType)}
              contentType={page.contentType}
              description={page.description}
              image={page.image}
              liked={page.liked}
              likes={page.likes}
              slug={page.url}
              publicationDate={page.publicationDate}
              readTimeText={readTimeText(page.contentType)}
              timeToRead={page.timeToRead}
              title={page.title}
              uid={page.RowKey}
            />
          </li>
        );
      })
  ) : (
    <li className={styles.noResults}>
      <ErrorIcon />
      <h2>
        No Results were found!
        <br />
        Try changing your filters.
      </h2>
    </li>
  );

export const pageCardList = (
  resourceHub: ResourceSearchAzureType[],
  numberOfitemsShown: number,
  loaded: boolean,
): any => (
  <React.Fragment>
    {loaded ? (
      <ul className={`${styles.resourceHubList}`}>
        {itemsToShow(resourceHub, numberOfitemsShown)}
      </ul>
    ) : (
      <Skeleton />
    )}
  </React.Fragment>
);
