import React from 'react';

import { ButtonColour, ButtonStyle } from 'components/Button/constants';
import Questions from 'components/Questions';
import * as Button from 'components/Button';
import * as Question from 'components/Question';
import { useSignIn } from '../../hooks/useSignIn';
import { model, schema } from './model';

import styles from './styles.module.scss';
import { InputType } from 'components/Inputs/Input/constants';
import { ReactComponent as LogoSVG } from 'modules/theme/ims/logos/MyAxelosRegisterLogo.svg';

const SignIn: React.FunctionComponent = () => {
  const { defaultValues, handleSubmit, isApiSubmitting, loading, error } =
    useSignIn();

  return (
    <div className={styles.myAxelosSignIn}>
      <div className={styles.backgroundBrandingContainer}>
        <span className={styles.image}></span>

        <div className={styles.register}>
          <h1>
            Welcome to the best <br /> practice hub that's <br />
            <span>all about you.</span>
          </h1>
          <LogoSVG className={styles.logo} />
          <a className={styles.btnRegister} href="">
            Register
          </a>
        </div>

        <span className={styles.backgroundSlice}></span>
      </div>

      <div className={styles.myAxelosForm}>
        <div className={styles.formContainer}>
          <h2>Sign In</h2>
          <div className={styles.signInForm}>
            <Questions
              defaultValues={defaultValues}
              enableReinitialize={true}
              onSubmit={handleSubmit}
              schema={schema}
            >
              {({ control, isFormSubmitting }) => (
                <React.Fragment>
                  <label>Email Address</label>
                  <Question.Text
                    control={control}
                    label="Username"
                    question={model.username}
                  />
                  <label>Password</label>
                  <Question.Text
                    control={control}
                    label="Password"
                    question={model.password}
                    type={InputType.PASSWORD}
                  />
                  <p className={styles.error}>{error}</p>
                  <div className={styles.buttons}>
                    <Button.Submit
                      colour={ButtonColour.BRAND_AQUA}
                      // disabled={isApiSubmitting || isFormSubmitting || loading}
                      label="Sign In"
                      // isSubmitting={isApiSubmitting || isFormSubmitting}
                      // small
                      style={ButtonStyle.SECONDARY}
                      className={styles.next}
                    />
                    <a href="" className="forgotPassword">
                      Forgot your password?
                    </a>
                  </div>
                </React.Fragment>
              )}
            </Questions>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
