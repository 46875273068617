import { useCallback, useState } from 'react';

import { defaultValues, Model } from '../../QuestionSet/model';
import { SubscriptionStateEnum } from 'modules/utils/types';
import { UserSubscriptionPlanType } from 'modules/api/endpoints/user-subscription-plan';
import useSubscribeUser from 'pages/sign-up/hooks/useSubscribeUser';

type UseSignUpWithPaymentProps = {
  candidateToken: string;
  peopleCertDefaultValues: Model;
  subscriptionPlan: UserSubscriptionPlanType | null;
  subscriptionState: SubscriptionStateEnum;
};

function useSignUpWithPayment({
  candidateToken,
  peopleCertDefaultValues,
  subscriptionPlan,
  subscriptionState,
}: UseSignUpWithPaymentProps) {
  const { subscribeNewUser, isSubmitting } = useSubscribeUser();
  const [activeStep, setActiveStep] = useState(1);
  const [formData, setFormData] = useState<Model>(
    subscriptionState === SubscriptionStateEnum.PeopleCert_User
      ? { ...defaultValues, ...peopleCertDefaultValues }
      : defaultValues,
  );

  const handleBack = useCallback(() => {
    window.scrollTo(0, 0);
    setActiveStep(1);
  }, []);

  //TODO done useCallback
  const handleSubmitStep2 = useCallback(
    async (data: Model & { nonce: string }) => {
      await subscribeNewUser(
        {
          candidateNumber: data.candidateNumber,
          candidateToken: candidateToken,
          countryCode: data.countryCode,
          couponCode: subscriptionPlan?.couponCode,
          email: data.email,
          givenName: data.givenName,
          paymentNonce: data.nonce,
          subscriptionPlanId: subscriptionPlan?.subscriptionPlanId,
          surname: data.surname,
        },
        subscriptionState,
        subscriptionPlan?.subscriptionPlanId,
      );
    },
    [candidateToken, subscribeNewUser, subscriptionPlan, subscriptionState],
  );

  return {
    activeStep,
    defaultValues: formData,
    handleBack,
    handleSubmitStep2,
    isSubmitting,
    setActiveStep,
    setFormData,
  };
}

export default useSignUpWithPayment;
