import React, { SetStateAction, useEffect } from 'react';
import DOMPurify from 'isomorphic-dompurify';
import parse from 'html-react-parser';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

import { AccountDataType, LoginTypeEnum } from 'modules/utils/types';
import { api, API } from 'modules/api';
import {
  BEST_PRACTICES_COLORS,
  BRAND_BLACK_GRAPE,
  CACHING_ID_ITEM_NAME,
  COLOR_ITIL,
  COLOR_PROPATH,
  COLOR_TRANSFORMATION,
  COLOR_MATURITY,
  COLOR_RESILIA,
  CONGRATULATIONS_PAGE_URL,
  MM_ITIL_ROLE_NAME,
  MM_P3M3_ROLE_NAME,
  PRODUCT_CONSULTANT_ROLE,
  RESOURCE_HUB_URL,
  SIGN_UP_URL,
  TOKEN_PARAM_NAME,
  PRODUCT_P3M3_CONSULTANT_ROLE_NAME,
  CLIENT_ID_ITEM_NAME,
} from './constants';
import { SubscriptionStatus } from 'pages/account-management/components/Subscriptions/constants';

import logger from 'modules/logger';

export const getConfig = (bearerToken?: string) => {
  const token = bearerToken || sessionStorage.getItem(TOKEN_PARAM_NAME);
  let cachingId = sessionStorage.getItem(CACHING_ID_ITEM_NAME);
  const clientId = sessionStorage.getItem(CLIENT_ID_ITEM_NAME);

  if (!cachingId) {
    cachingId = uuidv4();
    sessionStorage.setItem(CACHING_ID_ITEM_NAME, cachingId);
  }

  return {
    headers: {
      Authorization: `Bearer ${token}`,
      CachingID: cachingId,
      clientid: clientId,
    },
  };
};

export const clearCache = () => {
  sessionStorage.removeItem(CACHING_ID_ITEM_NAME);
};

export const getRefreshToken = async (): Promise<string> => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/auth/refresh`,
      {},
      { withCredentials: true },
    );
    sessionStorage.setItem(TOKEN_PARAM_NAME, res.data);
    return res.data;
  } catch (error: any) {
    logger.error('Error getting refresh token', error);
    throw error;
  }
};

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const getDateFormatted = (date: string): string => {
  const d = new Date(date);
  return `${monthNames[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`;
};

export const getDateFormattedNotif = (date: string): string => {
  const d = new Date(date);
  return `${d.getDate()} ${monthNames[d.getMonth()]} ${d.getFullYear()}`;
};

export const getDateWithOrdinal = (date: string, addComa = true): string => {
  const d = new Date(date);
  const day = d.getDate();
  let ordinal;

  if (day > 3 && day < 21) {
    ordinal = 'th';
  } else {
    switch (day % 10) {
      case 1:
        ordinal = 'st';
        break;
      case 2:
        ordinal = 'nd';
        break;
      case 3:
        ordinal = 'rd';
        break;
      default:
        ordinal = 'th';
    }
  }
  return `${day}${ordinal} ${monthNames[d.getMonth()]}${
    addComa ? ',' : ''
  } ${d.getFullYear()}`;
};

export const getDateDayFormatted = (date: string): string => {
  const dayNames = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];

  const d = new Date(date);

  return `${dayNames[d.getDay()]}, ${d.getDate()} ${monthNames[d.getMonth()]}`;
};

export const isSameDate = (initDate: string, endDate?: string): boolean => {
  const initD = new Date(initDate);
  if (endDate) {
    const endD = new Date(endDate);
    if (
      initD.getDate() === endD.getDate() &&
      initD.getMonth() === endD.getMonth() &&
      initD.getFullYear() === endD.getFullYear()
    ) {
      return true;
    }
  }
  return false;
};
export const getDateTimeFormatted = (
  initDate: string,
  endDate?: string,
): string => {
  const initD = new Date(initDate);
  let endTime = '';

  if (endDate && endDate.includes('T') && isSameDate(initDate, endDate)) {
    const endD = new Date(endDate);

    endTime = ` - ${endD
      .toLocaleString('en-GB', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
        timeZone: 'Europe/London',
      })
      .replace(' ', '')}`;
  }

  return `  ${initD
    .toLocaleString('en-GB', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
      timeZone: 'Europe/London',
    })
    .replace(' ', '')}${endTime} GMT`;
};

export const useOutsideAlerter = ({
  buttonRef,
  containerRef,
  handler,
  setState,
}: {
  containerRef: React.RefObject<any>;
  buttonRef?: React.RefObject<any>;
  handler?: () => void;
  setState?: React.Dispatch<SetStateAction<any>>;
}) => {
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target) &&
        buttonRef &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        handler && handler();
        setState && setState(false);
      }
    };
    document.addEventListener('mouseup', handleClickOutside);
    return () => {
      document.removeEventListener('mouseup', handleClickOutside);
    };
  }, [buttonRef, containerRef, handler, setState]);
};

export const useCloseOnEscapeKey = ({
  handler,
  setState,
}: {
  handler?: () => void;
  setState?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  useEffect(() => {
    const handleEscapeKeyDown = (event: any) => {
      if (event.key === 'Escape') {
        handler && handler();
        setState && setState(false);
      }
    };
    document.addEventListener('keydown', handleEscapeKeyDown, false);
    return () => {
      document.removeEventListener('keydown', handleEscapeKeyDown, false);
    };
  }, [handler, setState]);
};

export const truncate = (
  string: string,
  maxLen: number,
  separator = ' ',
): string => {
  if (!string || string.length <= maxLen) return string;
  return `${string.substr(0, string.lastIndexOf(separator, maxLen))}...`;
};

export const stripTags = (string: string): string => {
  return string?.replace(/<[^>]+>/g, '');
};

export const removeEmptyPTags = (string: string) => {
  return string.replace(/(<p><\/p>|<br>)+/g, '');
};

export const htmlSafe = (string: string) => {
  return parse(DOMPurify.sanitize(string, { ADD_ATTR: ['target'] }));
};

export const stripParagraphTags = (string: string) => {
  return string?.replace(/\s*<\/?p[^>]*>\s*/g, '');
};

export const wrapTableTags = (string: string) => {
  return string
    ?.replace(/<table>/g, "<div class='table'><table>")
    .replace(/<\/(table)+>?/g, '</table></div>');
};

export const getColorCode = (colorName: string): string => {
  switch (colorName) {
    case BEST_PRACTICES_COLORS.ITIL:
      return COLOR_ITIL;
    case BEST_PRACTICES_COLORS.ProPath:
      return COLOR_PROPATH;
    case BEST_PRACTICES_COLORS.Transformation:
      return COLOR_TRANSFORMATION;
    case BEST_PRACTICES_COLORS.Maturity:
      return COLOR_MATURITY;
    case BEST_PRACTICES_COLORS.RESILIA:
      return COLOR_RESILIA;
    default:
      return BRAND_BLACK_GRAPE;
  }
};

export const getAccountData = async (
  token: string,
): Promise<AccountDataType | null> => {
  try {
    const res = await api(API.GET_ACCOUNT_DATA(token));
    console.log(`modules > utils > getAccountData res ${res}`);
    return getAccountDataObject(res?.data);
  } catch (error) {
    logger.error('Error getting account data', error);
    return null;
  }
};

export const getAccountDataObject = (data: any): AccountDataType => {
  return {
    name: data?.user?.displayName,
    firstName: data?.user?.givenName,
    lastName: data?.user?.surname,
    company: data?.user?.organizationName,
    email: data?.username,
    phone: data?.user?.phone,
    jobTitle: data?.user?.jobTitle,
    userRoles: data?.groups,
    candidateNumbers: data?.appUser?.candidateIds,
    profilePhoto: data?.appUser?.profileImageUrl,
    notifications: data?.appUser?.notifications,
    subscription: data?.subscription,
    lastLogin: data?.lastLoggedIn ?? new Date(0).toISOString().split('T')[0],
    userId: data?.appUser?.id,
    guid: data?.appUser?.guid,
  };
};

// Determines if string is missing a start of line (<) and an end of line (>)
export const hasStartAndEndTag = (string: string) => {
  return /^<|>$/.test(string);
};

export const removeLeadingTrailingBrTag = (string: string) => {
  return string.replace(/^\s*<br>|<br>\s*$/gi, '');
};

export const getLink = (content_type: any[], slug: string) => {
  if (content_type[0]?.slug && slug) {
    return `${RESOURCE_HUB_URL}${content_type[0]?.slug}/${slug}`;
  }
  return '';
};

export const linkText = (contentTypeSlug: string) => {
  let retText;
  switch (contentTypeSlug) {
    case 'tutorial':
      retText = 'Watch this tutorial';
      break;
    case 'webinar':
      retText = 'Watch this webinar';
      break;
    case 'podcast':
      retText = 'Listen to this podcast';
      break;
    default:
      retText = 'Read now';
  }
  return retText;
};

export const readTimeText = (contentTypeSlug: string) => {
  let readTimeVerb;
  switch (contentTypeSlug) {
    case 'tutorial':
    case 'webinar':
      readTimeVerb = 'watch';
      break;
    case 'podcast':
      readTimeVerb = 'listen';
      break;
    default:
      readTimeVerb = 'read';
  }
  return readTimeVerb;
};

export const getDateFormattedKeyTemplate = (date: string): string => {
  const d = new Date(date);
  return `${d.getDate() > 9 ? d.getDate() : '0' + d.getDate()}/${
    d.getMonth() > 8 ? d.getMonth() + 1 : '0' + (d.getMonth() + 1)
  }/${d.getFullYear()}`;
};

export const capitalizeFirstLetter = (text: string) =>
  text.charAt(0).toUpperCase() + text.slice(1);

export const logInRedirection = () => {
  // window.location.href = `${process.env.REACT_APP_BACKEND_URL!}/auth/login`;
  window.location.href = `${process.env.REACT_APP_FRONTEND_URL}/sign-in`;
};
export const redirectionToHome = () => {
  window.location.href = `${process.env.REACT_APP_FRONTEND_URL}/`;
};

export const logOutRedirection = () => {
  sessionStorage.removeItem(TOKEN_PARAM_NAME);
  // TODO - clear coolies clientid
  window.location.href = `/sign-in`;
  // window.location.href = `${process.env.REACT_APP_FRONTEND_URL}/sign-in`;
  // window.location.href = process.env.REACT_APP_LOGIN_REDIRECT_URL!;
};

export const marketingPageRedirection = () => {
  window.location.href = process.env.REACT_APP_MARKETING_PAGE!;
};

export const protectedURL = (url: string): boolean => {
  const policiesURL = new RegExp(/\/policies\?*/);
  return (
    !policiesURL.test(url) &&
    url !== CONGRATULATIONS_PAGE_URL &&
    url !== SIGN_UP_URL
  );
};

export const objectToQueryString = (obj: any) => {
  const str = [];
  for (const p in obj)
    if (
      Object.prototype.hasOwnProperty.call(obj, p) &&
      typeof obj[p] !== undefined &&
      obj[p] !== null
    ) {
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
    }
  return str.join('&');
};

export const getTokenExpDate = (token: string): number => {
  const base64Url = token.split('.')[1];
  const jsonPayload = Buffer.from(base64Url, 'base64').toString();
  const { exp } = JSON.parse(jsonPayload);
  return exp;
};

export const getNavMenu = (login: LoginTypeEnum) => {
  const navMenu = [
    {
      label: 'Home',
      link: '/',
    },
    {
      label: 'Resources',
      link: '/resource-hub/',
    },
  ];

  if (login === LoginTypeEnum.INDIVIDUAL) {
    navMenu.push({ label: 'Badges', link: '/badges/' });
  }

  navMenu.push({ label: 'Help', link: '/help/' });
  return navMenu;
};

export const isP3M3 = (accountDataContext?: AccountDataType): boolean => {
  return !accountDataContext?.userRoles.includes(MM_P3M3_ROLE_NAME);
};

export const isProductP3M3Consultant = (
  accountDataContext?: AccountDataType,
): boolean =>
  accountDataContext?.userRoles.includes(PRODUCT_P3M3_CONSULTANT_ROLE_NAME) ||
  false;

export const isITIL = (accountDataContext?: AccountDataType): boolean => {
  return !accountDataContext?.userRoles.includes(MM_ITIL_ROLE_NAME);
};

export const isFreeTrialUser = (accountDataContext: AccountDataType) =>
  accountDataContext.subscription?.status === SubscriptionStatus.IN_TRIAL;

export const isProductConsultant = (
  accountDataContext?: AccountDataType,
): boolean =>
  accountDataContext?.userRoles.includes(PRODUCT_CONSULTANT_ROLE) || false;
