import {
  CertificatesPropPathTitles,
  ProPathDesignationsTitles,
} from './constants';

export const certificatesPropPath = [
  {
    category: 'PRINCE 2',
    certificates: [
      {
        title: CertificatesPropPathTitles.PRINCE2_FOUNDATION,
        uniqueTitle: 'PRINCE2 Foundation',
      },
      {
        title: CertificatesPropPathTitles.PRINCE2_PRACTITIONER,
        uniqueTitle: 'PRINCE2 Practitioner',
      },
    ],
  },
  {
    category: 'PRINCE 2 Agile',
    certificates: [
      {
        title: CertificatesPropPathTitles.PRINCE2_AGILE_FOUNDATION,
        uniqueTitle: 'PRINCE2 Agile Foundation',
      },
      {
        title: CertificatesPropPathTitles.PRINCE2_AGILE_PRACTITIONER,
        uniqueTitle: 'PRINCE2 Agile Practitioner',
      },
    ],
  },
  {
    category: 'MSP',
    certificates: [
      {
        title: CertificatesPropPathTitles.MSP_FOUNDATION,
        uniqueTitle: 'MSP Foundation',
      },
      {
        title: CertificatesPropPathTitles.MSP_PRACTITIONER,
        uniqueTitle: 'MSP Practitioner',
      },
    ],
  },
  {
    category: 'MOR',
    certificates: [
      {
        title: CertificatesPropPathTitles.MOR_PRACTITIONER,
        uniqueTitle: 'MoR Practitioner',
      },
    ],
  },
  {
    category: 'P3O',
    certificates: [
      {
        title: CertificatesPropPathTitles.P3O_FOUNDATION,
        uniqueTitle: 'P3O Foundation',
      },
      {
        title: CertificatesPropPathTitles.P3O_PRACTITIONER,
        uniqueTitle: 'P3O Practitioner',
      },
    ],
  },
  {
    category: 'MOP',
    certificates: [
      {
        title: CertificatesPropPathTitles.MOP_FOUNDATION,
        uniqueTitle: 'MoP Foundation',
      },
      {
        title: CertificatesPropPathTitles.MOP_PRACTITIONER,
        uniqueTitle: 'MoP Practitioner',
      },
    ],
  },
];

export const proPathDesignations = [
  {
    title: ProPathDesignationsTitles.PROJECT_EXPERT,
    uniqueTitle: 'ProPath Project Expert',
  },
  {
    title: ProPathDesignationsTitles.AGILE_PROJECT_EXPERT,
    uniqueTitle: 'ProPath Agile Project Expert',
  },
  {
    title: ProPathDesignationsTitles.PROGRAMME_LEADER,
    uniqueTitle: 'ProPath Programme Leader',
  },
];
