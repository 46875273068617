import { DeepPartial, UnpackNestedValue } from 'react-hook-form';

import {
  BraintreeBillingAddress,
  BraintreeError,
  BraintreeThreeDSecureVerifyPayload,
} from './types';
import { braintreeDefaultValues } from './model';
import {
  BraintreeCardType,
  BraintreeErrorAction,
  braintreeErrors,
  BraintreeErrorMessage,
} from './constants';

export const sanitizeData = (
  data: any,
  threeDSecurePayload: BraintreeThreeDSecureVerifyPayload,
) => {
  delete data.cardholderName;
  delete data.number;
  delete data.expirationMonth;
  delete data.expirationYear;
  delete data.cvv;

  delete data.billingFirstName;
  delete data.billingLastName;
  delete data.billingStreetAddress;
  delete data.billingExtendedAddress;
  delete data.billingLocality;
  delete data.billingPostalCode;
  delete data.billingCountryCodeAlpha2;

  data.nonce = threeDSecurePayload.nonce;

  return data;
};

export const getCardTitle = (cardType: BraintreeCardType) => {
  const normalized =
    typeof cardType === 'string'
      ? cardType
          .toLowerCase()
          .replace(' ', '')
          .replace('-', '')
          .replace('_', '')
      : '';
  switch (normalized) {
    case BraintreeCardType.AMERICAN_EXPRESS:
      return 'American Express';
    case BraintreeCardType.DINERS_CLUB:
      return 'Diners Club';
    case BraintreeCardType.DISCOVER:
      return 'Discover';
    case BraintreeCardType.ELO:
      return 'Elo';
    case BraintreeCardType.HIPER:
      return 'Hiper';
    case BraintreeCardType.HIPERCARD:
      return 'Hipercard';
    case BraintreeCardType.JCB:
      return 'JCB';
    case BraintreeCardType.MAESTRO:
      return 'Maestro';
    case BraintreeCardType.MASTERCARD:
      return 'MasterCard';
    case BraintreeCardType.MIR:
      return 'Mir';
    case BraintreeCardType.UNION_PAY:
      return 'Union Pay';
    case BraintreeCardType.VISA:
      return 'Visa';
  }
};

export const getBraintreeError = (
  error: any,
): Omit<BraintreeError, 'error'> => {
  const result = braintreeErrors.find(
    item => item.error === (error.code ? error.code : error.message),
  );
  return result
    ? result
    : {
        message: BraintreeErrorMessage.ERROR,
        action: BraintreeErrorAction.RETRY,
      };
};

export const getBillingAddress = (data: any): BraintreeBillingAddress => {
  const billingAddress: BraintreeBillingAddress = {
    firstName: data.billingFirstName,
    lastName: data.billingLastName,
    streetAddress: data.billingStreetAddress,
    locality: data.billingLocality,
    postalCode: data.billingPostalCode,
    countryCodeAlpha2: data.billingCountryCodeAlpha2,
  };
  !!data.billingExtendedAddress &&
    (billingAddress.extendedAddress = data.billingExtendedAddress);
  return billingAddress;
};

export function mergeDefaultValues<T>(
  defaultValues: any,
): UnpackNestedValue<DeepPartial<T>> {
  return {
    ...defaultValues,

    cardholderName: braintreeDefaultValues.cardholderName,
    number: braintreeDefaultValues.number,
    cvv: braintreeDefaultValues.cvv,
    expirationMonth: braintreeDefaultValues.expirationMonth,
    expirationYear: braintreeDefaultValues.expirationYear,

    billingFirstName: defaultValues.billingFirstName
      ? defaultValues.billingFirstName
      : braintreeDefaultValues.billingFirstName,

    billingLastName: defaultValues.billingLastName
      ? defaultValues.billingLastName
      : braintreeDefaultValues.billingLastName,

    billingStreetAddress: defaultValues.billingStreetAddress
      ? defaultValues.billingStreetAddress
      : braintreeDefaultValues.billingStreetAddress,

    billingExtendedAddress: defaultValues.billingExtendedAddress
      ? defaultValues.billingExtendedAddress
      : braintreeDefaultValues.billingExtendedAddress,

    billingLocality: defaultValues.billingLocality
      ? defaultValues.billingLocality
      : braintreeDefaultValues.billingLocality,

    billingPostalCode: defaultValues.billingPostalCode
      ? defaultValues.billingPostalCode
      : braintreeDefaultValues.billingPostalCode,

    billingCountryCodeAlpha2: defaultValues.billingCountryCodeAlpha2
      ? defaultValues.billingCountryCodeAlpha2
      : braintreeDefaultValues.billingCountryCodeAlpha2,
  };
}
