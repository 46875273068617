import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { JSEncrypt } from 'jsencrypt';
import { useCookies } from 'react-cookie';

import useModalAction from 'modules/modals/hooks/useModalAction';
import { AccountDataContext } from 'modules/context';
import { ModalType } from 'modules/modals/constants';
import { api, API } from 'modules/api';
import logger from 'modules/logger';
import { Model } from '../components/SignIn/model';
import {
  CLIENTID_COOKIE_NAME,
  CLIENT_ID_ITEM_NAME,
  TOKEN_PARAM_NAME,
} from 'modules/utils/constants';
import { logInRedirection, redirectionToHome } from 'modules/utils/utils';
import { useLoginToken } from 'modules/hooks';
import { useHistory } from 'react-router-dom';
import { CookieSetOptions } from 'pages/sign-up/hooks/types';
import { useJSPeoplecertApplication } from 'modules/utils/PeoplecertExternalApplication';
import { useJSUtilities } from 'modules/utils/UtilitiesExternal';

export const useSignIn = () => {
  const application = useJSPeoplecertApplication();
  // const JSUtilities = useJSUtilities();

  const history = useHistory();
  const { isLoggedIn, fetchAccountData } = useLoginToken();

  const { accountDataContext, setAccountDataContext } =
    useContext(AccountDataContext);
  const [isApiSubmitting, setIsApiSubmitting] = useState(false);
  const [isError, setIsError] = useState('');
  const active = useRef(true);
  const defaultValues: Model = useMemo(() => {
    return {
      username: '',
      password: '',
    };
  }, [accountDataContext]);

  const [clientId, setClientId] = useState(
    sessionStorage.getItem(TOKEN_PARAM_NAME),
  );

  const [cookie, setCookie, removeCookie] = useCookies<string>([
    CLIENTID_COOKIE_NAME,
  ]);

  const today = new Date();
  today.setMinutes(today.getMinutes() + 30);
  const cookieOptions: CookieSetOptions = {
    maxAge: 3600,
    expires: today,
    domain: '.axelos.com',
    // path: '/',
    // sameSite: 'strict',
  };

  // HandleSubmit useCallback
  const handleSubmit = useCallback(async (data: Model) => {
    if (!isApiSubmitting) {
      try {
        // setIsApiSubmitting(true);
        setIsError('');
        const cb = new Date().getTime();

        const publicKey =
          'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDa5kl2GxrW8QuZppNt961nYO0WvXhgXaylXw4LXLKxutphlaCuXvGPu/ONhTvPhMPlGp+Q3XAJxCsIT3HQtSw6pM4gd6bxqI5FLMtIqFeX0eWCPM4gPCJJkvAjma5qFD+AU3XO18I/YQCI+V3q7iJtPvYNsx03dNCpesryZDEefQIDAQAB';
        const encryptor = new JSEncrypt();
        encryptor.setPublicKey(publicKey);
        const bodyObj = {
          username: data.username,
          password: data.password,
        };
        const encrypted = encryptor.encrypt(JSON.stringify(bodyObj));

        const result = await api(API.POST_LOGIN_FORM(cb, encrypted)).then(
          res => {
            if (
              res.data?.headers[0]?.value[0] !== '' &&
              res.data?.headers[0]?.value[0] !== undefined &&
              res.data?.headers[0]?.value[0] !== null
            ) {
              setClientId(res.data?.headers[0]?.value[0]);
              sessionStorage.setItem(
                CLIENT_ID_ITEM_NAME,
                res.data?.headers[0]?.value[0],
              );
              setCookie(
                CLIENTID_COOKIE_NAME,
                res.data?.headers[0]?.value[0],
                cookieOptions,
              );
              localStorage.setItem('user_access', res.data?.reasonPhrase);
              // redirectionToHome();
              history.push('/');
            } else {
              setIsError(res.data?.reasonPhrase);
            }
          },
        );

        // logInRedirection();

        // active.current &&
        //   patchAccountData(result.data, setAccountDataContext);
      } catch (error) {
        if (active.current) {
          logger.error('handleSubmit Error', error);
        }
      } finally {
        active.current && setIsApiSubmitting(false);
      }
    }
  }, []);

  // Make API call after getting clientId
  useEffect(() => {
    // Requester API CALL
    async function fetchData(param: any) {
      try {
        const response = await api(API.GET_SET_PC_SSO_COOKIES(param)).then(
          res => {
            if (res === null) {
              sessionStorage.removeItem(TOKEN_PARAM_NAME);
            }
          },
        );
        console.log('requester call > Response', response);
      } catch (e) {
        console.log('requester call > Error', e);
      }
    }

    // SSO API CALL
    async function ssoAPI(id: string | null) {
      try {
        const response = await api(API.GET_SSO(id)).then(res => {
          if (res === null) {
            sessionStorage.removeItem(TOKEN_PARAM_NAME);
          }
        });
        console.log('ssoAPI call > Response', response);
      } catch (e) {
        console.log('ssoAPI call > Error', e);
      }
    }

    if (clientId !== '' && clientId !== undefined && clientId !== null) {
      removeCookie(CLIENTID_COOKIE_NAME);
      setCookie(CLIENTID_COOKIE_NAME, clientId, cookieOptions);
      const requesterBodyObj = {
        clientid: clientId,
        cb: new Date().getTime(),
      };
      fetchData(requesterBodyObj);
      ssoAPI(clientId);
      application.manageDomainCookies('add', [], clientId);
    }
  });

  // Check if cookie already exist, then redirect to home
  useEffect(() => {
    if (isLoggedIn()) {
      fetchAccountData();
    }

    if (clientId !== '' && clientId !== undefined && clientId !== null) {
      setCookie(CLIENTID_COOKIE_NAME, clientId, cookieOptions);
      history.push('/');
    } else if (
      cookie.clientid !== '' &&
      cookie.clientid !== undefined &&
      cookie.clientid !== null
    ) {
      sessionStorage.setItem(CLIENT_ID_ITEM_NAME, cookie.clientid);
      history.push('/');
    }
  });

  return {
    defaultValues,
    handleSubmit,
    isApiSubmitting,
    loading: !accountDataContext,
    error: isError,
  };
};
