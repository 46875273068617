import { useEffect } from 'react';

import { filtersHookType, resourcesType } from 'pages/resource-hub/types';
import { LoginTypeEnum } from 'modules/utils/types';
import { useSelectedFiltersItems } from './useSelectedFiltersItems';

export const useSelectedFilters = (
  filters: filtersHookType,
  loginTypeContext: LoginTypeEnum,
  resources: resourcesType,
  slug: string,
) => {
  const {
    addDeleteBestPractices,
    addDeleteContentTypes,
    addDeleteTopics,
    refreshResults,
    selectItemsFromSlug,
    setBestPracticesSlug,
    setContentTypesSlug,
    setRefreshResults,
    setTopicsSlug,
  } = useSelectedFiltersItems(filters, slug);

  useEffect(() => {
    selectItemsFromSlug(
      resources.topics.data,
      filters.setTopicsSelected,
      setTopicsSlug,
    );

    selectItemsFromSlug(
      resources.bestPractices.data,
      filters.setBestPracticesSelected,
      setBestPracticesSlug,
    );

    selectItemsFromSlug(
      resources.contentTypes.data,
      filters.setContentTypesSelected,
      setContentTypesSlug,
    );

    if (slug !== '') {
      setRefreshResults(true);
    }
  }, [
    selectItemsFromSlug,
    resources.setBestPractices,
    filters.setBestPracticesSelected,
    resources.setContentTypes,
    filters.setContentTypesSelected,
    setRefreshResults,
    resources.setTopics,
    filters.setTopicsSelected,
    loginTypeContext,
    resources.topics.data,
    resources.bestPractices.data,
    resources.contentTypes.data,
    setTopicsSlug,
    setBestPracticesSlug,
    setContentTypesSlug,
    slug,
  ]);

  useEffect(() => {
    if (slug === '') {
      setRefreshResults(true);
      setBestPracticesSlug('');
      setContentTypesSlug('');
      setTopicsSlug('');
    }
  }, [
    slug,
    setRefreshResults,
    setBestPracticesSlug,
    setContentTypesSlug,
    setTopicsSlug,
  ]);

  return {
    addDeleteBestPractices,
    addDeleteContentTypes,
    addDeleteTopics,
    refreshResults,
    setRefreshResults,
  };
};
