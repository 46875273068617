export enum EventsCategories {
  ACCOUNT_MANAGEMENT = 'Account management',
  BADGING = 'Badging',
  CANDIDATE_NUMBERS = 'Candidate numbers',
  CONTENT = 'Content',
  CPD = 'CPD',
  DASHBOARD = 'Dashboard',
  FAQ = 'FAQ',
  HELP = 'Help',
  MATURITY_MODEL = 'Maturity model',
  MIGRATION = 'Migration',
  SEARCH = 'Search',
  SIGN_UP = 'Sign up',
  PAGE_VIEWS = 'Page views',
}

export enum EventsActions {
  ACHIEVE_CERTIFICATION_LINK = 'Achieve Certification link click on dashboard',
  BOOKMARK_CONTENT_ITEMS = 'Bookmark content items',
  CLICK_AGILE_PROJECT_EXPERT = 'Click Agile Project Expert claim badge CTA',
  CLICK_CTA_ON_BADGING_PAGE = 'Click CTA on badging page',
  CLICK_CTA_ON_DASHBOARD = 'Click CTA on dashboard',
  CLICK_CTA_ON_RHS_OF_BADGING_PAGE = 'Click CTA on RHS of badging page',
  CLICK_ITIL_CDS = 'Click ITIL CDS claim badge CTA',
  CLICK_ITIL_DITS = 'Click ITIL DITS claim badge CTA',
  CLICK_ITIL_DPI = 'Click ITIL DPI claim badge CTA',
  CLICK_ITIL_DSV = 'Click ITIL DSV claim badge CTA',
  CLICK_ITIL_F = 'Click ITIL F claim badge CTA',
  CLICK_ITIL_HVIT = 'Click ITIL HVIT claim badge CTA',
  CLICK_ITIL_SIDIT = 'Click ITIL SIDIT claim badge CTA',
  CLICK_ITIL_AMCS = 'Click ITIL AMCS claim badge CTA',
  CLICK_ITIL_MP = 'Click ITIL MP claim badge CTA',
  CLICK_ITIL_SL = 'Click ITIL SL claim badge CTA',
  CLICK_M_O_R = 'Click M_o_R  claim badge CTA',
  CLICK_MOP_F = 'Click MoP F claim badge CTA',
  CLICK_MOP_P = 'Click MoP P claim badge CTA',
  CLICK_MSP_F = 'Click MSP F claim badge CTA',
  CLICK_MSP_P = 'Click MSP P claim badge CTA',
  CLICK_P30_F = 'Click P30 F claim badge CTA',
  CLICK_P30_P = 'Click P30 P claim badge CTA',
  CLICK_PAY_NOW_ANNUAL = 'Click Pay now (annual from marketing page)',
  CLICK_PAY_NOW_MONTHLY = 'Click Pay now (monthly from marketing page)',
  CLICK_PAY_NOW_PEOPLECERT = 'Click Pay now button (PeopleCert flow)',
  CLICK_PRINCE2_AGILE_F = 'Click PRINCE2 Agile F claim badge CTA',
  CLICK_PRINCE2_AGILE_P = 'Click PRINCE2 Agile P claim badge CTA',
  CLICK_PRINCE2_F = 'Click PRINCE2 F claim badge CTA',
  CLICK_PRINCE2_P = 'Click PRINCE2 P claim badge CTA',
  CLICK_PROGRAMME_LEADER = 'Click Programme Leader claim badge CTA',
  CLICK_PROJECT_EXPERT = 'Click Project Expert claim badge CTA',
  CLICK_READ_NOW = 'Click Read now on related content',
  CLICK_REGISTER_BUTTON = 'Click Register button on login page',
  CLICK_SEND_BUTTON = 'Click Send button on contact form',
  CLICK_TAKE_ME_TO_MYAXELOS = 'Click Take me to MyAxelos button',
  CLICK_UPDATE_CARD_DETAILS_BUTTON = 'Click update card details button',
  CLICK_VISIT_MM_ITIL = 'Click CTA to visit MM tool (ITIL)',
  CLICK_VISIT_MM_P3M3 = 'Click CTA to visit MM tool (P3M3)',
  DOWNLOAD_FILES_INDIVIDUALS = 'Download files in content pages (Individuals)',
  DOWNLOAD_FILES_CONSULTANTS = 'Download files in content pages (Consultants)',
  EXPAND_QUESTION = 'Expand question',
  LIKE_CONTENT_ITEMS = 'Like content items',
  SCROLL_DOWN_40 = 'Scroll down 40% of the page',
  SCROLL_DOWN_60 = 'Scroll down 60% of the page',
  SCROLL_DOWN_80 = 'Scroll down 80% of the page',
  SEARCH_BUTTON_CLICKED = 'Search button clicked',
  SIGN_UP = 'Sign up',
  SUBMIT_BUTTON_ON_CPD_FORM = 'Submit button on CPD form',
  USER_UPGRADES = 'User upgrades from monthly to annual',
  VOUCHER_CODE_ADDED = 'Voucher code added successfully',
  CLICK_SIGNUP_REMINDER = 'Click Signup Reminder',
  HELP_PAGE_VISITS_INDIVIDUALS = 'Number of Help page visits (Individuals)',
  HELP_PAGE_VISITS_CONSULTANTS = 'Number of Help page visits (Consultants)',
  RESOURCE_HUB_VISITS_INDIVIDUALS = 'Number of Resource Hub visits (Individuals)',
  RESOURCE_HUB_VISITS_CONSULTANTS = 'Number of Resource Hub visits (Consultants)',
  CURATED_CONTENT_PAGE_INDIVIDUALS = 'Number of curated content page views (Individuals)',
  CURATED_CONTENT_PAGE_CONSULTANTS = 'Number of curated content page views (Consultants)',
  LOGINS_INDIVIDUALS = 'Number of Logins (Individuals)',
  LOGINS_CONSULTANTS = 'Number of Logins (Consultants)',
  CONTENT_TYPES_VISITED_INDIVIDUALS = 'content types visited (Individuals)',
  CONTENT_TYPES_VISITED_CONSULTANTS = 'content types visited (Consultants)',
  CONTENT_ITEM = 'Content item',
}
