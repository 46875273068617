import yup, { ValdationMessages } from 'modules/validation';

const validateTelephone = (value: string): boolean => {
  return value.length >= 10 &&
    (/^01/.test(value) ||
      /^02/.test(value) ||
      /^03/.test(value) ||
      /^05/.test(value) ||
      /^07/.test(value) ||
      /^08/.test(value) ||
      /^09/.test(value))
    ? true
    : false;
};

function validator(this: yup.StringSchema) {
  return this.test('telephone', ValdationMessages.TELEPHONE, function (value) {
    return value ? validateTelephone(value) : true;
  });
}

export default validator;
