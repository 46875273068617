import { useContext, useEffect, useState } from 'react';

import { API, api } from 'modules/api';
import { DashboardLoadedType, DashboardType } from '../types';
import { KeyTemplatesGetResponse } from 'modules/api/endpoints/key-templates';
import { LoginTypeContext } from 'modules/context';
import { ResourceHubItemType } from 'components/FeaturedResources/types';
import logger from 'modules/logger';

export const useKeyTemplates = (
  setLoaded: React.Dispatch<React.SetStateAction<DashboardLoadedType>>,
  dashboard?: DashboardType,
) => {
  const { loginTypeContext } = useContext(LoginTypeContext);
  const [keyTemplates, setKeyTemplates] = useState<ResourceHubItemType[]>();

  useEffect(() => {
    if (dashboard && loginTypeContext) {
      //TODO done useEffect
      let active = true;
      (async () => {
        try {
          const response: KeyTemplatesGetResponse = await api(
            API.GET_DASHBOARD_CONTENT_TYPES({
              contentTypeUid: dashboard?.curate_key_templates.type[0]?.uid,
              keyTemplates: true,
              loginTypeContext,
              numberOfItems: 10,
            }),
          );
          if (active && dashboard.curate_key_templates.show_key_templates) {
            setKeyTemplates(response.data.entries);
          }
        } catch (error) {
          active && logger.error('Get Key Templates Error', error);
        } finally {
          active &&
            setLoaded(prevState => ({
              ...prevState,
              keyTemplates: true,
            }));
        }
      })();
      return () => {
        active = false;
      };
    }
  }, [dashboard, loginTypeContext, setLoaded]);

  return { keyTemplates };
};
