import { useContext, useEffect, useState } from 'react';

import { API, api } from 'modules/api';
import { AchievementsGetResponse } from 'modules/api/endpoints/achievements';
import {
  AchievementsTypes,
  CandidateIdTypes,
  DashboardLoadedType,
  DashboardType,
} from '../types';
import { LoginTypeContext } from 'modules/context';
import { LoginTypeEnum } from 'modules/utils/types';
import logger from 'modules/logger';

export const useAchievements = (
  setLoaded: React.Dispatch<React.SetStateAction<DashboardLoadedType>>,
  candidateIds?: CandidateIdTypes[],
  dashboard?: DashboardType,
) => {
  const { loginTypeContext } = useContext(LoginTypeContext);
  const [achievements, setAchievements] = useState<
    AchievementsTypes[] | undefined
  >();

  useEffect(() => {
    if (
      dashboard &&
      loginTypeContext &&
      loginTypeContext !== LoginTypeEnum.CONSULTANT
    ) {
      let active = true;
      (async () => {
        try {
          const response: AchievementsGetResponse = await api(
            API.GET_BADGES_CERTIFICATE_PATHS(),
          );
          active &&
            candidateIds &&
            candidateIds.length > 0 &&
            setAchievements(response.data);
        } catch (error) {
          active &&
            candidateIds &&
            candidateIds.length > 0 &&
            logger.error('Get Achievements Error', error);
        } finally {
          active &&
            setLoaded(prevState => ({
              ...prevState,
              achievements: true,
            }));
        }
      })();
      return () => {
        active = false;
      };
    }
  }, [candidateIds, dashboard, loginTypeContext, setLoaded]);

  return { achievements, setAchievements };
};
