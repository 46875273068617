import { useContext, useEffect, useState } from 'react';

import { API, api } from 'modules/api';
import { BadgesGetResponse } from 'modules/api/endpoints/badges';
import { BadgesTypes } from 'pages/badges/types';
import { BadgeStatus } from 'pages/badges/components/Badges/constants';
import { CandidateIdTypes, DashboardLoadedType, DashboardType } from '../types';
import { LoginTypeContext } from 'modules/context';
import { LoginTypeEnum } from 'modules/utils/types';
import logger from 'modules/logger';

export const useBadges = (
  runPolling: (
    badges: BadgesTypes[],
    setBadges: React.Dispatch<React.SetStateAction<BadgesTypes[] | undefined>>,
  ) => void,
  setLoaded: React.Dispatch<React.SetStateAction<DashboardLoadedType>>,
  candidateIds?: CandidateIdTypes[],
  dashboard?: DashboardType,
) => {
  const { loginTypeContext } = useContext(LoginTypeContext);
  const [badges, setBadges] = useState<BadgesTypes[] | undefined>();

  useEffect(() => {
    if (
      dashboard &&
      loginTypeContext &&
      loginTypeContext !== LoginTypeEnum.CONSULTANT
    ) {
      //TODO done useEffect
      let active = true;
      (async () => {
        try {
          const response: BadgesGetResponse = await api(
            API.GET_BADGES_STATUS(),
          );
          if (active) {
            candidateIds && candidateIds.length > 0 && setBadges(response.data);
            const badgesStatusIndex = response.data.findIndex(
              (el: BadgesTypes) =>
                el.status === BadgeStatus.BADGE_PENDING ||
                el.status === BadgeStatus.BADGE_CLAIMABLE,
            );
            if (badgesStatusIndex > -1) {
              runPolling(response.data, setBadges);
            }
          }
        } catch (error) {
          active &&
            candidateIds &&
            candidateIds.length > 0 &&
            logger.error('Get Badges Error', error);
        } finally {
          active &&
            setLoaded(prevState => ({
              ...prevState,
              badges: true,
            }));
        }
      })();
      return () => {
        active = false;
      };
    }
  }, [candidateIds, dashboard, loginTypeContext, runPolling, setLoaded]);

  return { badges, setBadges };
};
