import React from 'react';
import clsx from 'clsx';
import { useId } from 'react-id-generator';

import { ReactComponent as DownloadSVG } from './download.svg';
import { ReactComponent as ArrowSVG } from '../arrow.svg';
import { useAccordion } from 'modules/hooks';
import * as Button from 'components/Button';

import styles from './styles.module.scss';
import { getDateWithOrdinal } from 'modules/utils/utils';

export type PaymentHistoryYearItemProps = {
  paymentDate: string;
  paymentDescription: string;
  paymentAmountNet: string;
  paymentAmount: string;
  salesTaxPerc: string;
  invoiceLink: string;
  salesTaxAmount: string;
};

const PaymentHistoryYearItem: React.FunctionComponent<PaymentHistoryYearItemProps> =
  ({
    paymentDate,
    paymentDescription,
    paymentAmountNet,
    paymentAmount,
    salesTaxPerc,
    invoiceLink,
    salesTaxAmount,
  }) => {
    const { animating, handleClick, open, ref } = useAccordion(false);
    const [id] = useId(1, 'payment-history-year-item');
    const className = clsx(
      styles.paymentHistoryYearItem,
      animating && styles.animating,
      !open && styles.closed,
    );

    return (
      <div className={className}>
        <div>
          <h4>{getDateWithOrdinal(paymentDate.split('T')[0])}</h4>
          <Button.Generic
            ariaControls={id}
            ariaExpanded={open}
            ariaLabel={`${
              open ? 'Open' : 'Closed'
            } ${paymentDate} Payment History Item`}
            onClick={handleClick}
          >
            <ArrowSVG />
          </Button.Generic>
        </div>
        <div aria-hidden={!open} id={id} ref={ref}>
          <div>
            <table>
              <tbody>
                <tr>
                  <td>{paymentDescription}</td>
                  <td>{`£${paymentAmountNet}`}</td>
                </tr>
                <tr>
                  <td>{`VAT (${salesTaxPerc}%)`}</td>
                  <td>{`£${salesTaxAmount}`}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th>Total</th>
                  <th>{`£${paymentAmount}`}</th>
                </tr>
              </tfoot>
            </table>
            <Button.CustomLink
              ariaLabel="Download as PDF"
              href={`${process.env.REACT_APP_BACKEND_URL}${invoiceLink}`}
            >
              <DownloadSVG />
              <span>Download as PDF</span>
            </Button.CustomLink>
          </div>
        </div>
      </div>
    );
  };

export default React.memo(PaymentHistoryYearItem);
