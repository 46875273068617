import React, { useCallback, useContext } from 'react';
import { TransitionProps } from '@material-ui/core/transitions';
import { RemoveScroll } from 'react-remove-scroll';
import clsx from 'clsx';
import Zoom from '@material-ui/core/Zoom';

import { ModalSize } from 'modules/modals/constants';
import { ReactComponent as CrossSVG } from 'modules/theme/icons/general/cross.svg';
import * as Button from 'components/Button';
import ModalOverlay from '../Overlay';
import PlatformContext from 'modules/platform/context';

import styles from './styles.module.scss';
import modalStyles from 'modules/modals/styles.module.scss';
import Certificate from './components/Certificate';
import ClaimInfo from './components/ClaimInfo';
import { cpdSubmissionResponse } from 'pages/badges/components/Badges/BadgesItem/CpdForm/types';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Zoom ref={ref} {...props} />;
});

export type ModalCpdClaimTemplateProps = {
  onClose: () => void;
  size?: ModalSize;
  res: cpdSubmissionResponse;
};

export const ModalCpdClaimTemplate = React.memo<ModalCpdClaimTemplateProps>(
  ({ onClose, size = ModalSize.NARROW, res }) => {
    const classNames = clsx(modalStyles[`${size}`], styles.modalInfo);
    const title =
      res && !res.cpdCompleteThisYear
        ? `You have logged ${res.cpdPointsThisYear} CPD points this year`
        : 'Congratulations! You have logged all CPD points for this year!';
    return (
      <section className={classNames} role="dialog">
        <header>
          <h2>{title}</h2>
          <Button.Generic
            onClick={onClose}
            ariaLabel="Close info popup"
            title="Close info popup"
          >
            <CrossSVG />
          </Button.Generic>
        </header>
        <div>
          <div className={styles.modalContent}>
            <Certificate
              level={res.qualificationLevel}
              category={res.qualificationCategory}
            />
            <ClaimInfo
              candidateNumber={res.candidateId}
              cpdCompleteThisYear={res.cpdPointsThisYear}
              cpdClaimWindowEnd={res.cpdClaimWindowEnd}
              cpdPointsRemainingThisYear={res.cpdPointsRemainingThisYear}
              firstName={res.firstName}
              lastName={res.lastName}
              pointsRequiredRemaining={res.cpdPointsRequiredRemainingThisYear}
            />
          </div>
        </div>
      </section>
    );
  },
);

export type ModalCpdClaimProps = {
  destroyModal: () => void;
  disableRestoreFocus?: boolean;
  hideModal: () => void;
  id: string;
  onClose?: () => void;
  open: boolean;
  size?: ModalSize;
  res: cpdSubmissionResponse;
};

const CpdClaim: React.FunctionComponent<ModalCpdClaimProps> = ({
  destroyModal,
  disableRestoreFocus,
  hideModal,
  onClose,
  open,
  res,
}) => {
  const { isMobile } = useContext(PlatformContext);

  const handleClose = useCallback(() => {
    onClose ? open && onClose() : open && hideModal();
  }, [hideModal, onClose, open]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === 'Escape' || event.key === 'Enter') {
        handleClose();
        event.preventDefault();
        event.stopPropagation();
      }
    },
    [handleClose],
  );
  return (
    <RemoveScroll enabled={isMobile}>
      <ModalOverlay
        disableRestoreFocus={disableRestoreFocus}
        onExited={destroyModal}
        onKeyDown={handleKeyDown}
        open={open}
        scroll="paper"
        TransitionComponent={Transition}
      >
        <ModalCpdClaimTemplate onClose={handleClose} res={res} />
      </ModalOverlay>
    </RemoveScroll>
  );
};

export default CpdClaim;
