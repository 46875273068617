import { useCallback, useEffect, useRef } from 'react';

import { api, API } from 'modules/api';
import { AchievementsGetResponse } from 'modules/api/endpoints/achievements';
import { AchievementsTypes } from '../types';
import { BadgesGetResponse } from 'modules/api/endpoints/badges';
import { BadgesTypes } from 'pages/badges/types';
import logger from 'modules/logger';

export const useUpdateBadges = (
  setAchievements: React.Dispatch<
    React.SetStateAction<AchievementsTypes[] | undefined>
  >,
  setBadges: React.Dispatch<React.SetStateAction<BadgesTypes[] | undefined>>,
  cancelPolling: () => void,
  runPolling: (
    badges: BadgesTypes[],
    setBadges: React.Dispatch<React.SetStateAction<BadgesTypes[] | undefined>>,
  ) => void,
) => {
  const active = useRef(true);
  //TODO done useCallback
  const updateBadges = useCallback(async () => {
    try {
      const achievementsResponse: AchievementsGetResponse = await api(
        API.GET_BADGES_CERTIFICATE_PATHS(),
      );
      const badgesResponse: BadgesGetResponse = await api(
        API.GET_BADGES_STATUS(),
      );
      if (active.current) {
        setAchievements(achievementsResponse.data);
        setBadges(badgesResponse.data);
        cancelPolling();
        runPolling(badgesResponse.data, setBadges);
      }
    } catch (error) {
      active.current && logger.debug('Badges Response Error: ', error);
    }
  }, [cancelPolling, runPolling, setAchievements, setBadges]);

  useEffect(() => {
    return () => {
      active.current = false;
    };
  }, []);

  return { updateBadges };
};
