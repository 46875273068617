import { useContext, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import sleep from 'sleep-promise';

import { API, api } from 'modules/api';
import { ContentTypePageResponse } from 'modules/api/endpoints/content-type-page';
import { AccountDataContext, LoginTypeContext } from 'modules/context';
import { RESOURCE_HUB_URL } from 'modules/utils/constants';
import { useErrorModal } from 'modules/modals/hooks/useErrorModal';
import logger from 'modules/logger';
import { EventsActions, EventsCategories } from 'modules/gtm/constants';
import { TriggerGTMEvent } from 'modules/gtm';
import { ContentTypePageType, ResourceHubLoadedType } from '../types';
import { EngagementSummaryPageType } from 'modules/engagementSummary';

export const useContentTypePage = (
  setLoaded: React.Dispatch<React.SetStateAction<ResourceHubLoadedType>>,
) => {
  const [page, setPage] = useState<ContentTypePageType>();

  const [userEmail, setUserEmail] = useState('');
  const [pageURL, setPageURL] = useState('');
  const [contentType, setContentType] = useState('');

  const url = useRouteMatch<any>(`${RESOURCE_HUB_URL}*`);
  const [contentTypeSlug, pageSlug] = url?.params[0].split('/');
  const [displayModal] = useErrorModal();
  const { loginTypeContext } = useContext(LoginTypeContext);
  const { accountDataContext } = useContext(AccountDataContext);
  const userEmailId = accountDataContext?.email ? accountDataContext.email : '';

  useEffect(() => {
    //TODO done useEffect
    if (loginTypeContext && pageSlug) {
      let active = true;
      (async () => {
        try {
          const startTime = performance.now();
          const response: ContentTypePageResponse = await api(
            API.GET_CONTENT_TYPE_PAGE({
              loginTypeContext,
              contentTypeSlug,
              pageSlug,
            }),
          );

          if (active) {
            const endTime = performance.now();
            const formattedPageSlug = `${pageSlug.replace(/-/g, ' ')}`;
            const getContentTypes =
              (response?.data.entries[0]?.categorisation?.content_type).map(
                type => {
                  return type.title;
                },
              );

            setUserEmail(userEmailId);
            setPageURL(window.location.href);
            setContentType(getContentTypes.join(', '));

            endTime - startTime < 400 && (await sleep(250));
            response?.data.entries[0]?.uid
              ? setPage(response?.data.entries[0])
              : displayModal('You do not have permission to view this page');

            TriggerGTMEvent({
              action: `${EventsActions.CONTENT_ITEM} - ${formattedPageSlug}`,
              category: EventsCategories.CONTENT,
              label: pageSlug,
            });

            // Engagement Summary Dashboard
            EngagementSummaryPageType({
              id: 0,
              pageURL: window.location.href,
              contentTitle: response?.data.entries[0]?.title,
              contentType: getContentTypes.join(', '),
              pageviews: 1,
              downloads: 0,
              indivisualUser: loginTypeContext === 1 ? 1 : 0,
              consultantUser: loginTypeContext === 2 ? 1 : 0,
            });
          }
        } catch (error) {
          active && logger.error('Get Content Type Error', error);
        } finally {
          active &&
            setLoaded(prevState => ({
              ...prevState,
              contentTypePage: true,
            }));
        }
      })();
      return () => {
        active = false;
        setLoaded(prevState => ({
          ...prevState,
          contentTypePage: false,
          resourceHub: false,
        }));
      };
    }
  }, [
    contentTypeSlug,
    displayModal,
    loginTypeContext,
    pageSlug,
    setLoaded,
    setPage,
    userEmailId,
  ]);

  return {
    page,
    userEmail,
    pageURL,
    contentType,
  };
};
