import React, { MutableRefObject, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import dayjs from 'dayjs';

import { ButtonColour, ButtonStyle } from 'components/Button/constants';
import { model, schema } from './model';
import { useCpdTypes } from './hooks/useCpdTypes';
import { useSubmitCpd } from './hooks/useSubmitCpd';
import * as Button from 'components/Button';
import * as Question from 'components/Question';
import Fieldset from 'components/Fieldset';
import QuestionDate from 'components/QuestionDate';
import Questions from 'components/Questions';

import formModalStyles from 'components/Modal/Form/styles.module.scss';
import modalStyles from 'modules/modals/styles.module.scss';
import styles from './styles.module.scss';

type CpdFormProps = {
  examDateTaken: string;
  certificateId: string;
  modalRef: MutableRefObject<string | null>;
  onCancel: () => void;
  pointsOptions: {
    label: string;
    value: string;
  }[];
  title: string;
};

const CpdForm: React.FunctionComponent<CpdFormProps> = ({
  certificateId,
  examDateTaken,
  modalRef,
  onCancel,
  pointsOptions,
  title,
}) => {
  const [fetchCpdTypes, isLoading, cpdTypesOptions] = useCpdTypes();
  const [fetchingData, setfetchingData] = useState(true);
  const { handleSubmit, isApiSubmitting } = useSubmitCpd(modalRef);

  const calcMinDate = useMemo(() => {
    const dateTaken = dayjs(examDateTaken);
    const today = dayjs();
    let cpdPointsYearStart = dateTaken;
    while (cpdPointsYearStart.add(1, 'year') < today) {
      cpdPointsYearStart = cpdPointsYearStart.add(1, 'year');
    }
    return cpdPointsYearStart.toISOString();
  }, [examDateTaken]);

  const defaultValue = {
    cpdType: '',
    description: '',
    claimedPoints: '',
    completionDate: '',
    whatDidYouLearn: '',
    certificateId: certificateId || '',
    minDate: calcMinDate || '',
  };

  useEffect(() => {
    if (fetchingData) {
      setfetchingData(false);
      fetchCpdTypes();
    }
  }, [fetchCpdTypes, cpdTypesOptions, isLoading, fetchingData]);

  const buttonClassNames = clsx(
    styles.buttons,
    modalStyles.buttons,
    formModalStyles.buttons,
  );

  return (
    <div className={styles.cpdForm}>
      <Questions
        enableReinitialize={true}
        defaultValues={defaultValue}
        onSubmit={handleSubmit}
        schema={schema}
      >
        {({ control, isFormSubmitting }) => (
          <Fieldset title={title} cpdForm>
            <Question.Select
              control={control}
              isSubmitting={isApiSubmitting || isFormSubmitting}
              label="Type of CPD"
              options={cpdTypesOptions}
              question={model.cpdType}
            />
            <div className={styles.cpdTextarea}>
              <Question.Textarea
                control={control}
                isSubmitting={isApiSubmitting || isFormSubmitting}
                label="Description"
                question={model.description}
              />
            </div>
            <Question.Select
              control={control}
              isSubmitting={isApiSubmitting || isFormSubmitting}
              label="Points I'm claiming"
              options={pointsOptions}
              question={model.claimedPoints}
            />
            <QuestionDate
              control={control}
              isSubmitting={isApiSubmitting || isFormSubmitting}
              label="Completion date"
              min={calcMinDate}
              question={model.completionDate}
            />
            <Question.Textarea
              control={control}
              isSubmitting={isApiSubmitting || isFormSubmitting}
              label="What did you learn?"
              question={model.whatDidYouLearn}
            />
            <div className={buttonClassNames}>
              {(isApiSubmitting || isFormSubmitting) && (
                <span className={styles.spinner}></span>
              )}
              <Button.Generic
                colour={ButtonColour.BRAND_MONO_ONE}
                disabled={isApiSubmitting || isFormSubmitting}
                label="Cancel"
                onClick={onCancel}
                readOnly={isApiSubmitting || isFormSubmitting}
                style={ButtonStyle.SECONDARY}
              />
              <Button.Submit
                colour={ButtonColour.BRAND_BLACK_GRAPE}
                disabled={isApiSubmitting || isFormSubmitting}
                label="Submit"
                isSubmitting={isApiSubmitting || isFormSubmitting}
                showSpinner={false}
                style={ButtonStyle.SECONDARY}
              />
            </div>
          </Fieldset>
        )}
      </Questions>
    </div>
  );
};

export default CpdForm;
