import { useCallback, useEffect, useRef } from 'react';

import { API, api } from 'modules/api';
import { APIExceptions } from 'modules/api/exceptions';
import { IsCandidateNumberClaimableGetResponse } from 'modules/api/endpoints/is-candidate-number-claimable';
import { Model } from '../../QuestionSet/model';
import { ModalType } from 'modules/modals/constants';
import useModalAction from 'modules/modals/hooks/useModalAction';

export const useStepOneSubmit = (
  setFormData: React.Dispatch<React.SetStateAction<Model>>,
  setActiveStep: React.Dispatch<React.SetStateAction<number>>,
) => {
  const { modalShow, modalHide } = useModalAction();
  const modal = useRef<string | null>(null);
  const active = useRef(true);

  const showModal = useCallback(
    (title: string, message: string) => {
      if (!modal.current) {
        modal.current = modalShow({
          onClose: () => {
            !!modal.current && modalHide(modal.current);
            modal.current = null;
          },
          title: title,
          text: message,
          type: ModalType.INFO,
        });
      }
    },
    [modalHide, modalShow],
  );

  const handleStepOneSubmit = useCallback(
    async (data: Model & { nonce: string }) => {
      if (data.candidateNumber) {
        try {
          const res: IsCandidateNumberClaimableGetResponse = await api(
            API.GET_IS_CANDIDATE_ID_CLAIMABLE(
              data.candidateNumber,
              data.givenName,
              data.surname,
            ),
          );
          if (active.current) {
            if (res.data) {
              setFormData(data);
              window.scrollTo(0, 0);
              setActiveStep(2);
            } else {
              showModal(
                "We're having trouble processing your information",
                'The Candidate Number provided is unrecognised. Please check you have entered it correctly and try again or visit our <a href="/help">Help page</a>',
              );
            }
          }
        } catch (error: any) {
          if (active.current) {
            switch (error.response.data.type) {
              case APIExceptions.CandidateIdClaimCheckValidationException:
                showModal(
                  "We're having trouble processing your information",
                  "The name you have entered doesn't match the name associated with your Candidate Number. This may be because you have a double-barrelled name or you have recently married and changed your name. Please enter your name as it appears on your exam certificate and try again.",
                );
                break;
              case APIExceptions.CandidateIdAlreadyExistsException:
                showModal(
                  "We're having trouble processing your information",
                  "The name you have entered doesn't match the name associated with your Candidate Number. This may be because you have a double-barrelled name or you have recently married and changed your name. Please enter your name as it appears on your exam certificate and try again.",
                );
                break;
              case APIExceptions.CandidateIdClaimCheckNoExamException:
                showModal(
                  "We're having trouble processing your information",
                  `Oops, it looks like there aren't any valid exams associated with this candidate number. Please try again or visit our <a href="/help">Help page</a>`,
                );
                break;
              default:
                showModal(
                  "We're having trouble processing your information",
                  "We are sorry but we couldn't add that candidate number to your account. Please check that the name on your account matches the name on your exam or contact Axelos for help",
                );
            }
          }
        }
      } else {
        setFormData(data);
        window.scrollTo(0, 0);
        setActiveStep(2);
      }
    },
    [setActiveStep, setFormData, showModal],
  );

  useEffect(() => {
    return () => {
      !!modal.current && modalHide(modal.current);
      modal.current = null;
      active.current = false;
    };
  }, [modalHide]);

  return {
    handleStepOneSubmit,
  };
};
