import React, { useContext, useState } from 'react';
import clsx from 'clsx';
import { useController } from 'react-hook-form';

import { QuestionProps } from './types';
import { ReactComponent as HelpSVG } from 'modules/theme/icons/form/help.svg';
import PlatformContext from 'modules/platform/context';

import styles from './styles.module.scss';

function Component<T>({
  className,
  children,
  control,
  help,
  question,
}: QuestionProps<T>) {
  const {
    field: { onChange, value, onBlur, name, ref },
    fieldState: { error, invalid },
  } = useController({
    control,
    name: question.name,
  });
  const { isMobile } = useContext(PlatformContext);
  const [showHelp, setShowHelp] = useState(false);
  const errorMessage = (error as any)?.message;
  const classNames = clsx(
    styles.question,
    isMobile && showHelp && styles.showHelp,
    className,
  );
  return (
    <div className={classNames}>
      {help && (
        <>
          <HelpSVG onClick={() => setShowHelp(!showHelp)} />
          <div role="tooltip" aria-hidden="true">
            {help}
          </div>
        </>
      )}
      {children &&
        children({
          errorMessage,
          invalid,
          name,
          onBlur,
          onChange,
          ref,
          value,
        })}
      {invalid && <p aria-live="polite">{errorMessage}</p>}
    </div>
  );
}

export default React.memo(Component) as typeof Component;
