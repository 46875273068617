import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useCookies } from 'react-cookie';
import { useHistory, useLocation } from 'react-router-dom';

import { AccountDataContext, LoginTypeContext } from 'modules/context';
import { API, api } from 'modules/api';
import {
  getAccountData,
  getRefreshToken,
  getTokenExpDate,
  logInRedirection,
  logOutRedirection,
  protectedURL,
  redirectionToHome,
} from 'modules/utils/utils';
import { LoginTypeEnum } from 'modules/utils/types';
import {
  CLIENTID_COOKIE_NAME,
  CLIENT_ID_ITEM_NAME,
  mainUserRoles,
  REDIRECT_URL_COOKIE,
  ROLE_SELECT_SCREEN_CONTENT_ID,
  VERIFY_PAGE_URL,
} from 'modules/utils/constants';
import { ModalType } from 'modules/modals/constants';
import { useLapsedUserModal } from 'modules/modals/hooks/useLapsedUserModal';
import { useLoginToken } from 'modules/hooks';
import { userRoleDescription } from './types';
import * as Loading from 'components/Loading';
import AuthLanding from 'components/AuthLanding';
import logger from 'modules/logger';
import useModalAction from 'modules/modals/hooks/useModalAction';
import { CookieSetOptions } from 'pages/sign-up/hooks/types';

export const Auth: React.FunctionComponent = () => {
  const { search: params, pathname } = useLocation();
  const code = new URLSearchParams(params).get('code');
  const { modalShow, modalHide } = useModalAction();
  const modal = useRef<string | null>(null);
  const { accountDataContext } = useContext(AccountDataContext);
  const { setLoginTypeContext } = useContext(LoginTypeContext);
  const [, setCookieRedirectURL] = useCookies<string>([REDIRECT_URL_COOKIE]);
  const [roleSelectScreenContent, setRoleSelectScreenContent] =
    useState<userRoleDescription[]>();
  const [cookie, setCookie, removeCookie] = useCookies<string>([
    CLIENTID_COOKIE_NAME,
  ]);

  const {
    displayLoadingModal,
    isLoggedIn,
    fetchAccountDataAndStoreToken,
    fetchAccountData,
    onlyOneRoleAvailable,
    saveOnlyAvailableUserRole,
    userRoleCookie,
    getUserRolesDisplayed,
    saveUserRoleCookie,
    token,
    setNewtoken,
    setDisplayLoadingModal,
  } = useLoginToken();

  const { displayLapsedUserModal } = useLapsedUserModal();
  const history = useHistory();
  useEffect(() => {
    // if (code && !token) {
    //   console.log('Auth > index > useEffect > 1');
    //   fetchAccountDataAndStoreToken(code);
    // } else if (isLoggedIn()) {
    //   console.log('Auth > index > useEffect > 2');
    //   fetchAccountData();
    // } else if (!isLoggedIn() && protectedURL(pathname)) {
    //   console.log('Auth > index > useEffect > 3');
    //   setCookieRedirectURL(REDIRECT_URL_COOKIE, `${pathname}${params}`, {
    //     path: '/',
    //   });
    //   // logInRedirection();
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps

    if (isLoggedIn()) {
      // const ci = sessionStorage.getItem(CLIENT_ID_ITEM_NAME);
      // const today = new Date();
      // today.setMinutes(today.getMinutes() + 30);
      // const cookieOptions: CookieSetOptions = {
      //   path: '/',
      //   maxAge: 3600,
      //   expires: today,
      //   domain: '.axelos.com',
      //   // sameSite: 'strict',
      // };
      // setCookie(CLIENTID_COOKIE_NAME, ci, cookieOptions);

      fetchAccountData();
      // history.push('/'); //Always redirecting to home
      setDisplayLoadingModal(true);
    } else {
      history.push('/sign-in');
      setDisplayLoadingModal(true);
    }
  }, [pathname]);

  // useEffect(() => {
  //   //TODO done useEffect
  //   let active = true;
  //   const fetchRefreshToken = async () => {
  //     try {
  //       const newToken = await getRefreshToken();
  //       if (active) {
  //         setNewtoken(newToken);
  //         const accountData = await getAccountData(newToken);

  //         if (accountData) {
  //           const rolesAvailable = accountData?.userRoles.filter(
  //             (userRole: string) => mainUserRoles.includes(userRole),
  //           );
  //           if (rolesAvailable.length === 0) {
  //             displayLapsedUserModal();
  //           }
  //         } else {
  //           logOutRedirection();
  //         }
  //       }
  //     } catch {
  //       active && logOutRedirection();
  //     }
  //   };

  //   if (token) {
  //     const tokenExpireDate = getTokenExpDate(token);
  //     const currentDate = Date.now();
  //     const offsetTimeout = 1000 * 60 * 5;

  //     const intervalTime = tokenExpireDate
  //       ? tokenExpireDate * 1000 - currentDate
  //       : 1000 * 60 * 25;

  //     if (intervalTime <= offsetTimeout) {
  //       fetchRefreshToken();
  //       return;
  //     }

  //     const timer = setTimeout(
  //       () => {
  //         fetchRefreshToken();
  //       },
  //       tokenExpireDate ? intervalTime - offsetTimeout : intervalTime,
  //     );

  //     return () => {
  //       active = false;
  //       clearTimeout(timer);
  //     };
  //   }
  // }, [displayLapsedUserModal, setNewtoken, token]);

  const handleClickUserRole = useCallback(
    (value: LoginTypeEnum) => {
      setLoginTypeContext(value);
      saveUserRoleCookie(value);
      !!modal.current && modalHide(modal.current);
      modal.current = null;
    },
    [modalHide, saveUserRoleCookie, setLoginTypeContext],
  );

  useEffect(() => {
    if (
      token &&
      !roleSelectScreenContent &&
      protectedURL(pathname) &&
      pathname !== '/auth' &&
      getUserRolesDisplayed().length > 1
    ) {
      let active = true;
      (async () => {
        try {
          const res = await api(
            API.GET_CONTENT_BY_ID(
              `${ROLE_SELECT_SCREEN_CONTENT_ID}/user_roles`,
            ),
          );
          active && setRoleSelectScreenContent(res?.data.entries);
        } catch (error) {
          active && logger.debug('Get Content By ID Error: ', error);
        }
      })();
      return () => {
        active = false;
      };
    }
  }, [getUserRolesDisplayed, pathname, roleSelectScreenContent, token]);

  useEffect(() => {
    if (accountDataContext) {
      const userRoleCookieValue = userRoleCookie();
      const onlyOneRole = onlyOneRoleAvailable();
      if (userRoleCookieValue) {
        setLoginTypeContext(userRoleCookieValue);
        setDisplayLoadingModal(true);
      } else if (onlyOneRole || pathname === VERIFY_PAGE_URL) {
        const roleAvailable = saveOnlyAvailableUserRole();
        setLoginTypeContext(roleAvailable);
        setDisplayLoadingModal(true);
      } else if (
        !modal.current &&
        accountDataContext &&
        roleSelectScreenContent &&
        isLoggedIn()
      ) {
        modal.current = modalShow({
          onClick: handleClickUserRole,
          type: ModalType.ROLES,
          userRoles: getUserRolesDisplayed(),
          rolesDescription: roleSelectScreenContent,
        });
        setDisplayLoadingModal(true);
      }
    }
  }, [
    accountDataContext,
    getUserRolesDisplayed,
    handleClickUserRole,
    isLoggedIn,
    modalHide,
    modalShow,
    onlyOneRoleAvailable,
    pathname,
    roleSelectScreenContent,
    saveOnlyAvailableUserRole,
    setDisplayLoadingModal,
    setLoginTypeContext,
    userRoleCookie,
  ]);

  return !displayLoadingModal && protectedURL(pathname) ? (
    <div className="page-container">
      <AuthLanding />
      <Loading.Modal />
    </div>
  ) : null;
};
