/* eslint-disable no-template-curly-in-string */

export enum ValdationMessages {
  API_ERROR = 'An error has occured. Please check your internet connection or try again later.',

  EMAIL = 'Please enter a valid email address.',

  USER_EXISTS = 'This email address has already been used.',

  CANDIDATE_NUMBER_CLAIMABLE = 'The number provided is already used or unrecognised.',

  NAME = 'Please enter a valid name.',

  MAX_LENGTH = 'The maximum length the answer can be is ${limit}.',

  NOT_TYPE = 'Please enter a valid ${type}.',

  ONE_OF = 'Please complete this question.',

  REQUIRED = 'Please complete this question.',

  TELEPHONE = 'Please enter a valid telephone number.',

  DATE = 'Please enter a valid date',

  CPD_COMPLETION_MAX = 'Completion date cannot be after today',
}

/* eslint-enable no-template-curly-in-string */

export default ValdationMessages;
