import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { useMediaQuery } from 'react-responsive';

import { filtersHookType, resourcesType } from '../../../types';
import { LoginTypeEnum } from 'modules/utils/types';
import { useSelectedFilters } from './hooks/useSelectedFilters';
import * as Button from 'components/Button';
import * as Filters from '../../Filters';

import styles from './styles.module.scss';
import { SortByTypes } from 'pages/resource-hub/utils';

type FiltersContainerProps = {
  filters: filtersHookType;
  getData: (resetNumberOfitemsShown: boolean) => Promise<void>;
  getSortByValue: (value: SortByTypes) => void;
  loginTypeContext: LoginTypeEnum;
  resources: resourcesType;
  slug: string;
  searchInitiated: boolean;
  searchTerm: string;
};

export const FiltersContainer: React.FunctionComponent<FiltersContainerProps> =
  ({
    filters,
    getData,
    getSortByValue,
    loginTypeContext,
    resources,
    slug = '',
    searchInitiated,
    searchTerm,
  }) => {
    const [isTopicsSelected, setIsTopicsSelected] = useState(false);
    const [isBestPracticesSelected, setIsBestPracticesSelected] =
      useState(false);
    const [isContentTypesSelected, setIsContentTypesSelected] = useState(false);
    const [showFilters, setShowFilters] = useState(false);
    const wrapperRef = useRef(null);

    const isMobile = useMediaQuery({ query: '(max-width: 520px)' });

    const closeFilters = () => {
      setIsTopicsSelected(false);
      setIsBestPracticesSelected(false);
      setIsContentTypesSelected(false);
    };
    const {
      addDeleteBestPractices,
      addDeleteContentTypes,
      addDeleteTopics,
      refreshResults,
      setRefreshResults,
    } = useSelectedFilters(filters, loginTypeContext, resources, slug);

    useEffect(() => {
      if (refreshResults) {
        getData(true);
        setRefreshResults(false);
      }
    }, [refreshResults, setRefreshResults, getData]);

    const classNames = clsx(
      styles.filtersContainer,
      isMobile && showFilters && styles.showFilters,
    );

    const getSortByType = (value: any) => {
      getSortByValue(value);
    };

    return (
      <div className={classNames} ref={wrapperRef}>
        <div>
          <Filters.Filter
            addDeleteItems={addDeleteTopics}
            closeFilters={closeFilters}
            isFilterSelected={isTopicsSelected}
            itemsSelected={filters.topicsSelected}
            listItems={resources.topics}
            setIsFilterSelected={setIsTopicsSelected}
            setItemsSelected={filters.setTopicsSelected}
            title="Topics"
          />
          <Filters.Filter
            addDeleteItems={addDeleteBestPractices}
            closeFilters={closeFilters}
            isFilterSelected={isBestPracticesSelected}
            itemsSelected={filters.bestPracticesSelected}
            listItems={resources.bestPractices}
            setIsFilterSelected={setIsBestPracticesSelected}
            setItemsSelected={filters.setBestPracticesSelected}
            title="Best Practice"
          />
          <Filters.Filter
            addDeleteItems={addDeleteContentTypes}
            closeFilters={closeFilters}
            isFilterSelected={isContentTypesSelected}
            itemsSelected={filters.contentTypesSelected}
            listItems={resources.contentTypes}
            setIsFilterSelected={setIsContentTypesSelected}
            setItemsSelected={filters.setContentTypesSelected}
            title="Content Type"
          />

          {(filters.topicsSelected[0].title !== 'All' ||
            filters.bestPracticesSelected[0].title !== 'All' ||
            filters.contentTypesSelected[0].title !== 'All' ||
            (searchTerm !== '' && searchInitiated)) && (
            <Filters.SortBy getSortByType={getSortByType} />
          )}

          {isMobile && (
            <Button.Generic
              className={styles.showFiltersButton}
              label="More filters"
              onClick={() => setShowFilters(true)}
            />
          )}
        </div>
        {(filters.topicsSelected[0].title !== 'All' ||
          filters.bestPracticesSelected[0].title !== 'All' ||
          filters.contentTypesSelected[0].title !== 'All') && (
          <div>
            <Filters.Selected
              itemsSelected={filters.topicsSelected}
              addDeleteItems={addDeleteTopics}
            />
            <Filters.Selected
              itemsSelected={filters.bestPracticesSelected}
              addDeleteItems={addDeleteBestPractices}
            />
            <Filters.Selected
              itemsSelected={filters.contentTypesSelected}
              addDeleteItems={addDeleteContentTypes}
            />
          </div>
        )}
      </div>
    );
  };

export default FiltersContainer;
