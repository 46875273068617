import { API, api } from 'modules/api';

import { AccountLoadedType } from '../../../types';
import { ChargebeeSubscriptionPlanId } from 'modules/chargebee/constants';
import { LoginTypeEnum } from 'modules/utils/types';
import { SIGN_UP_URL } from 'modules/utils/constants';
import { SubscriptionEndDateContext } from 'modules/context';
import { SubscriptionItemType } from '.';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import logger from 'modules/logger';

export const useSubscriptions = (
  setSkeletonLoaded: React.Dispatch<React.SetStateAction<AccountLoadedType>>,
  loginTypeContext?: LoginTypeEnum,
) => {
  const [subscriptionsList, setSubscriptionsList] =
    useState<SubscriptionItemType[]>();
  const [currentSubscription, setCurrentSubscription] =
    useState<SubscriptionItemType>();
  const { setSubscriptionEndDateContext } = useContext(
    SubscriptionEndDateContext,
  );

  const history = useHistory();

  const isMigratedUser = useCallback(
    (currentSubscriptionAux?: SubscriptionItemType): boolean =>
      currentSubscriptionAux?.currentPlan ===
        ChargebeeSubscriptionPlanId.STANDARD_INDIVIDUAL_MONTHLY_MIGRATION ||
      currentSubscriptionAux?.currentPlan ===
        ChargebeeSubscriptionPlanId.STANDARD_INDIVIDUAL_YEARLY_MIGRATION,
    [],
  );

  const validCurrentPlan = useCallback(
    (
      plan: SubscriptionItemType,
      currentSubscriptionAux: SubscriptionItemType,
    ): boolean => {
      return (
        plan.planFrequency !== null &&
        (plan.planFrequency !== 'Month' ||
          (plan.planFrequency === 'Month' &&
            (plan.currentPlan !== null ||
              isMigratedUser(currentSubscriptionAux))))
      );
    },
    [isMigratedUser],
  );

  const getPlansToBeDisplayed = useCallback(
    (
      plan: SubscriptionItemType,
      currentSubscriptionAux: SubscriptionItemType,
    ): boolean => {
      return validCurrentPlan(plan, currentSubscriptionAux);
    },
    [validCurrentPlan],
  );

  const isCurrentPlan = useCallback(
    (currentPlan: string | null): boolean => currentPlan !== null,
    [],
  );
  //TODO done useEffect
  useEffect(() => {
    if (loginTypeContext === LoginTypeEnum.INDIVIDUAL) {
      let active = true;
      (async () => {
        try {
          const response = await api(API.GET_SUBSCRIPTIONS());
          if (active) {
            const subscriptionsListAux =
              response.data as SubscriptionItemType[];
            const currentSubscriptionAux = subscriptionsListAux.filter(
              (subscription: SubscriptionItemType) =>
                isCurrentPlan(subscription.currentPlan),
            )[0];
            setCurrentSubscription(currentSubscriptionAux);

            let subscriptionArray = response.data.filter(
              (subscription: SubscriptionItemType) =>
                getPlansToBeDisplayed(subscription, currentSubscriptionAux),
            );
            subscriptionArray = subscriptionArray.sort(
              (a: SubscriptionItemType) => (a.planFee ? -1 : 1),
            );
            setSubscriptionsList(subscriptionArray);

            currentSubscriptionAux &&
              setSubscriptionEndDateContext(
                currentSubscriptionAux?.planEnd || '',
              );
          }
        } catch (error) {
          if (active) {
            logger.error('Get Subscriptions Error', error);
            setSubscriptionsList([]);
          }
        } finally {
          active &&
            setSkeletonLoaded(prevState => ({
              ...prevState,
              subscriptions: true,
            }));
        }
      })();
      return () => {
        active = false;
      };
    } else {
      setSkeletonLoaded(prevState => ({
        ...prevState,
        subscriptions: true,
      }));
    }
  }, [
    getPlansToBeDisplayed,
    isCurrentPlan,
    loginTypeContext,
    setSkeletonLoaded,
    setSubscriptionEndDateContext,
  ]);

  const handleUpgradeClick = useCallback(
    (subscriptionPlanId: string) => {
      history.push({
        pathname: SIGN_UP_URL,
        search: encodeURI(`?plan=${subscriptionPlanId}`),
      });
    },
    [history],
  );

  return {
    subscriptions: {
      subscriptionsList,
      currentSubscription,
      isCurrentPlan,
      handleUpgradeClick,
      isMigratedUser,
    },
  };
};
