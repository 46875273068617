import { useEffect, useState } from 'react';

import { API, api } from 'modules/api';
import { AchievementsGetResponse } from 'modules/api/endpoints/achievements';
import {
  BadgesCertificatesPathsTypes,
  BadgesLoadedType,
  BadgesCandidateIdTypes,
} from '../types';
import logger from 'modules/logger';

export const usePaths = (
  setLoaded: React.Dispatch<React.SetStateAction<BadgesLoadedType>>,
  candidateIds?: BadgesCandidateIdTypes[],
) => {
  const [paths, setPaths] = useState<
    BadgesCertificatesPathsTypes[] | undefined
  >();
  //TODO done useEffect
  useEffect(() => {
    let active = true;
    (async () => {
      try {
        const response: AchievementsGetResponse = await api(
          API.GET_BADGES_CERTIFICATE_PATHS(),
        );
        if (active) {
          setPaths(Array.isArray(response.data) ? response.data : []);
        }
      } catch (error) {
        active &&
          candidateIds &&
          candidateIds.length > 0 &&
          logger.error('Get Paths Error', error);
      } finally {
        active &&
          setLoaded(prevState => ({
            ...prevState,
            paths: true,
          }));
      }
    })();
    return () => {
      active = false;
    };
  }, [candidateIds, setLoaded]);

  return { paths };
};
