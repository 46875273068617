import yup, { ValdationMessages } from 'modules/validation';
import dayjs from 'dayjs';

const validateCpdCompletionValid = (value: string): boolean => {
  return dayjs(value).isValid() ? true : false;
};

const validateCpdCompletionMax = (value: string): boolean => {
  return !dayjs(value).isAfter(dayjs()) ? true : false;
};

const minMessage = 'Completion date cannot be before';

function validator(this: yup.StringSchema) {
  return this.test(
    'cpdCompletion',
    ValdationMessages.CPD_COMPLETION_MAX,
    function (value) {
      return value ? validateCpdCompletionMax(value) : true;
    },
  )
    .test('cpdCompletion', ValdationMessages.DATE, function (value) {
      return value ? validateCpdCompletionValid(value) : true;
    })
    .test('minDate', minMessage, function (value) {
      const ref = this.resolve(yup.ref('minDate')) || '';
      const minDate = dayjs(ref) < dayjs(value) ? true : false;
      return value ? minDate : true;
    });
}

export default validator;
