export enum CertificatesPropPathTitles {
  PRINCE2_FOUNDATION = 'PRINCE2 Foundation',
  PRINCE2_PRACTITIONER = 'PRINCE2 Practitioner',
  PRINCE2_AGILE_FOUNDATION = 'PRINCE2 Agile Foundation',
  PRINCE2_AGILE_PRACTITIONER = 'PRINCE2 Agile Practitioner',
  MSP_FOUNDATION = 'MSP Foundation',
  MSP_PRACTITIONER = 'MSP Practitioner',
  MOR_PRACTITIONER = 'M_o_R Practitioner',
  P3O_FOUNDATION = 'P3O Foundation',
  P3O_PRACTITIONER = 'P3O Practitioner',
  MOP_FOUNDATION = 'MoP Foundation',
  MOP_PRACTITIONER = 'MoP Practitioner',
}

export enum ProPathDesignationsTitles {
  PROJECT_EXPERT = 'ProPath Project Expert',
  AGILE_PROJECT_EXPERT = 'ProPath Agile Project Expert',
  PROGRAMME_LEADER = 'ProPath Programme Leader',
}

export enum CertificatesItilTitles {
  FOUNDATION = 'ITIL Foundation',
  CREATE_DELIVER_SUPPORT = 'Create, Deliver & Support',
  DRIVE_STAKEHOLDER_VALUE = 'Drive Stakeholder Value',
  HIGH_VELOCITY_IT = 'High Velocity IT',
  DIRECT_PLAN_IMPROVE = 'Direct, Plan & Improve',
  DIGITAL_IT_STRATEGY = 'Digital & IT Strategy',
  SUSTAINABILITY_IN_DIGITAL_IT = 'Sustainability in Digital & IT',
  ACQUIRING_MANAGING_CLOUD_SERVICES = 'Acquiring & Managing Cloud Services',
}

export enum ItilDesignationsTitles {
  MANAGING_PROFESSIONAL = 'ITIL Managing Professional',
  STRATEGIC_LEADER = 'ITIL Strategic Leader',
}
