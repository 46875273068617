import { BadgeLinksTypes, BadgesTypes } from 'pages/badges/types';
import {
  CertificatesItilTitles,
  CertificatesPropPathTitles,
  ItilDesignationsTitles,
  ProPathDesignationsTitles,
} from 'pages/badges/constants';
import { EventsActions, EventsCategories } from 'modules/gtm/constants';
import { TriggerGTMEvent } from 'modules/gtm';

export const useAchieved = (
  achieved?: BadgesTypes[],
  findOutMoreLink?: BadgeLinksTypes,
) => {
  let canRecordCpd;
  let className;

  type LinkType = {
    href?: string | null;
    text?: string | null;
  };

  const link: LinkType = {
    href: '',
    text: '',
  };

  const certificateID =
    achieved && achieved.length > 0 && achieved[0].certificate != null
      ? achieved[0].certificate.id
      : '';

  const certificatesCanRecordCpd =
    achieved && achieved.length > 0 && achieved[0].certificate != null
      ? achieved[0].certificate.canRecordCpd
      : false;

  const hasDateExpiry =
    achieved &&
    achieved.length > 0 &&
    achieved[0].certificate != null &&
    achieved[0].certificate.dateExpires != null
      ? true
      : false;

  const date = {
    expiry: () => {
      if (achieved && achieved.length > 0) {
        const d = new Date(achieved[0].certificate.dateExpires);
        return `Expires: ${d.getMonth() + 1}/${d.getFullYear()}`;
      }
      return null;
    },
    taken:
      achieved && achieved.length > 0 && achieved[0].certificate != null
        ? achieved[0].certificate.dateTaken
        : '',
  };

  switch (achieved && achieved.length > 0 && achieved[0].status) {
    case 4:
      canRecordCpd = certificatesCanRecordCpd;
      className = 'claimed';
      link.href = (achieved && achieved[0]?.viewUrl) || null;
      link.text = 'View';
      break;
    case 2:
      canRecordCpd = false;
      className = 'canClaim';
      link.href = (achieved && achieved[0]?.acceptUrl) || null;
      link.text = 'Claim Badge';
      break;
    case 1:
      canRecordCpd = false;
      className = 'examPassed';
      link.href = null;
      link.text = null;
      break;
    default:
      canRecordCpd = false;
      className = null;
      link.href = findOutMoreLink
        ? findOutMoreLink[0]?.find_out_more_link
        : null;
      link.text = 'Find out more';
      break;
  }

  return {
    canRecordCpd,
    certificateID,
    className,
    date,
    hasDateExpiry,
    link,
  };
};

export const useClaimBadgeGTMTrigger = () => {
  const handleBadgeClaimed = (badgeTitle: string, linktext: string) => {
    if (linktext === 'Claim Badge') {
      let actionType = '';

      switch (badgeTitle) {
        case CertificatesPropPathTitles.PRINCE2_FOUNDATION:
          actionType = EventsActions.CLICK_PRINCE2_F;
          break;
        case CertificatesPropPathTitles.PRINCE2_PRACTITIONER:
          actionType = EventsActions.CLICK_PRINCE2_P;
          break;
        case CertificatesPropPathTitles.PRINCE2_AGILE_FOUNDATION:
          actionType = EventsActions.CLICK_PRINCE2_AGILE_F;
          break;
        case CertificatesPropPathTitles.PRINCE2_AGILE_PRACTITIONER:
          actionType = EventsActions.CLICK_PRINCE2_AGILE_P;
          break;
        case CertificatesPropPathTitles.MSP_FOUNDATION:
          actionType = EventsActions.CLICK_MSP_F;
          break;
        case CertificatesPropPathTitles.MSP_PRACTITIONER:
          actionType = EventsActions.CLICK_MSP_P;
          break;
        case CertificatesPropPathTitles.MOR_PRACTITIONER:
          actionType = EventsActions.CLICK_M_O_R;
          break;
        case CertificatesPropPathTitles.P3O_FOUNDATION:
          actionType = EventsActions.CLICK_P30_F;
          break;
        case CertificatesPropPathTitles.P3O_PRACTITIONER:
          actionType = EventsActions.CLICK_P30_P;
          break;
        case CertificatesPropPathTitles.MOP_FOUNDATION:
          actionType = EventsActions.CLICK_MOP_F;
          break;
        case CertificatesPropPathTitles.MOP_PRACTITIONER:
          actionType = EventsActions.CLICK_MOP_P;
          break;
        case ProPathDesignationsTitles.PROJECT_EXPERT:
          actionType = EventsActions.CLICK_PROJECT_EXPERT;
          break;
        case ProPathDesignationsTitles.AGILE_PROJECT_EXPERT:
          actionType = EventsActions.CLICK_AGILE_PROJECT_EXPERT;
          break;
        case ProPathDesignationsTitles.PROGRAMME_LEADER:
          actionType = EventsActions.CLICK_PROGRAMME_LEADER;
          break;
        case CertificatesItilTitles.FOUNDATION:
          actionType = EventsActions.CLICK_ITIL_F;
          break;
        case CertificatesItilTitles.CREATE_DELIVER_SUPPORT:
          actionType = EventsActions.CLICK_ITIL_CDS;
          break;
        case CertificatesItilTitles.DRIVE_STAKEHOLDER_VALUE:
          actionType = EventsActions.CLICK_ITIL_DSV;
          break;
        case CertificatesItilTitles.HIGH_VELOCITY_IT:
          actionType = EventsActions.CLICK_ITIL_HVIT;
          break;
        case CertificatesItilTitles.SUSTAINABILITY_IN_DIGITAL_IT:
          actionType = EventsActions.CLICK_ITIL_SIDIT;
          break;
        case CertificatesItilTitles.ACQUIRING_MANAGING_CLOUD_SERVICES:
          actionType = EventsActions.CLICK_ITIL_AMCS;
          break;
        case CertificatesItilTitles.DIRECT_PLAN_IMPROVE:
          actionType = EventsActions.CLICK_ITIL_DPI;
          break;
        case CertificatesItilTitles.DIGITAL_IT_STRATEGY:
          actionType = EventsActions.CLICK_ITIL_DITS;
          break;
        case ItilDesignationsTitles.MANAGING_PROFESSIONAL:
          actionType = EventsActions.CLICK_ITIL_MP;
          break;
        case ItilDesignationsTitles.STRATEGIC_LEADER:
          actionType = EventsActions.CLICK_ITIL_SL;
          break;
      }
      TriggerGTMEvent({
        action: actionType,
        category: EventsCategories.BADGING,
        label: '',
      });
    }
  };

  return { handleBadgeClaimed };
};
