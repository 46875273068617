import { useContext, useEffect, useState } from 'react';

import { API, api } from 'modules/api';
import { CandidateIdsGetResponse } from 'modules/api/endpoints/candidate-ids';
import { CandidateIdTypes } from '../types';
import { LoginTypeContext } from 'modules/context';
import { LoginTypeEnum } from 'modules/utils/types';
import logger from 'modules/logger';

export const useCandidateIds = () => {
  const { loginTypeContext } = useContext(LoginTypeContext);
  const [candidateIds, setCandidateIds] = useState<
    CandidateIdTypes[] | undefined
  >();

  useEffect(() => {
    if (loginTypeContext && loginTypeContext !== LoginTypeEnum.CONSULTANT) {
      //TODO done useEffect
      let active = true;
      (async () => {
        try {
          const response: CandidateIdsGetResponse = await api(
            API.GET_CANDIDATE_ID(),
          );
          active && setCandidateIds(response.data);
        } catch (error) {
          active && logger.error('Get Candidate Numbers Error', error);
        }
      })();
      return () => {
        active = false;
      };
    }
  }, [loginTypeContext]);

  return { candidateIds };
};
