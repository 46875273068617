import {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { API, api } from 'modules/api';
import { cpdSubmissionResponse } from '../types';
import { EventsActions, EventsCategories } from 'modules/gtm/constants';
import { ModalType } from 'modules/modals/constants';
import { Model } from '../model';
import { TriggerGTMEvent } from 'modules/gtm';
import dayjs from 'dayjs';
import useModalAction from 'modules/modals/hooks/useModalAction';

export const useSubmitCpd = (modalRef: MutableRefObject<string | null>) => {
  const [isApiSubmitting, setIsApiSubmitting] = useState(false);
  const { modalShow, modalHide } = useModalAction();
  const errorModal = useRef<string | null>(null);
  const active = useRef(true);
  const modal = useRef<string | null>(null);

  const showCpdClaimModal = useCallback(
    (res: cpdSubmissionResponse) => {
      modal.current = modalShow({
        onClose: () => {
          !!modal.current && modalHide(modal.current);
          modal.current = null;
        },
        res: res,
        type: ModalType.CPD_CLAIM,
      });
    },
    [modalHide, modalShow],
  );

  const showErrorModal = useCallback(() => {
    errorModal.current = modalShow({
      onClose: () => {
        !!errorModal.current && modalHide(errorModal.current);
        errorModal.current = null;
      },
      title: 'Error',
      text: 'There was an error trying to send your data. Please try again later',
      type: ModalType.INFO,
    });
  }, [modalHide, modalShow]);

  const handleSuccess = useCallback(
    data => {
      if (modalRef) {
        modalRef.current && modalHide(modalRef.current);
        modalRef.current = null;
      }
      TriggerGTMEvent({
        action: EventsActions.SUBMIT_BUTTON_ON_CPD_FORM,
        category: EventsCategories.CPD,
        label: '',
      });
      showCpdClaimModal(data);
    },
    [modalHide, modalRef, showCpdClaimModal],
  );

  //TODO done useCallback
  const handleSubmit = useCallback(
    async (data: Model) => {
      if (!isApiSubmitting) {
        try {
          setIsApiSubmitting(true);
          const completion = dayjs(data.completionDate).toISOString();
          const cpdClaim = {
            certificate: data.certificateId,
            points: Number(data.claimedPoints),
            completionDate: completion,
            type: Number(data.cpdType),
            description: data.description,
            comment: data.whatDidYouLearn,
          };
          const cpdPoints = await api(API.POST_BADGE_CPD_CLAIM(cpdClaim));
          active.current && handleSuccess(cpdPoints.data);
        } catch (error) {
          active.current && showErrorModal();
        } finally {
          active.current && setIsApiSubmitting(false);
        }
      }
    },
    [handleSuccess, isApiSubmitting, showErrorModal],
  );

  useEffect(() => {
    return () => {
      active.current = false;
    };
  }, []);

  return {
    handleSubmit,
    isApiSubmitting,
  };
};
