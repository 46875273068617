import React from 'react';

import { QuestionProps } from '../Template/types';
import Select, { OptionType } from 'components/Inputs/Select';
import * as Question from 'components/Question';

type QuestionSelectProps<T> = QuestionProps<T> & {
  options?: OptionType[];
  readOnly?: boolean;
};

function Component<T>({
  control,
  disabled,
  help,
  isSubmitting,
  label,
  options,
  question,
  readOnly,
}: QuestionSelectProps<T>) {
  return (
    <Question.Template
      control={control}
      help={help}
      label={label}
      question={question}
    >
      {({ invalid, name, onBlur, onChange, ref, value }) => (
        <Select
          ariaLabel={label}
          disabled={(options && options.length === 0) || options === undefined}
          forwardedRef={ref}
          invalid={invalid}
          label={label}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          options={options}
          readOnly={isSubmitting || readOnly}
          tabIndex={disabled ? -1 : 0}
          value={value}
        />
      )}
    </Question.Template>
  );
}

export default React.memo(Component) as typeof Component;
