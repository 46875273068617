import React, { useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import clsx from 'clsx';

import { AccountDataContext, LoginTypeContext } from 'modules/context';
import {
  BOOKMARKS_URL,
  CONGRATULATIONS_PAGE_URL,
  LOGIN_URL,
  SIGN_UP_URL,
} from 'modules/utils/constants';
import {
  ButtonColour,
  ButtonStyle,
  IconStyle,
} from 'components/Button/constants';
import { ReactComponent as ChevronSVG } from 'modules/theme/icons/chevrons/chevron-right.svg';
import { ReactComponent as PrimaryBookmarkSVG } from 'modules/theme/icons/general/bookmark-primary.svg';
import { useLoginToken } from 'modules/hooks';
import { useNavigation } from './hooks/useNavigation';
import * as Button from 'components/Button';
import Account from './Account';
import BurgerMenuButton from './BurgerMenuButton';
import Logo from 'components/Logo';
import Menu, { NavMenuItem } from './Menu';

import styles from './styles.module.scss';

type HeaderProps = {
  navMenu?: NavMenuItem[];
};

const Header: React.FunctionComponent<HeaderProps> = ({ navMenu }) => {
  const { accountDataContext } = useContext(AccountDataContext);
  const { loginTypeContext } = useContext(LoginTypeContext);
  const { pathname } = useLocation();
  const [toggleMobileMenu, setToggleMobileMenu] = useState(false);
  const isMobile = useMediaQuery({
    query: `(max-width: 767px)`,
  });

  const { isLoggedIn } = useLoginToken();

  const { handleCloseMenu, handleMenuToggle, menuAnimating, navRef } =
    useNavigation({
      isMobile,
      setToggleMobileMenu,
      toggleMobileMenu,
    });

  const classNames = clsx(
    styles.header,
    toggleMobileMenu && styles.menuAnimating,
  );

  if (pathname === '/sign-in') return null;

  return (
    <React.Fragment>
      <header className={classNames}>
        <div className="container">
          <Logo
            isLoggedIn={isLoggedIn}
            pathname={pathname}
            role={loginTypeContext}
          />
          {!isMobile && isLoggedIn() && (
            <Menu
              accountDataContext={accountDataContext}
              forwardedRef={navRef}
              handleCloseMenu={handleCloseMenu}
              isLoggedIn={isLoggedIn}
              isMobile={isMobile}
              navLinks={navMenu}
              toggleMobileMenu={toggleMobileMenu}
            />
          )}
          {!isMobile && isLoggedIn() && (
            <Button.Icon
              ariaLabel="Bookmarks"
              className={styles.bookmark}
              iconStyle={IconStyle.PRIMARY}
              slug={BOOKMARKS_URL}
              title="Bookmarks"
            >
              <PrimaryBookmarkSVG />
            </Button.Icon>
          )}
          {isMobile && isLoggedIn() && (
            <BurgerMenuButton
              handleMenuToggle={handleMenuToggle}
              toggleMobileMenu={toggleMobileMenu}
            />
          )}
          {!isMobile && isLoggedIn() && (
            <Account
              accountDataContext={accountDataContext}
              handleCloseMenu={handleCloseMenu}
            />
          )}
          {!isLoggedIn() &&
            false &&
            pathname !== SIGN_UP_URL &&
            pathname !== CONGRATULATIONS_PAGE_URL && (
              <Button.CustomLink
                colour={ButtonColour.BRAND_AQUA}
                className={styles.signIn}
                href={LOGIN_URL}
                label="Sign in"
                style={ButtonStyle.SECONDARY}
              >
                <ChevronSVG />
              </Button.CustomLink>
            )}
        </div>
      </header>
      {isMobile && isLoggedIn() && (
        <Menu
          accountDataContext={accountDataContext}
          forwardedRef={navRef}
          handleCloseMenu={handleCloseMenu}
          isLoggedIn={isLoggedIn}
          isMobile={isMobile}
          navLinks={navMenu}
          menuAnimating={menuAnimating}
          toggleMobileMenu={toggleMobileMenu}
        />
      )}
    </React.Fragment>
  );
};

export default Header;
