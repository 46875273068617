import React from 'react';
import { useMediaQuery } from 'react-responsive';

import { Braintree } from 'modules/braintree';
import { model, Model, schema } from '../QuestionSet/model';
import { ProductInfoProps, VoucherCodeProps } from '../types';
import { ReactComponent as SecureCheckoutIcon } from 'modules/theme/ims/secure-checkout.svg';
import { ReactComponent as SecureIcon } from 'modules/theme/ims/secure-icon.svg';
import * as FormControl from '../FormControl';
import * as QuestionSet from '../QuestionSet';
import Fieldset from 'components/Fieldset';
import PurchaseSummary from '../PurchaseSummary';
import VoucherCode from '../VoucherCode';

import styles from './styles.module.scss';

type PaymentQuestionsProps = {
  defaultValues: Model;
  handleBack: () => void;
  handleSubmit: (data: any) => Promise<void>;
  isSubmitting: boolean;
  isUserFromPeopleCert?: boolean;
  productInfo: ProductInfoProps;
  voucherCode: VoucherCodeProps;
};

function PaymentQuestions({
  defaultValues,
  handleBack,
  handleSubmit,
  isSubmitting,
  isUserFromPeopleCert,
  productInfo,
  voucherCode,
}: PaymentQuestionsProps) {
  const isMobileBreakpoint = useMediaQuery({ query: '(max-width: 767px)' });
  const displaySecureIcon = useMediaQuery({ query: '(max-width: 450px)' });

  return (
    <Braintree<Model>
      className={styles.paymentForm}
      defaultValues={defaultValues}
      onSubmit={handleSubmit}
      title="Payment details"
      schema={schema[1]}
    >
      {({ control, isFormLoading, isFormSubmitting }) => (
        <React.Fragment>
          <div className={styles.secureIcon}>
            {displaySecureIcon ? <SecureIcon /> : <SecureCheckoutIcon />}
          </div>
          <Fieldset title="User agreement">
            <QuestionSet.Consent
              control={control}
              isSubmitting={isFormLoading || isFormSubmitting || isSubmitting}
              model={model}
            />
          </Fieldset>

          {isMobileBreakpoint && (
            <PurchaseSummary showBackLink={true} {...productInfo}>
              <VoucherCode
                {...voucherCode}
                isSubmitting={isFormLoading || isFormSubmitting || isSubmitting}
              />
            </PurchaseSummary>
          )}

          <FormControl.PaymentDetails
            handleBack={handleBack}
            isLoading={isFormLoading}
            isSubmitting={isFormSubmitting || isSubmitting}
            isUserFromPeopleCert={isUserFromPeopleCert}
          />
        </React.Fragment>
      )}
    </Braintree>
  );
}

export default React.memo(PaymentQuestions) as typeof PaymentQuestions;
