import { useCallback, useEffect, useRef } from 'react';

import { APIExceptions } from 'modules/api/exceptions';
import { ModalType } from 'modules/modals/constants';
import logger from 'modules/logger';
import useModalAction from 'modules/modals/hooks/useModalAction';

export const useHandleSignUpError = () => {
  const { modalShow, modalHide } = useModalAction();
  const modal = useRef<string | null>(null);

  const handleSignUpError = useCallback(
    (error: any) => {
      let errorTitle = 'Oops, something went wrong…';
      let errorText =
        'There was a problem with the payment details you provided. Please check them and try again.';

      switch (error?.response?.data?.type) {
        case APIExceptions.CandidateIdAlreadyExistsException:
          errorText =
            'It looks like this candidate number has already been used';
          break;
        case APIExceptions.CandidateIdValidationException:
        case APIExceptions.CandidateIdNotFoundException:
        case APIExceptions.CandidateIdClaimCheckNoExamException:
          logger.error(
            'There was a problem when handling your candidate number',
            error,
          );
          errorText =
            'There was a problem when handling your candidate number. Please check it and try again.';
          break;
        case APIExceptions.CandidateIdClaimCheckValidationException:
          errorTitle = "We're having trouble creating your account";
          errorText =
            "The name you have entered doesn't match the name associated with your Candidate Number. This may be because you have a double-barrelled name or you have recently married and changed your name. Please enter your name as it appears on your exam certificate and try again.";
          break;
      }

      modal.current = modalShow({
        onClose: () => {
          !!modal.current && modalHide(modal.current);
          modal.current = null;
        },
        title: errorTitle,
        text: errorText,
        type: ModalType.INFO,
      });
    },
    [modalHide, modalShow],
  );

  useEffect(() => {
    return () => {
      !!modal.current && modalHide(modal.current);
      modal.current = null;
    };
  }, [modalHide]);

  return { handleSignUpError } as const;
};
