import { QuestionType } from 'components/Question/Template/types';
import yup from 'modules/validation';

export const schema = yup.object().shape({
  username: yup.string().required('Enter Username'),
  password: yup.string().required('Enter Password'),
  // password: yup
  //   .string()
  //   .required('Please Enter your password')
  //   .matches(
  //     /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
  //     'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character',
  //   ),
});

export type Model = {
  username: string;
  password: string;
};

const username: QuestionType<Model> = {
  name: 'username',
};
const password: QuestionType<Model> = {
  name: 'password',
};

export const model = {
  username,
  password,
};
