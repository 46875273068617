import { useLoginToken } from 'modules/hooks';
import { SESSION_IDLE_MINUTES } from 'modules/utils/constants';
import IdleTimer from 'react-idle-timer';

export const AutoLogoutTimer = () => {
  const { logOutAndRedirection } = useLoginToken();

  const handleOnIdle = () => {
    logOutAndRedirection();
  };

  return (
    <IdleTimer
      timeout={1000 * 60 * SESSION_IDLE_MINUTES}
      onIdle={handleOnIdle}
      debounce={500}
    />
  );
};
