import { BraintreeErrors } from './types';

export enum BraintreeCardType {
  AMERICAN_EXPRESS = 'americanexpress',
  DINERS_CLUB = 'dinersclub',
  DISCOVER = 'discover',
  ELO = 'elo',
  HIPER = 'hiper',
  HIPERCARD = 'hipercard',
  JCB = 'jcb',
  MAESTRO = 'maestro',
  MASTERCARD = 'mastercard',
  MIR = 'mir',
  UNION_PAY = 'unionpay',
  VISA = 'visa',
}

export enum BraintreeErrorType {
  /* Hosted Fields - Init */
  HOSTED_FIELDS_TIMEOUT = 'HOSTED_FIELDS_TIMEOUT',
  HOSTED_FIELDS_FIELD_NOT_PRESENT = 'HOSTED_FIELDS_FIELD_NOT_PRESENT',
  HOSTED_FIELDS_INVALID_FIELD_KEY = 'HOSTED_FIELDS_INVALID_FIELD_KEY',
  HOSTED_FIELDS_INVALID_FIELD_SELECTOR = 'HOSTED_FIELDS_INVALID_FIELD_SELECTOR',
  HOSTED_FIELDS_FIELD_DUPLICATE_IFRAME = 'HOSTED_FIELDS_FIELD_DUPLICATE_IFRAME',
  HOSTED_FIELDS_FIELD_PROPERTY_INVALID = 'HOSTED_FIELDS_FIELD_PROPERTY_INVALID',
  HOSTED_FIELDS_ATTRIBUTE_NOT_SUPPORTED = 'HOSTED_FIELDS_ATTRIBUTE_NOT_SUPPORTED',
  HOSTED_FIELDS_ATTRIBUTE_VALUE_NOT_ALLOWED = 'HOSTED_FIELDS_ATTRIBUTE_VALUE_NOT_ALLOWED',

  /* Hosted Fields - Tokenize */
  HOSTED_FIELDS_FIELDS_EMPTY = 'HOSTED_FIELDS_FIELDS_EMPTY',
  HOSTED_FIELDS_FIELDS_INVALID = 'HOSTED_FIELDS_FIELDS_INVALID',
  HOSTED_FIELDS_TOKENIZATION_FAIL_ON_DUPLICATE = 'HOSTED_FIELDS_TOKENIZATION_FAIL_ON_DUPLICATE',
  HOSTED_FIELDS_TOKENIZATION_CVV_VERIFICATION_FAILED = 'HOSTED_FIELDS_TOKENIZATION_CVV_VERIFICATION_FAILED',
  HOSTED_FIELDS_FAILED_TOKENIZATION = 'HOSTED_FIELDS_FAILED_TOKENIZATION',
  HOSTED_FIELDS_TOKENIZATION_NETWORK_ERROR = 'HOSTED_FIELDS_TOKENIZATION_NETWORK_ERROR',

  /* 3D Secure - Init */
  THREEDS_NOT_ENABLED = 'THREEDS_NOT_ENABLED',
  THREEDS_CAN_NOT_USE_TOKENIZATION_KEY = 'THREEDS_CAN_NOT_USE_TOKENIZATION_KEY',
  THREEDS_HTTPS_REQUIRED = 'THREEDS_HTTPS_REQUIRED',
  THREEDS_NOT_ENABLED_FOR_V2 = 'THREEDS_NOT_ENABLED_FOR_V2',
  THREEDS_UNRECOGNIZED_VERSION = 'THREEDS_UNRECOGNIZED_VERSION',

  /* 3D Secure - Cardinal */
  THREEDS_CARDINAL_SDK_SETUP_FAILED = 'THREEDS_CARDINAL_SDK_SETUP_FAILED',
  THREEDS_CARDINAL_SDK_SCRIPT_LOAD_FAILED = 'THREEDS_CARDINAL_SDK_SCRIPT_LOAD_FAILED',
  THREEDS_CARDINAL_SDK_SETUP_TIMEDOUT = 'THREEDS_CARDINAL_SDK_SETUP_TIMEDOUT',
  THREEDS_CARDINAL_SDK_RESPONSE_TIMEDOUT = 'THREEDS_CARDINAL_SDK_RESPONSE_TIMEDOUT',
  THREEDS_CARDINAL_SDK_BAD_CONFIG = 'THREEDS_CARDINAL_SDK_BAD_CONFIG',
  THREEDS_CARDINAL_SDK_BAD_JWT = 'THREEDS_CARDINAL_SDK_BAD_JWT',
  THREEDS_CARDINAL_SDK_ERROR = 'THREEDS_CARDINAL_SDK_ERROR',
  THREEDS_CARDINAL_SDK_CANCELED = 'THREEDS_CARDINAL_SDK_CANCELED',

  /* 3D Secure - Verify */
  THREEDS_NO_VERIFICATION_PAYLOAD = 'THREEDS_NO_VERIFICATION_PAYLOAD',
  THREEDS_AUTHENTICATION_IN_PROGRESS = 'THREEDS_AUTHENTICATION_IN_PROGRESS',
  THREEDS_MISSING_VERIFY_CARD_OPTION = 'THREEDS_MISSING_VERIFY_CARD_OPTION',
  THREEDS_JWT_AUTHENTICATION_FAILED = 'THREEDS_JWT_AUTHENTICATION_FAILED',
  THREEDS_LOOKUP_TOKENIZED_CARD_NOT_FOUND_ERROR = 'THREEDS_LOOKUP_TOKENIZED_CARD_NOT_FOUND_ERROR',
  THREEDS_LOOKUP_VALIDATION_ERROR = 'THREEDS_LOOKUP_VALIDATION_ERROR',
  THREEDS_LOOKUP_ERROR = 'THREEDS_LOOKUP_ERROR',
  THREEDS_VERIFY_CARD_CANCELED_BY_MERCHANT = 'THREEDS_VERIFY_CARD_CANCELED_BY_MERCHANT',
  THREEDS_INLINE_IFRAME_DETAILS_INCORRECT = 'THREEDS_INLINE_IFRAME_DETAILS_INCORRECT',

  /* 3D Secure - Failed Verification */
  THREEDS_FAILED_VERIFICATION = 'THREEDS_FAILED_VERIFICATION',
}

export enum BraintreeErrorMessage {
  DUPLICATE = 'Looks like you have already registered this card, please check your payment methods.',
  ERROR = 'An error has occurred. The page will now reload, please try again.',
  FAILED_VERIFICATION = 'We were unable to verify your card, please check the details you have entered and try again.',
  IN_PROGRESS = 'Verification is already in progress.',
  INVALID_FIELDS = 'Please check the details you have entered and try again.',
  INVALID_CVV = 'Please check your CVV code and try again.',
  NETWORK = 'A network error has occurred. Please check your connection and try again.',
}

export enum BraintreeErrorAction {
  NONE = 'NONE',
  RELOAD = 'RELOAD',
  RETRY = 'RETRY',
}

export const braintreeErrors: BraintreeErrors = [
  /* Occurs when Hosted Fields does not finish setting up within 60 seconds */
  {
    error: BraintreeErrorType.HOSTED_FIELDS_TIMEOUT,
    message: BraintreeErrorMessage.NETWORK,
    action: BraintreeErrorAction.RETRY,
  },

  /* Occurs when attempting to modify a field that is not configured with Hosted Fields */
  {
    error: BraintreeErrorType.HOSTED_FIELDS_FIELD_NOT_PRESENT,
    message: BraintreeErrorMessage.ERROR,
    action: BraintreeErrorAction.RETRY,
  },

  /* Occurs when Hosted Fields is instantiated with an invalid Field option */
  {
    error: BraintreeErrorType.HOSTED_FIELDS_INVALID_FIELD_KEY,
    message: BraintreeErrorMessage.ERROR,
    action: BraintreeErrorAction.RETRY,
  },

  /* Occurs when Hosted Fields given a field selector that is not valid */
  {
    error: BraintreeErrorType.HOSTED_FIELDS_INVALID_FIELD_SELECTOR,
    message: BraintreeErrorMessage.ERROR,
    action: BraintreeErrorAction.RETRY,
  },

  /* Occurs when Hosted Fields given a field selector that already contains an iframe */
  {
    error: BraintreeErrorType.HOSTED_FIELDS_FIELD_DUPLICATE_IFRAME,
    message: BraintreeErrorMessage.ERROR,
    action: BraintreeErrorAction.RELOAD,
  },

  /* Occurs when a field configuration option is not valid */
  {
    error: BraintreeErrorType.HOSTED_FIELDS_FIELD_PROPERTY_INVALID,
    message: BraintreeErrorMessage.ERROR,
    action: BraintreeErrorAction.RETRY,
  },

  /* Occurs when trying to set an attribute that is not supported to be set */
  {
    error: BraintreeErrorType.HOSTED_FIELDS_ATTRIBUTE_NOT_SUPPORTED,
    message: BraintreeErrorMessage.ERROR,
    action: BraintreeErrorAction.RETRY,
  },

  /* Occurs when the type of value for an attribute is not allowed to be set */
  {
    error: BraintreeErrorType.HOSTED_FIELDS_ATTRIBUTE_VALUE_NOT_ALLOWED,
    message: BraintreeErrorMessage.ERROR,
    action: BraintreeErrorAction.RETRY,
  },

  /* Occurs when all the Hosted Fields inputs are empty */
  {
    error: BraintreeErrorType.HOSTED_FIELDS_FIELDS_EMPTY,
    message: BraintreeErrorMessage.INVALID_FIELDS,
    action: BraintreeErrorAction.NONE,
  },

  /* Occurs when one ore more fields are invalid */
  {
    error: BraintreeErrorType.HOSTED_FIELDS_FIELDS_INVALID,
    message: BraintreeErrorMessage.INVALID_FIELDS,
    action: BraintreeErrorAction.NONE,
  },

  /* Occurs when attempting to vault a card, but the client token being used is configured to fail if the card already exists in the vault */
  {
    error: BraintreeErrorType.HOSTED_FIELDS_TOKENIZATION_FAIL_ON_DUPLICATE,
    message: BraintreeErrorMessage.DUPLICATE,
    action: BraintreeErrorAction.NONE,
  },

  /* Occurs when cvv verification is turned on in the Braintree control panel */
  {
    error:
      BraintreeErrorType.HOSTED_FIELDS_TOKENIZATION_CVV_VERIFICATION_FAILED,
    message: BraintreeErrorMessage.INVALID_CVV,
    action: BraintreeErrorAction.NONE,
  },

  /* Occurs when the credit card details were sent to Braintree, but failed to tokenize */
  {
    error: BraintreeErrorType.HOSTED_FIELDS_FAILED_TOKENIZATION,
    message: BraintreeErrorMessage.FAILED_VERIFICATION,
    action: BraintreeErrorAction.NONE,
  },

  /* Occurs when the Braintree gateway cannot be contacted */
  {
    error: BraintreeErrorType.HOSTED_FIELDS_TOKENIZATION_NETWORK_ERROR,
    message: BraintreeErrorMessage.NETWORK,
    action: BraintreeErrorAction.RETRY,
  },

  /* Occurs when 3D Secure is not enabled in the Braintree control panel */
  {
    error: BraintreeErrorType.THREEDS_NOT_ENABLED,
    message: BraintreeErrorMessage.ERROR,
    action: BraintreeErrorAction.RETRY,
  },

  /* Occurs when 3D Secure component is created without a Client Token */
  {
    error: BraintreeErrorType.THREEDS_CAN_NOT_USE_TOKENIZATION_KEY,
    message: BraintreeErrorMessage.ERROR,
    action: BraintreeErrorAction.RETRY,
  },

  /* Occurs when 3D Secure component is created in production over HTTPS */
  {
    error: BraintreeErrorType.THREEDS_HTTPS_REQUIRED,
    message: BraintreeErrorMessage.ERROR,
    action: BraintreeErrorAction.RELOAD,
  },

  /* Occurs when 3D Secure component is created with version 2 parameter, but merchant is not enabled to use version 2 */
  {
    error: BraintreeErrorType.THREEDS_NOT_ENABLED_FOR_V2,
    message: BraintreeErrorMessage.ERROR,
    action: BraintreeErrorAction.RETRY,
  },

  /* Occurs when unrecognized version enum is passed into the create call */
  {
    error: BraintreeErrorType.THREEDS_UNRECOGNIZED_VERSION,
    message: BraintreeErrorMessage.ERROR,
    action: BraintreeErrorAction.RETRY,
  },

  /* Occurs when Cardinal's Songbird.js library fails to setup for an unknown reason */
  {
    error: BraintreeErrorType.THREEDS_CARDINAL_SDK_SETUP_FAILED,
    message: BraintreeErrorMessage.ERROR,
    action: BraintreeErrorAction.RETRY,
  },

  /* Occurs when using version 2 and Cardinal's Songbird.js script could not be loaded */
  {
    error: BraintreeErrorType.THREEDS_CARDINAL_SDK_SCRIPT_LOAD_FAILED,
    message: BraintreeErrorMessage.NETWORK,
    action: BraintreeErrorAction.RETRY,
  },

  /* Occurs when Cardinal's Songbird.js library takes longer than 60 seconds to set up */
  {
    error: BraintreeErrorType.THREEDS_CARDINAL_SDK_SETUP_TIMEDOUT,
    message: BraintreeErrorMessage.NETWORK,
    action: BraintreeErrorAction.RETRY,
  },

  /* Occurs when Cardinal sends a response indicating a timeout on /Validate, /Confirm, or /Continue */
  {
    error: BraintreeErrorType.THREEDS_CARDINAL_SDK_RESPONSE_TIMEDOUT,
    message: BraintreeErrorMessage.NETWORK,
    action: BraintreeErrorAction.RETRY,
  },

  /* Occurs when there is no JWT in the request. Also when there's some other malformed aspect of config */
  {
    error: BraintreeErrorType.THREEDS_CARDINAL_SDK_BAD_CONFIG,
    message: BraintreeErrorMessage.ERROR,
    action: BraintreeErrorAction.RETRY,
  },

  /* Occurs when a malformed config causes a either a missing response JWT or a malformed Cardinal response */
  {
    error: BraintreeErrorType.THREEDS_CARDINAL_SDK_BAD_JWT,
    message: BraintreeErrorMessage.ERROR,
    action: BraintreeErrorAction.RETRY,
  },

  /* Occurs when a "general error" or a Cardinal hosted fields error happens. Description contains more details */
  {
    error: BraintreeErrorType.THREEDS_CARDINAL_SDK_ERROR,
    message: BraintreeErrorMessage.ERROR,
    action: BraintreeErrorAction.RETRY,
  },

  /* Occurs when customer cancels the transaction mid-flow, usually with alt-pays that have their own cancel buttons */
  {
    error: BraintreeErrorType.THREEDS_CARDINAL_SDK_CANCELED,
    message: BraintreeErrorMessage.FAILED_VERIFICATION,
    action: BraintreeErrorAction.NONE,
  },

  /* Occurs when the 3D Secure flow is canceled, but there is no 3D Secure information available */
  {
    error: BraintreeErrorType.THREEDS_NO_VERIFICATION_PAYLOAD,
    message: BraintreeErrorMessage.FAILED_VERIFICATION,
    action: BraintreeErrorAction.NONE,
  },

  /* Occurs when another verification is already in progress */
  {
    error: BraintreeErrorType.THREEDS_AUTHENTICATION_IN_PROGRESS,
    message: BraintreeErrorMessage.IN_PROGRESS,
    action: BraintreeErrorAction.NONE,
  },

  /* Occurs when a required option is missing */
  {
    error: BraintreeErrorType.THREEDS_MISSING_VERIFY_CARD_OPTION,
    message: BraintreeErrorMessage.FAILED_VERIFICATION,
    action: BraintreeErrorAction.NONE,
  },

  /* Occurs when something went wrong authenticating the JWT from the Cardinal SDK */
  {
    error: BraintreeErrorType.THREEDS_JWT_AUTHENTICATION_FAILED,
    message: BraintreeErrorMessage.ERROR,
    action: BraintreeErrorAction.RETRY,
  },

  /* Occurs when the supplied payment method nonce does not exist or the payment method nonce has already been consumed */
  {
    error: BraintreeErrorType.THREEDS_LOOKUP_TOKENIZED_CARD_NOT_FOUND_ERROR,
    message: BraintreeErrorMessage.ERROR,
    action: BraintreeErrorAction.RETRY,
  },

  /* Occurs when a validation error occurs during the 3D Secure lookup */
  {
    error: BraintreeErrorType.THREEDS_LOOKUP_VALIDATION_ERROR,
    message: BraintreeErrorMessage.FAILED_VERIFICATION,
    action: BraintreeErrorAction.NONE,
  },

  /* An unknown error occurred while attempting the 3D Secure lookup */
  {
    error: BraintreeErrorType.THREEDS_LOOKUP_ERROR,
    message: BraintreeErrorMessage.FAILED_VERIFICATION,
    action: BraintreeErrorAction.NONE,
  },

  /* Occurs when the 3D Secure flow is canceled by the merchant using cancelVerifyCard (3D Secure v2 flows only) */
  {
    error: BraintreeErrorType.THREEDS_VERIFY_CARD_CANCELED_BY_MERCHANT,
    message: BraintreeErrorMessage.FAILED_VERIFICATION,
    action: BraintreeErrorAction.NONE,
  },

  /* An unknown error occurred while attempting to use the inline iframe framework */
  {
    error: BraintreeErrorType.THREEDS_INLINE_IFRAME_DETAILS_INCORRECT,
    message: BraintreeErrorMessage.ERROR,
    action: BraintreeErrorAction.RELOAD,
  },

  /* Occurs when the customer fails the 3D Secure check */
  {
    error: BraintreeErrorType.THREEDS_FAILED_VERIFICATION,
    message: BraintreeErrorMessage.FAILED_VERIFICATION,
    action: BraintreeErrorAction.NONE,
  },
];
