import React from 'react';

import { QuestionProps } from '../Template/types';
import * as Question from 'components/Question';
import TextArea from 'components/Inputs/TextArea';

type QuestionTextareaProps<T> = QuestionProps<T> & {
  readOnly?: boolean;
};

function Component<T>({
  control,
  disabled,
  help,
  isSubmitting,
  label,
  question,
  readOnly,
}: QuestionTextareaProps<T>) {
  return (
    <Question.Template
      control={control}
      help={help}
      label={label}
      question={question}
    >
      {({ invalid, name, onBlur, onChange, ref, value }) => (
        <TextArea
          label={label}
          forwardedRef={ref}
          id={name}
          invalid={invalid}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          readOnly={isSubmitting || readOnly}
          tabIndex={disabled ? -1 : 0}
          value={value}
        />
      )}
    </Question.Template>
  );
}

export default React.memo(Component) as typeof Component;
