import yup, { ValdationMessages } from 'modules/validation';
import debounce from 'debounce-promise';
import { api, API } from 'modules/api';

type Cache = {
  [key: string]: {
    active: string;
    results: string[];
  };
};

const cache: Cache = {};

const cacheResponse = (
  exists: boolean,
  path: string,
  value: string,
): boolean => {
  exists && cache[path].results.push(value);
  return !cacheSearch(path, cache[path].active);
};

const cacheSearch = (path: string, value: string): boolean => {
  return !!cache[path].results.find(item => item === value);
};

const setActive = (path: string, value: string) => {
  !cache[path]
    ? (cache[path] = { active: value, results: [] })
    : (cache[path].active = value);
};

const apiRequest = async (
  createError: (params?: Record<string, unknown>) => yup.ValidationError,
  path: string,
  value: string,
) => {
  try {
    const response = await api(API.POST_IS_USER_SUBSCRIBED(value));
    return cacheResponse(response.data === true, path, value);
  } catch (error: any) {
    return createError({ message: ValdationMessages.API_ERROR });
  }
};

const debounceApiRequest = debounce(apiRequest, 1500, {
  leading: true,
});

const request = (
  createError: (params?: Record<string, unknown>) => yup.ValidationError,
  path: string,
  value: string,
) => {
  return cacheSearch(path, value)
    ? false
    : debounceApiRequest(createError, path, value);
};

function validator(this: yup.StringSchema) {
  return this.test(
    'userExists',
    ValdationMessages.USER_EXISTS,
    function (value) {
      setActive(this.path, value || '');
      return value ? request(this.createError, this.path, value) : true;
    },
  );
}

export default validator;
