import { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { API, api } from 'modules/api';
import { POLICIES_PAGE_URL } from 'modules/utils/constants';
import { PrivacyGetResponse } from 'modules/api/endpoints/privacy-policy';
import { PrivacyMatchParams, PrivacyContentType } from './types';
import logger from 'modules/logger';

export const usePrivacyPolicy = (
  setLoaded: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  const [privacyContent, setPrivacyContent] = useState<PrivacyContentType>();
  const matchParam = useRouteMatch<PrivacyMatchParams>(
    `${POLICIES_PAGE_URL}:slug`,
  );
  const slug = matchParam ? matchParam?.params.slug : '';

  useEffect(() => {
    //TODO done useEffect
    let active = true;
    (async () => {
      try {
        const response: PrivacyGetResponse = await api(
          API.GET_PUBLIC_CONTENT_BY_ID({
            page: 'myaxelos_generic_page/',
            slug,
          }),
        );
        active && setPrivacyContent(response?.data?.entries[0]);
      } catch (error) {
        active && logger.error('Get Privacy Policy Text Error', error);
      } finally {
        active && setLoaded(true);
      }
    })();
    return () => {
      active = false;
    };
  }, [slug, setLoaded]);

  return { privacyContent };
};
