import { useEffect, useState } from 'react';

import { API, api } from 'modules/api';
import { BadgesGetResponse } from 'modules/api/endpoints/badges';
import {
  BadgesCandidateIdTypes,
  BadgesLoadedType,
  BadgesTypes,
} from '../types';
import logger from 'modules/logger';

export const useBadges = (
  setLoaded: React.Dispatch<React.SetStateAction<BadgesLoadedType>>,
  runPolling: (
    badges: BadgesTypes[],
    setBadges: React.Dispatch<React.SetStateAction<BadgesTypes[] | undefined>>,
  ) => void,
  candidateIds?: BadgesCandidateIdTypes[],
) => {
  const [badges, setBadges] = useState<BadgesTypes[] | undefined>();
  //TODO done useEffect
  useEffect(() => {
    let active = true;
    (async () => {
      try {
        const response: BadgesGetResponse = await api(API.GET_BADGES());
        if (active) {
          const badgesStatusIndex = response.data.findIndex(
            (el: BadgesTypes) => el.status === 1 || el.status === 2,
          );
          setBadges(response.data);
          if (badgesStatusIndex > -1) {
            runPolling(response.data, setBadges);
          }
        }
      } catch (error) {
        active &&
          candidateIds &&
          candidateIds.length > 0 &&
          logger.error('Get Badges Error', error);
      } finally {
        active &&
          setLoaded(prevState => ({
            ...prevState,
            badges: true,
          }));
      }
    })();
    return () => {
      active = false;
    };
  }, [candidateIds, runPolling, setLoaded]);

  return { badges, setBadges };
};
