import React from 'react';
import clsx from 'clsx';

import {
  BadgeLinksTypes,
  BadgesCandidateIdTypes,
  BadgesCertificatesPathsTypes,
  BadgesLoadedType,
  BadgesTypes,
  CertificateTypes,
  DesignationsType,
} from 'pages/badges/types';
import { ProgrammeType } from './constants';
import { ReactComponent as MyaxelosShort } from 'modules/theme/ims/logos/myaxelos-short.svg';
import { useAccordion } from 'modules/hooks';
import * as Button from 'components/Button';
import Certificates from '../Badges/Certificates';
import Designations from '../Badges/Designations';
import Paths from '../Paths';

import styles from './styles.module.scss';

type ProgrammeProps = {
  badges?: BadgesTypes[];
  badgeLinks?: BadgeLinksTypes;
  cancelPolling: () => void;
  certificates: CertificateTypes[];
  candidateId?: BadgesCandidateIdTypes[];
  designations: DesignationsType[];
  handleNext: () => void;
  handlePrev: () => void;
  loaded: BadgesLoadedType;
  paths?: BadgesCertificatesPathsTypes[];
  runPolling: (
    badges: BadgesTypes[],
    setBadges: React.Dispatch<React.SetStateAction<BadgesTypes[] | undefined>>,
  ) => void;
  setBadges: React.Dispatch<React.SetStateAction<BadgesTypes[] | undefined>>;
  showPaths: number | boolean;
  slidePosition: number;
  title: string;
  type: ProgrammeType;
};

const Programme: React.FunctionComponent<ProgrammeProps> = ({
  badges,
  badgeLinks,
  cancelPolling,
  certificates,
  designations,
  handleNext,
  handlePrev,
  loaded,
  paths,
  runPolling,
  setBadges,
  showPaths,
  slidePosition,
  title,
  type,
}) => {
  const { animating, handleClick, open, ref } = useAccordion();
  const classNames = clsx(
    styles.programme,
    `${styles[type.replace(/\s/g, '-')]}`,
    animating && styles.animating,
    !open && styles.closed,
  );

  const displayPaths = paths && !!showPaths ? true : false;

  return (
    <section className={classNames}>
      <h2>
        <MyaxelosShort />
        <span>{title}</span>
        <Button.Generic
          ariaControls={title}
          ariaExpanded={open}
          ariaLabel={`${title} section`}
          onClick={handleClick}
        >
          <span></span>
        </Button.Generic>
      </h2>
      <div aria-hidden={!open} id={title} ref={ref}>
        <div>
          <Paths
            displayPaths={displayPaths}
            handleNext={handleNext}
            handlePrev={handlePrev}
            loaded={loaded.paths}
            paths={paths}
            slidePosition={slidePosition}
            type={type}
          />
          <Certificates
            badges={badges}
            badgeLinks={badgeLinks}
            cancelPolling={cancelPolling}
            certificates={certificates}
            loaded={loaded.certificates}
            runPolling={runPolling}
            setBadges={setBadges}
            type={type}
          />
          <Designations
            badges={badges}
            badgeLinks={badgeLinks}
            loaded={loaded.certificates}
            designations={designations}
            type={type}
          />
        </div>
      </div>
    </section>
  );
};

export default Programme;
