import React from 'react';

import { ReactComponent as MasterCardSvg } from 'modules/braintree/icons/mastercard.svg';
import { ReactComponent as VisaSvg } from 'modules/braintree/icons/visa.svg';
import { ReactComponent as AmericanExpressSvg } from 'modules/braintree/icons/american-express.svg';
import { ReactComponent as DinersClubSvg } from 'modules/braintree/icons/diners-club.svg';
import { ReactComponent as DiscoverSvg } from 'modules/braintree/icons/discover.svg';
import { ReactComponent as EloSvg } from 'modules/braintree/icons/elo.svg';
import { ReactComponent as HiperSvg } from 'modules/braintree/icons/hiper.svg';
import { ReactComponent as HiperCardSvg } from 'modules/braintree/icons/hipercard.svg';
import { ReactComponent as JcbSvg } from 'modules/braintree/icons/jcb.svg';
import { ReactComponent as MaestroSvg } from 'modules/braintree/icons/maestro.svg';
import { ReactComponent as MirSvg } from 'modules/braintree/icons/mir.svg';
import { ReactComponent as UnionPaySvg } from 'modules/braintree/icons/union-pay.svg';
import BraintreeQuestion from '../BraintreeQuestion';
import { BraintreeFieldType } from '../BraintreeQuestion/constants';
import { BraintreeFormProps } from '../../types';
import * as Question from 'components/Question';
import { countries } from 'modules/countries';
import { braintreeModel } from '../../model';
import Fieldset from 'components/Fieldset';

import styles from './styles.module.scss';

function BraintreeForm({
  control,
  hostedFields,
  isLoading,
  isSubmitting,
  title,
}: BraintreeFormProps) {
  return (
    <React.Fragment>
      <Fieldset title={title} className={styles.braintreeForm}>
        <BraintreeQuestion
          control={control}
          hostedFields={hostedFields}
          id="cardholder-name"
          isSubmitting={isLoading || isSubmitting}
          label="Cardholder Name"
          question={braintreeModel.cardholderName}
        />

        <BraintreeQuestion
          control={control}
          hostedFields={hostedFields}
          id="card-number"
          isSubmitting={isLoading || isSubmitting}
          label="Credit Card Number"
          question={braintreeModel.number}
          help={
            <div id="cardTooltip" className={styles.cardTooltip}>
              <p>Other accepted card types:</p>
              <AmericanExpressSvg />
              <DinersClubSvg />
              <DiscoverSvg />
              <EloSvg />
              <HiperSvg />
              <HiperCardSvg />
              <JcbSvg />
              <MaestroSvg />
              <MirSvg />
              <UnionPaySvg />
            </div>
          }
        >
          <VisaSvg />
          <MasterCardSvg />
        </BraintreeQuestion>
      </Fieldset>

      <Fieldset title="Expiration date">
        <BraintreeQuestion
          control={control}
          hostedFields={hostedFields}
          id="expiration-month"
          isSubmitting={isLoading || isSubmitting}
          label="Expiration Month"
          question={braintreeModel.expirationMonth}
          type={BraintreeFieldType.SELECT}
        />

        <BraintreeQuestion
          control={control}
          hostedFields={hostedFields}
          id="expiration-year"
          isSubmitting={isLoading || isSubmitting}
          label="Expiration Year"
          question={braintreeModel.expirationYear}
          type={BraintreeFieldType.SELECT}
        />
      </Fieldset>

      <Fieldset title="CVV">
        <BraintreeQuestion
          control={control}
          help="This is the three-digit number at the back of your credit or debit card."
          hostedFields={hostedFields}
          id="cvv"
          isSubmitting={isLoading || isSubmitting}
          label="CVV"
          question={braintreeModel.cvv}
        />
      </Fieldset>

      <Fieldset title="Billing address">
        <Question.Text
          control={control}
          isSubmitting={isLoading || isSubmitting}
          label="First name"
          question={braintreeModel.billingFirstName}
        />

        <Question.Text
          control={control}
          isSubmitting={isLoading || isSubmitting}
          label="Last name"
          question={braintreeModel.billingLastName}
        />

        <Question.Text
          control={control}
          isSubmitting={isLoading || isSubmitting}
          label="Address line one"
          question={braintreeModel.billingStreetAddress}
        />

        <Question.Text
          control={control}
          isSubmitting={isLoading || isSubmitting}
          label="Address line two (optional)"
          question={braintreeModel.billingExtendedAddress}
        />

        <Question.Text
          control={control}
          isSubmitting={isLoading || isSubmitting}
          label="City"
          question={braintreeModel.billingLocality}
        />

        <Question.Text
          control={control}
          isSubmitting={isLoading || isSubmitting}
          label="Zip or Postcode"
          question={braintreeModel.billingPostalCode}
        />

        <Question.Select
          control={control}
          isSubmitting={isLoading || isSubmitting}
          label="Country"
          options={countries}
          question={braintreeModel.billingCountryCodeAlpha2}
        />
      </Fieldset>
    </React.Fragment>
  );
}

export default React.memo(BraintreeForm) as typeof BraintreeForm;
