/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import clsx from 'clsx';
import { RefCallBack } from 'react-hook-form';

import { ReactComponent as ChevronSVG } from 'modules/theme/icons/chevrons/chevron-down.svg';

import styles from './styles.module.scss';

export type OptionType = {
  label: string;
  value: string | number;
};

type SelectPrimitiveOptionsProps = {
  options?: OptionType[];
  readOnly?: boolean;
};

const SelectPrimitiveOptions = React.memo<SelectPrimitiveOptionsProps>(
  ({ options, readOnly }) => (
    <React.Fragment>
      {options?.map((option, key) => (
        <option
          disabled={readOnly || option.value === ''}
          key={key}
          value={option.value}
        >
          {option.label}
        </option>
      ))}
    </React.Fragment>
  ),
);

export type SelectProps = {
  ariaLabel?: string;
  autoFocus?: boolean;
  children?: React.ReactNode;
  disabled?: boolean;
  forwardedRef: RefCallBack | React.MutableRefObject<HTMLSelectElement | null>;
  id?: string;
  invalid?: boolean;
  label: string;
  name: string;
  onBlur?: (event: React.FocusEvent<HTMLSelectElement>) => void;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLSelectElement>) => void;
  options?: OptionType[];
  readOnly?: boolean;
  tabIndex?: number;
  value: string;
};

const Select: React.FunctionComponent<SelectProps> = ({
  ariaLabel,
  autoFocus,
  disabled,
  forwardedRef,
  id,
  invalid,
  label,
  name,
  onBlur,
  onChange,
  onFocus,
  options,
  readOnly,
  tabIndex,
  value,
}) => {
  const getTabIndex = tabIndex ? tabIndex : 0;

  const className = clsx(
    styles.select,
    disabled && styles.disabled,
    readOnly && styles.readOnly,
    !disabled && invalid && styles.invalid,
    !value && styles.placeholder,
  );

  return (
    <div className={className}>
      <select
        aria-label={ariaLabel}
        autoFocus={!disabled && autoFocus}
        disabled={disabled}
        id={id}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        ref={forwardedRef}
        tabIndex={disabled ? -1 : getTabIndex}
        value={value}
      >
        <option disabled={readOnly} value="">
          {label}
        </option>
        <SelectPrimitiveOptions options={options} readOnly={readOnly} />
      </select>
      {!disabled && <ChevronSVG />}
    </div>
  );
};

export default React.memo(Select);
